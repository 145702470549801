export default () => {
  return new Promise((resolve, reject) => {
    new window.daum.Postcode({
      oncomplete: data => {
        // data
        /**
         * zonecode 우편번호
         * address
         * roadAddress
         * jibunAddress
         * buildingCode 건물관리번호
         * buildingName 건물명
         * sido
         * sigungu
         * roadname
         */
        if (data) {
          resolve({
            ...data,
            jibunAddress: data.jibunAddress || data.autoJibunAddress,
            roadAddress: data.roadAddress || data.autoRoadAddress,
            dong: data.bname
              ? `${data.bname} ${data.bname1}`.trim()
              : data.bname2
          });
        } else {
          reject(new Error("주소를 불러올 수 없습니다."));
        }
      }
    }).open();
  });
};
