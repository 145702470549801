import React, { memo, useCallback, useState } from "react";
import noop from "lodash/noop";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";
import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore } from "@/store/hooks";

const Description = styled.p``;

const Title = styled.h3``;

export default memo(
  ({
    description = "",
    onCancel = noop,
    onSubmit = noop,
    placeholder = "",
    title = "",
  }) => {
    const { closeModal } = useModalStore();
    const [text, setText] = useState("");

    const handleCancel = useCallback(() => {
      closeModal();
      onCancel();
    }, [closeModal]);

    const handleChange = useCallback(
      (e) => {
        setText(e.target.value);
      },
      [setText]
    );

    const handleSubmit = useCallback(() => {
      onSubmit({
        text,
      });
      closeModal();
    }, [closeModal, onSubmit, text]);

    return (
      <Modal size="lg">
        <Modal.Header>
          <Title>{title}</Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>{description && <Description>{description}</Description>}</Col>
          </Row>
          <Row>
            <Col>
              <Form.Control
                onChange={handleChange}
                type="text"
                placeholder={placeholder}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleCancel} variant="secondary">
            취소
          </Button>
          <Button onClick={handleSubmit} variant="primary">
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
);
