import React, { useMemo, useCallback, useEffect, memo } from "react";
import _noop from "lodash/noop";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { useReallocatedDeliveriesStore } from "@/store/hooks";

const DeliveriesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const DecisionButtonGroups = memo(({ bookId, onClickAdmit, onClickDeny }) => {
  const handleClickAdmit = useCallback(() => {
    onClickAdmit(bookId);
  }, [bookId, onClickAdmit]);

  const handleClickDeny = useCallback(() => {
    onClickDeny(bookId);
  }, [bookId, onClickDeny]);

  return (
    <ButtonGroup>
      <SmallButton onClick={handleClickAdmit} variant="success">
        승인
      </SmallButton>
      <SmallButton onClick={handleClickDeny} variant="warning">
        거절
      </SmallButton>
    </ButtonGroup>
  );
});

export default () => {
  const { state, ...actions } = useReallocatedDeliveriesStore();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  const handleClickAdmit = useCallback(
    async (bookId) => {
      if (window.confirm("재배차를 승인하시겠습니까?")) {
        try {
          await actions.decideOnTheFly({ bookId, decision: true });
          window.alert(`재배차를 승인하였습니다.`);
        } catch (e) {
          window.alert(
            `재배차 승인에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }

        actions.fetchAll();
      }
    },
    [actions.decideOnTheFly],
  );

  const handleClickDeny = useCallback(
    async (bookId) => {
      if (window.confirm("재배차를 거절하시겠습니까?")) {
        try {
          await actions.decideOnTheFly({ bookId, decision: false });
          window.alert(`재배차를 거절하였습니다.`);
        } catch (e) {
          window.alert(
            `재배차 거절에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }

      actions.fetchAll();
    },
    [actions.decideOnTheFly],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        accessor: "bookId",
        selectable: false,
      },
      {
        id: "senderDong",
        Header: "출발동",
        accessor: (row) => row.senderDongName,
        width: 100,
      },
      {
        id: "receiverDong",
        Header: "도착동",
        accessor: (row) => row.receiverDongName,
        width: 100,
      },
      {
        id: "pickupRider",
        Header: "수거라이더",
        accessor: (row) => row.pickupRiderName,
        width: 120,
      },
      {
        id: "deliveryRider",
        Header: "배송라이더",
        accessor: (row) => row.deliveryRiderName,
        width: 120,
      },
      {
        id: "reallocatedRider",
        Header: "재배차 라이더",
        accessor: (row) => row.reallocatedRiderName,
        width: 120,
      },
      {
        id: "reallocationRequestedAt",
        Header: "재배차 요청 일자",
        accessor: (row) => row.renderReallocationRequestedAt(),
      },
      {
        id: "buttons",
        Header: "승인/거절",
        accessor: (row) => (
          <DecisionButtonGroups
            bookId={row.bookId}
            onClickAdmit={handleClickAdmit}
            onClickDeny={handleClickDeny}
          />
        ),
        selectable: false,
      },
    ],
    [],
  );

  return (
    <Container>
      <Row>
        <Col>
          <Title>재배차 승인/거절 관리</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <DeliveriesTable
            responsive
            bordered
            columns={columns}
            data={state.deliveries}
            pagination={false}
          />
        </Col>
      </Row>
    </Container>
  );
};
