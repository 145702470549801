import React, { useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore, useCsesStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

export default ({ cs, onUpdate = _noop }) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useCsesStore();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset({
      text: cs.text,
    });
  }, []);

  function handleClose() {
    closeModal();
  }

  const onSubmit = useCallback(async (data) => {
    if (cs) {
      let _data = { ...data };

      const id = cs.id;
      await actions.update(id, _data).then(() => {
        window.alert(`수정되었습니다.`);
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(e.message);
      });    
    }
  }, [actions.update, cs]);  


  return (
    <Modal>
      <Modal.Header>
        <Title>CS 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <OrigRow>
            <Col>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="6"
                  name="text"
                  ref={register}
                />
              </Form.Group>
            </Col>
          </OrigRow>

          <Form.Group as={OrigRow}>
            <Col>
              <CustomButtom type="submit">수정</CustomButtom>
              <CustomButtom type="button" variant="secondary" onClick={handleClose}>닫기</CustomButtom>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
