import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _remove from "lodash/remove";
import { CorpUser } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_QUERY = {
  corpTitle: "",
};

const INITIAL_STATE = {
  deletedCorpUsers: [],
  page: 1,
  pageCount: 1,
  pageSize: 10,
  query: INITIAL_QUERY,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deletedCorpUsers: action.deletedCorpUsers,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
        query: action.query,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          corpTitle: action.corpTitle,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeletedCorpUsersStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(
    async ({
      corpTitle = "",
      page = state.page,
      pageSize = state.pageSize,
    } = {}) => {
      const { corpUsers, pageCount } = await api.get("/corp-users", {
        corpTitle,
        page,
        pageSize,
        deleted: 1,
      });
      
      dispatch({
        type: CONSTANTS.FETCH_ALL,
        deletedCorpUsers: corpUsers.map((c) => new CorpUser(c)),
        page,
        pageCount,
        pageSize,
      });

      dispatch({
        type: CONSTANTS.SET_QUERY,
        corpTitle,
      });
    },
    [api.get, dispatch]
  );

  const restore = useCallback(
    (id) => {
      return api.put(`/corp-users/${id}/restore`);
    },
    [api.put]
  );

  return {
    state,
    fetchAll,
    restore,
  };
};
