export class Dong {
  id = null;
  name = "";
  fee = null;
  etc = {};
  sigungu = {};

  constructor(dong) {
    this.id = dong.id;
    this.name = dong.name;
    this.fee = dong.fee;
    this.etc = dong.etc;
    this.sigungu = dong.sigungu;

    this.sigunguName = dong.sigungu && dong.sigungu.name;
    this.sidoName = dong.sigungu && dong.sigungu.sido && dong.sigungu.sido.name;
  }

  get dongEtcObj() {
    return this.etc && JSON.parse(this.etc);
  }

  get feeUpdatedAt() {
    return this.dongEtcObj ? this.dongEtcObj.feeUpdatedAt : "";
  }
}
