import React, { useCallback, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import { Button } from "@/components/Buttons";
import styled from "@emotion/styled";

import { useAddressStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;

const OrigRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const OrigCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;

const AddressWrap = styled.div`
  margin-top: 40px;
`;
const AddressTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 20px;
`;
const AddressRow = styled(OrigRow)`
  margin-bottom: 10px;
`;
const AddressCol = styled(OrigCol)``;

export default () => {
  const { addressKakao, addressOne, addressTmap } = useAddressStore().state;
  const { ...actions } = useAddressStore();

  const { handleSubmit, register, errors } = useForm();

  const [address, setAddress] = useState("");
  const [addressDetail, setAddressDetail] = useState("");

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.refineAddress(data);
      } catch (e) {
        window.alert(`주소 정제에 실패하였습니다.\n에러메시지: ${e.message}`);
      }
    },
    [actions.refineAddress],
  );

  const handleChangeAddress = useCallback((e) => {
    setAddress(e.target.value);
  }, []);

  const handleChangeAddressDetail = useCallback((e) => {
    setAddressDetail(e.target.value);
  }, []);

  return (
    <Container>
      <Title>주소 정제 테스트</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={OrigRow}>
          <OrigCol sm={2}>
            <Form.Label>기본 주소</Form.Label>
          </OrigCol>
          <OrigCol sm={10}>
            <Form.Control
              value={address}
              onChange={handleChangeAddress}
              name="address"
              ref={register({ required: true })}
            />
            <ErrorText>
              {errors.address && "기본 주소를 입력해주세요."}
            </ErrorText>
          </OrigCol>
        </Form.Group>
        <Form.Group as={OrigRow}>
          <OrigCol sm={2}>
            <Form.Label>상세 주소</Form.Label>
          </OrigCol>
          <OrigCol sm={10}>
            <Form.Control
              value={addressDetail}
              onChange={handleChangeAddressDetail}
              name="addressDetail"
              ref={register}
            />
          </OrigCol>
        </Form.Group>
        <Form.Group as={OrigRow}>
          <OrigCol sm={{ span: 10, offset: 2 }}>
            <StyledButton type="submit">주소 정제 테스트</StyledButton>
          </OrigCol>
        </Form.Group>
      </Form>
      <Row>
        <Col>
          {addressOne && (
            <AddressWrap>
              <AddressTitle>수지원 주소정제 결과</AddressTitle>
              {addressOne.success ? (
                <>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>지번주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.address}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>지번주소 상세</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.addressDetail}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>도로명주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.addressRoad}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>도로명주소 상세</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.addressRoadDetail}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시도</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.sido}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시군구</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.sigungu}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>동</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.dong}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>빌딩</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.building}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>우편번호</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.postalCode}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>위도, 경도</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressOne.data?.address?.lat},{" "}
                      {addressOne.data?.address?.lang}
                    </AddressCol>
                  </AddressRow>
                </>
              ) : (
                <AddressRow>
                  <AddressCol>
                    {addressOne.error && addressOne.error.message
                      ? addressOne.error.message
                      : "수지원 주소정제에 실패했습니다."}
                  </AddressCol>
                </AddressRow>
              )}
            </AddressWrap>
          )}
        </Col>
        <Col>
          {addressKakao && (
            <AddressWrap>
              <AddressTitle>카카오 주소정제 결과</AddressTitle>
              {addressKakao.success && addressKakao.data.length ? (
                <>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>지번주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressKakao.data[0].address}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>도로명주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressKakao.data[0].addressRoad}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시도</b>
                    </AddressCol>
                    <AddressCol sm={8}>{addressKakao.data[0].sido}</AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시군구</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressKakao.data[0].sigungu}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>동</b>
                    </AddressCol>
                    <AddressCol sm={8}>{addressKakao.data[0].dong}</AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>빌딩</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressKakao.data[0].building}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>위도, 경도</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressKakao.data[0].lat}, {addressKakao.data[0].lang}
                    </AddressCol>
                  </AddressRow>
                </>
              ) : (
                <AddressRow>
                  <AddressCol>
                    {addressKakao.error && addressKakao.error.message
                      ? addressKakao.error.message
                      : "카카오 주소정제에 실패했습니다."}
                  </AddressCol>
                </AddressRow>
              )}
            </AddressWrap>
          )}
        </Col>
        <Col>
          {addressTmap && (
            <AddressWrap>
              <AddressTitle>티맵 주소정제 결과</AddressTitle>
              {addressTmap.success && addressTmap.data?.result ? (
                <>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>지번주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.address}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>도로명주소</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.addressRoad}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시도</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.sido}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>시군구</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.sigungu}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>동</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.dong}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>빌딩</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.building}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>buildingDongNumber</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.buildingDongNumber}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>위도, 경도</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.lat},{" "}
                      {addressTmap.data.result.lang}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>point</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.point}
                    </AddressCol>
                  </AddressRow>
                  <AddressRow>
                    <AddressCol sm={4}>
                      <b>pointForEntry</b>
                    </AddressCol>
                    <AddressCol sm={8}>
                      {addressTmap.data.result.pointForEntry}
                    </AddressCol>
                  </AddressRow>
                </>
              ) : (
                <AddressRow>
                  <AddressCol>
                    {addressTmap.error && addressTmap.error.message
                      ? addressTmap.error.message
                      : "티맵 주소정제에 실패했습니다."}
                  </AddressCol>
                </AddressRow>
              )}
            </AddressWrap>
          )}
        </Col>
      </Row>
    </Container>
  );
};
