import React, { useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useCorpUsersStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;

export default () => {
  const { ...actions } = useCorpUsersStore();
  const { handleSubmit, register, reset } = useForm();

  const zonedDateToday = getDateByTimeZone();
  const initDate = formatDate(zonedDateToday);

  useEffect(() => {
    reset({
      date: initDate,
    });
  }, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        await actions.register(data);
        window.alert("수동 처리 하였습니다..");
      } catch (e) {
        console.log(e);
        window.alert(
          `스케쥴러 수동 처리에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [actions.fetchAll],
  );

  return (
    <Container>
      <Title>외부업체 스케쥴러 수동 처리</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            고객사
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              name="corp"
              defaultValue="oliveyoung"
              ref={register}
            >
              <option value="oliveyoung">올리브영</option>
            </Form.Control>
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            일자
          </Form.Label>
          <Col sm={10}>
            <Form.Control name="date" type="date" ref={register} />
          </Col>
        </Form.Group>
        <Form.Group as={Row}>
          <Col sm={2}></Col>
          <Col sm={10}>
            <Button type="submit">수동 처리</Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};
