import React, { useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { FindAllocationGroupsModal, FindRidersModal } from "@/Modals";
import { useAllocationMatchesStore, useModalStore } from "@/store/hooks";
import { FormControl } from "react-bootstrap";

const RightColumn = styled(Col)`
  text-align: right;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const Title = styled.h3``;

const FormRadio = styled(Form.Check)`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
`;

export default ({ onCreate = _noop } = {}) => {
  const [selectedAllocationGroup, setSelectedAllocationGroup] = useState(null);
  const [selectedRider, setSelectedRider] = useState(null);
  const { ...actions } = useAllocationMatchesStore();
  const { closeModal, openModal } = useModalStore();
  const { register, handleSubmit } = useForm();

  function handleClickFindAllocationGroup() {
    openModal(
      <FindAllocationGroupsModal
        onSelect={handleSelectAllocationGroups}
        key="find-allocation-groups-modal"
      />,
    );
  }

  function handleClickFindRider() {
    openModal(
      <FindRidersModal onSelect={handleSelectRiders} key="find-riders-modal" />,
    );
  }

  function handleSelectAllocationGroups(allocationGroups) {
    closeModal();
    setSelectedAllocationGroup(allocationGroups[0]);
  }

  function handleSelectRiders(rider) {
    closeModal();
    setSelectedRider(rider);
  }

  const onSubmit = async (data) => {
    if (!selectedAllocationGroup) {
      alert("배차그룹을 지정해주세요.");
    } else if (!selectedRider) {
      alert("라이더를 지정해주세요.");
    } else {
      actions
        .create({
          ...data,
          allocationGroupId: selectedAllocationGroup.id,
          riderId: selectedRider.id,
        })
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          alert(e.message);
        });
    }
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>배차 생성하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              지역 그룹
            </Form.Label>
            <Col sm={6}>
              <FormControl
                value={
                  selectedAllocationGroup ? selectedAllocationGroup.name : ""
                }
                readOnly
              />
            </Col>
            <RightColumn sm={3}>
              <StyledButton onClick={handleClickFindAllocationGroup}>
                검색
              </StyledButton>
            </RightColumn>
          </Form.Group>

          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              라이더
            </Form.Label>
            <Col sm={6}>
              <FormControl
                value={selectedRider ? selectedRider.name : ""}
                readOnly
              />
            </Col>
            <RightColumn sm={3}>
              <StyledButton onClick={handleClickFindRider}>검색</StyledButton>
            </RightColumn>
          </Form.Group>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              유형
            </Form.Label>
            <Col sm={2}>
              <FormRadio
                name="allocationType"
                label="수거"
                type="radio"
                ref={register}
                value="1"
                defaultChecked={true}
              />
            </Col>
            <Col sm={2}>
              <FormRadio
                name="allocationType"
                label="배차"
                type="radio"
                ref={register}
                value="2"
              />
            </Col>
          </Form.Group>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              출발시간
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                maxLength={4}
                minLength={4}
                name="timeFrom"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              종료시간
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                maxLength={4}
                minLength={4}
                name="timeTo"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Button type="submit">생성하기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
