import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import Table from "@/components/Table";
import { useSessionLogsStore } from "@/store/hooks";

import { formatDate, getDateByTimeZone } from "@/lib/date";

const LogsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;
      
export default () => {
  const { state, ...actions } = useSessionLogsStore();

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: "id",
        width: 80,
      },
      {
        Header: "type",
        accessor: "type",
        width: 120,
      },
      {
        Header: "ip",
        accessor: "ip",
      },
      {
        Header: "success",
        accessor: "success",
        width: 120,
      },
      {
        Header: "userAgent",
        accessor: "userAgent",
      },
      
      {
        Header: "username",
        accessor: "user.username",
      },
      {
        Header: "realname",
        accessor: "user.realname",
      },
      {
        Header: "createdAt",
        accessor: (row) => (
          <div>{formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm")}</div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <Row>
        <Col>
          <Title>관제 접속 로그 관리</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <LogsTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.sessionLogs}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
