import React, { createContext, useCallback, useContext, useReducer } from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_GROUP_1: "FETCH_GROUP_1",
  FETCH_GROUP_1_NO_PAGINATION: "FETCH_GROUP_1_NO_PAGINATION",
  FETCH_GROUP_2: "FETCH_GROUP_2",
  SELECT_RIDER_GROUP_2: "SELECT_RIDER_GROUP_2",
};

const INITIAL_STATE = {
  page: 1,
  pageCount: 1,
  pageSize: 10,
  riderGroup1s: [],
  riderGroup2s: [],

  selectedRiderGroup2: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_GROUP_1:
      return {
        ...state,
        riderGroup1s: action.riderGroup1s,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
      };
    case CONSTANTS.FETCH_GROUP_1_NO_PAGINATION:
      return {
        ...state,
        riderGroup1s: action.riderGroup1s,
      };
    case CONSTANTS.FETCH_GROUP_2:
      return {
        ...state,
        riderGroup2s: action.riderGroup2s,
      };
    case CONSTANTS.SELECT_RIDER_GROUP_2:
      return {
        ...state,
        selectedRiderGroup2: action.selectedRiderGroup2,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useRiderGroupsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  /**
   * 조 생성
   */
  const createRiderGroup1 = useCallback(async (data) => {
    return await api.post("/rider-group1s", data);
  }, [api.post]);

  /**
   * 팀 생성
   */
  const createRiderGroup2 = useCallback(async (data) => {
    return await api.post("/rider-group2s", data);
  }, [api.post]);

  /**
   * 조 삭제
   */
  const deleteRiderGroup1 = useCallback((id) => {
    return api.del(`/rider-group1s/${id}`);
  }, [api.del]);

  /**
   * 팀 삭제
   */
  const deleteRiderGroup2 = useCallback((id) => {
    return api.del(`/rider-group2s/${id}`);
  }, [api.del]);

  const fetchRiderGroup1 = useCallback(async ({
    name = "",
    page = state.page,
    pageSize = state.pageSize,
  } = {}) => {
    const { riderGroup1s, pageCount } = await api.get("/rider-group1s", {
      name,
      page,
      pageSize,
    });

    dispatch({
      type: CONSTANTS.FETCH_GROUP_1,
      riderGroup1s,
      page,
      pageCount,
      pageSize,
    });
  }, [api.get, dispatch, state.page, state.pageSize]);

  /**
   * 조 목록 no pagination
   */
  const fetchRiderGroup1NoPagination = useCallback(async (riderGroup2Id) => {
    const { items: riderGroup1s } = await api.get("/rider-group1s/no-pagination", { riderGroup2Id });

    dispatch({
      type: CONSTANTS.FETCH_GROUP_1_NO_PAGINATION,
      riderGroup1s,
    });
  }, [api.get, dispatch]);

  /**
   * 팀 목록 no pagination
   */
  const fetchRiderGroup2 = useCallback(async () => {
    const riderGroup2s = await api.get("/rider-group2s");

    dispatch({
      type: CONSTANTS.FETCH_GROUP_2,
      riderGroup2s,
    });
  }, [api.get, dispatch]);

  /**
   * 팀 선택
   */
  const selectRiderGroup2 = useCallback((selectedRiderGroup2) => {
    dispatch({
      type: CONSTANTS.SELECT_RIDER_GROUP_2,
      selectedRiderGroup2,
    });
  }, [dispatch]);

  /**
   * 조 수정
   */
  const updateRiderGroup1 = useCallback(async (id, data) => {
    return await api.put(`/rider-group1s/${id}`, data);
  }, [api.put]);

  /**
   * 팀 수정
   */
  const updateRiderGroup2 = useCallback(async (id, data) => {
    return await api.put(`/rider-group2s/${id}`, data);
  }, [api.put]);


  return {
    state,
    createRiderGroup1,
    createRiderGroup2,
    deleteRiderGroup1,
    deleteRiderGroup2,
    fetchRiderGroup1,
    fetchRiderGroup1NoPagination,
    fetchRiderGroup2,
    selectRiderGroup2,
    updateRiderGroup1,
    updateRiderGroup2,
  };
};
