import React from "react";
import styled from "@emotion/styled";

const ErrorWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
`;

export default () => {
  return (
    <ErrorWrap>
      접근할 수 없는 디바이스 입니다.
      <br />
      PC로 접속해 주세요.
    </ErrorWrap>
  );
};
