import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import XLSX from "xlsx";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  CreateCustomDongModal,
  EditCustomDongModal,
  FileUploadModal,
} from "@/Modals";
import {
  useCustomDongsStore,
  useErrorsStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const CustomDongsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const DeleteColumn = memo(({ id, onDelete = _noop }) => {
  const { ...actions } = useCustomDongsStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteCustomDong(id);
        onDelete();
      } catch (e) {
        window.alert(`커스텀 동 삭제에 실패했습니다: ${e.message}`);
      }
      onDelete();
    }
  }, [actions.deleteCustomDong, onDelete]);

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      삭제
    </SmallButton>
  );
});

const EditColumn = ({ customDong, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditCustomDongModal
        key="edit-custom-dong-modal"
        customDong={customDong}
        onUpdate={onUpdate}
      />,
    );
  }

  return (
    <SmallButton onClick={handleClickEdit} variant="warning">
      수정
    </SmallButton>
  );
};

export default () => {
  const { state, ...actions } = useCustomDongsStore();
  const { actions: errorsActions } = useErrorsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();
  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        worksheetArr.forEach((el) => {
          if (el.beonji2) {
            el.beonji2 = el.beonji2.split(",");
          } else {
            el.beonji2 = [];
          }
        });

        await actions.bulkUpload(worksheetArr);
      } catch (e) {
        errorsActions.apiError({
          status: e.status,
          message: e.message,
        });
      }

      finishLoading();
      closeModal();
      fetchAll();
    },
    [errorsActions.apiError, actions.bulkUpload],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleUploadBulkFile,
        }}
        key="customDong-bulk-upload-modal"
        title="커스텀동 대량생성하기"
      />,
    );
  }, [handleUploadBulkFile]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateCustomDongModal
        key="create-custom-dong-modal"
        onCreate={handleCreate}
      />,
    );
  }, [handleCreate, openModal]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const columns = useMemo(() => [
    {
      id: "sido",
      Header: "시/도",
      accessor: "sidoName",
      width: 120,
    },
    {
      id: "sigungu",
      Header: "시/군/구",
      accessor: "sigunguName",
      width: 120,
    },
    {
      id: "name",
      Header: "동명",
      accessor: "dongName",
      width: 130,
    },
    {
      id: "beonji1",
      Header: "번지1",
      accessor: "beonji1",
      width: 100,
    },
    {
      id: "beonji2",
      Header: "번지2",
      accessor: "displayBeonji2",
      width: 100,
    },
    {
      id: "fee",
      Header: "운임",
      accessor: "fee",
      width: 100,
    },
    {
      id: "allocationGroupName",
      Header: "배차그룹",
      accessor: "allocationGroupName",
      width: 120,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn customDong={row} onUpdate={fetchAll} />;
      },
      selectable: false,
      width: 100,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => <DeleteColumn id={row.id} onDelete={fetchAll} />,
      selectable: false,
      width: 100,
    },
  ]);

  return (
    <Container>
      <Title>커스텀 동 관리</Title>

      <ButtonsRow>
        <Col>
          <Button type="button" onClick={handleClickCreate}>
            커스텀 동 추가
          </Button>
        </Col>
        <RightButtonsGroupColumn>
          <StyledButton onClick={handleClickBulkUpload}>대량 생성</StyledButton>
        </RightButtonsGroupColumn>
      </ButtonsRow>

      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="name"
                    onChange={handleChange}
                    placeholder="동 이름 조회"
                    value={state.query.name}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>

      <CustomDongsTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.customDongs}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
