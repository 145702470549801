import React, { useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import { saveAs } from "file-saver";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { DOWNLOAD_TYPE_REVIEWS } from "@/store/Downloads";
import { useDownloadsStore, useLoadingStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

export default () => {
  const { state, ...actions } = useDownloadsStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit } = useForm();

  useEffect(() => {
    actions.setQuery({
      downloadType: DOWNLOAD_TYPE_REVIEWS,
    });
  }, []);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(async () => {
    try {
      startLoading();

      if (state.query.downloadType === DOWNLOAD_TYPE_REVIEWS) {
        const reviews = await actions.downloadReviews();

        saveAs(reviews, `리뷰목록(${state.query.dateFrom}~).xlsx`);
      }
    } catch (e) {
      window.alert(e.message);
    }

    finishLoading();
  }, [state.query]);

  return (
    <Container>
      <Title>통합 엑셀 다운로드</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            다운로드 타입
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              as="select"
              custom
              name="downloadType"
              onChange={handleChange}
              value={state.query.downloadType}
            >
              <option value={DOWNLOAD_TYPE_REVIEWS}>리뷰 다운로드</option>
            </Form.Control>
          </Col>
        </Form.Group>
        {/* {state.query.downloadType === DOWNLOAD_TYPE_DELIVERIES && (
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              일자 기준
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                custom
                name="dateType"
                onChange={handleChange}
                value={state.query.dateType}
              >
                <option value={QUERY_DATE_TYPE_RECEIPT_DATE}>접수일자</option>
                <option value={QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE}>
                  수거완료일자
                </option>
                <option value={QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE}>
                  배송완료일자
                </option>
              </Form.Control>
            </Col>
          </Form.Group>
        )} */}
        {/* {state.query.downloadType === DOWNLOAD_TYPE_BACKUP_LOGS && (
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              백업 구분
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="select"
                custom
                name="backupType"
                onChange={handleChange}
                value={state.query.backupType}
              >
                <option value={QUERY_BACKUP_TYPE_GOOKMIN_BANK}>국민배송</option>
              </Form.Control>
            </Col>
          </Form.Group>
        )} */}
        {/* {state.query.downloadType === DOWNLOAD_TYPE_DATADOG && (
          <>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                type
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  as="select"
                  custom
                  name="datadogType"
                  onChange={handleChange}
                  value={state.query.datadogType}
                >
                  <option value={QUERY_DATADOG_TYPE_ADMIN_LOGIN}>
                    {QUERY_DATADOG_TYPE_ADMIN_LOGIN}
                  </option>
                  <option value={QUERY_DATADOG_TYPE_ADMIN_INQUIRY}>
                    {QUERY_DATADOG_TYPE_ADMIN_INQUIRY}
                  </option>
                  <option value={QUERY_DATADOG_TYPE_ADMIN_INQUIRY_DETAIL}>
                    {QUERY_DATADOG_TYPE_ADMIN_INQUIRY_DETAIL}
                  </option>
                  <option value={QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_LOGIN}>
                    {QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_LOGIN}
                  </option>
                  <option value={QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_INQUIRY}>
                    {QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_INQUIRY}
                  </option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Form.Label column sm={2}>
                indexName
              </Form.Label>
              <Col sm={10}>
                <Form.Control
                  onChange={handleChange}
                  name="indexName"
                  value={state.query.indexName}
                />
              </Col>
            </Form.Group>
          </>
        )} */}
        <Form.Group as={Row}>
          <Form.Label column sm={2}>
            시작일
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              id="start"
              name="dateFrom"
              onChange={handleChange}
              type="date"
              value={state.query.dateFrom}
            />
          </Col>
        </Form.Group>
        {state.query.downloadType !== DOWNLOAD_TYPE_REVIEWS && (
          <Form.Group as={Row}>
            <Form.Label column sm={2}>
              종료일
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                id="end"
                name="dateTo"
                onChange={handleChange}
                type="date"
                value={state.query.dateTo}
              />
            </Col>
          </Form.Group>
        )}

        <Form.Group as={Row}>
          <Col sm={2}></Col>
          <Col sm={10}>
            <Button type="submit">다운로드</Button>
          </Col>
        </Form.Group>
      </Form>
    </Container>
  );
};
