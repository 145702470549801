import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { useModalStore, useReviewsStore } from "@/store/hooks";
import { EditReviewModal, EditReviewCommentModal } from "@/Modals";

import { formatDate, getDateByTimeZone } from "@/lib/date";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ReviewssTable = styled(Table)``;

const Comment = styled.div``;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
`;

const CommentColumn = ({ bookId, comment }) => {
  const { ...actions } = useReviewsStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  function handleClickEdit() {
    openModal(
      <EditReviewCommentModal
        key="edit-review-comment-modal"
        bookId={bookId}
        comment={comment}
        onUpdate={handleUpdate}
      />,
    );
  }

  return (
    <>
      <Comment>{comment}</Comment>
      <SmallButton onClick={handleClickEdit} variant="warning">
        수정
      </SmallButton>
    </>
  );
};

const EditColumn = ({ review }) => {
  const { ...actions } = useReviewsStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  function handleClickEdit() {
    openModal(
      <EditReviewModal
        key="edit-review-modal"
        review={review}
        onUpdate={handleUpdate}
      />,
    );
  }

  return (
    <SmallButton onClick={handleClickEdit} variant="warning">
      수정
    </SmallButton>
  );
};

const PublicColumn = memo(({ bookId, isPublic }) => {
  const { ...actions } = useReviewsStore();

  const handleTogglePublic = useCallback(async () => {
    if (bookId) {
      await actions
        .update(bookId, { public: !isPublic })
        .then(() => {
          actions.fetchAll();
        })
        .catch((e) => {
          window.alert(e.message);
        });
    }
  }, [actions.fetchAll, bookId, isPublic]);

  return (
    <SmallButton
      onClick={handleTogglePublic}
      variant={isPublic ? "primary" : "light"}
    >
      {isPublic ? "공개" : "비공개"}
    </SmallButton>
  );
});

const TwitterColumn = memo(({ bookId, twitterPostId }) => {
  const { ...actions } = useReviewsStore();

  const handleToggleTwitter = useCallback(async () => {
    if (bookId) {
      if (twitterPostId) {
        // 트위터 삭제하기
        await actions
          .deleteTwitter(bookId)
          .then(() => {
            actions.fetchAll();
          })
          .catch((e) => {
            window.alert(e.message);
          });
      } else {
        // 트위터에 공개하기
        await actions
          .postTwitter(bookId)
          .then(() => {
            actions.fetchAll();
          })
          .catch((e) => {
            window.alert(e.message);
          });
      }
    }
  }, [actions.fetchAll, bookId, twitterPostId]);

  return (
    <SmallButton
      onClick={handleToggleTwitter}
      variant={twitterPostId ? "primary" : "light"}
    >
      {twitterPostId ? "공개" : "비공개"}
    </SmallButton>
  );
});

export default () => {
  const { state, ...actions } = useReviewsStore();

  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page,
    state.query.pageSize,
    state.query.is5Star,
    state.query.date,
  ]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const handleSet5Star = useCallback(
    (is5Star) => {
      actions.setQuery({
        is5Star,
        page: 1,
      });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(
    ({ date }) => {
      actions.setQuery({ date });
    },
    [actions.setQuery],
  );

  const handleResetDate = useCallback(() => {
    actions.setQuery({ date: null });
    reset({
      date: null,
    });
  }, [actions.setQuery]);
  const columns = useMemo(() => [
    {
      id: "bookId",
      Header: "배송번호",
      accessor: "bookId",
    },
    {
      id: "senderName",
      Header: "송하인명",
      accessor: "delivery.senderName",
    },
    {
      id: "star",
      Header: "별점",
      accessor: "star",
      width: 60,
    },
    {
      id: "text",
      Header: "내용",
      accessor: "text",
      width: 360,
      ellipsis: false,
    },
    {
      id: "comment",
      Header: "댓글",
      accessor: (row) => (
        <CommentColumn bookId={row.bookId} comment={row.comment} />
      ),
      ellipsis: false,
      selectable: false,
      width: 360,
    },
    {
      Header: "배송기사명",
      accessor: "delivery.deliveryRider.name",
      width: 100,
    },
    {
      id: "createdAt",
      Header: "등록일",
      accessor: (row) => (
        <div>
          {formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm")}
        </div>
      ),
    },
    {
      id: "public",
      Header: "공개",
      accessor: (row) => (
        <PublicColumn bookId={row.bookId} isPublic={row.public} />
      ),
      selectable: false,
      width: 80,
    },
    {
      Header: "트위터 공개",
      accessor: (row) => (
        <TwitterColumn bookId={row.bookId} twitterPostId={row.twitterPostId} />
      ),
      selectable: false,
      width: 90,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn review={row} />;
      },
      selectable: false,
      width: 80,
    },
  ]);

  return (
    <Container fluid>
      <Title>리뷰 관리</Title>

      <SearchRow>
        <ButtonWrap>
          <StyledButton
            onClick={() => handleSet5Star(null)}
            variant={state.query.is5Star === null ? "info" : "light"}
          >
            전체
          </StyledButton>
          <StyledButton
            onClick={() => handleSet5Star(true)}
            variant={state.query.is5Star === true ? "info" : "light"}
          >
            5점
          </StyledButton>
          <StyledButton
            onClick={() => handleSet5Star(false)}
            variant={state.query.is5Star === false ? "info" : "light"}
          >
            5점 이하
          </StyledButton>
        </ButtonWrap>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={6}>
              <Form.Control name="date" ref={register} type="date" />
            </StyledCol>
            <StyledCol md={6}>
              <ButtonWrap>
                <StyledButton type="submit">조회</StyledButton>
                <StyledButton
                  type="button"
                  variant="light"
                  onClick={handleResetDate}
                >
                  초기화
                </StyledButton>
              </ButtonWrap>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <ReviewssTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.reviews}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
