import React from "react";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";

import { Button } from "@/components/Buttons";

const DongNotSupportedForm = ({ onSubmit }) => {
  const { register, handleSubmit } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label>우편번호</Form.Label>
        <Form.Control name="postalCode" ref={register({ required: true })} />
      </Form.Group>

      <Form.Group>
        <Form.Label>동</Form.Label>
        <Form.Control name="dong" ref={register({ required: true })} />
      </Form.Group>

      <Button type="submit">생성하기</Button>
    </Form>
  );
};

export default DongNotSupportedForm;
