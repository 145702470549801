import React, { memo, useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import ButtonGroups from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate } from "@/lib/date";
import {
  AddDeliveryRiderToSpotModal,
  AddPickupRiderToSpotModal,
  CreateSpotModal,
  EditSpotModal,
  EditDeliveryHoursModal,
  EditSpotsExcelSchemeModal,
} from "@/Modals";
import { useModalStore, useSpotsStore } from "@/store/hooks";

const AddRiderButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RiderDeleteButton = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

const RiderItem = styled.li``;

const RiderList = styled.ul`
  padding-left: 0;
  margin: 0;

  li {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const SpotsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Text = styled.div``;

const EditColumn = ({ id, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditSpotModal key="create-spot-modal" onUpdate={onUpdate} id={id} />,
    );
  }

  return (
    <SmallButton variant="warning" onClick={handleClickEdit}>
      수정
    </SmallButton>
  );
};

const EditDeliveryHoursColumn = ({
  id,
  limitedDeliveryHours,
  onUpdate = _noop,
}) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditDeliveryHoursModal
        key="edit-rider-modal"
        id={id}
        onUpdate={onUpdate}
      />,
    );
  }

  return (
    <div>
      <div>{limitedDeliveryHours && `${limitedDeliveryHours}시간`}</div>
      <SmallButton onClick={handleClickEdit}>입력</SmallButton>
    </div>
  );
};

const EditExcelSchemeColumn = ({ id, scheme, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditSpotsExcelSchemeModal
        key="edit-spots-modal"
        id={id}
        onUpdate={onUpdate}
      />,
    );
  }

  return (
    <>
      {scheme && <Text>개별대응</Text>}
      <SmallButton onClick={handleClickEdit}>수정</SmallButton>
    </>
  );
};

const DeleteColumn = ({ id }) => {
  const { ...actions } = useSpotsStore();

  const handleClickDelete = useCallback(() => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteSpot(id);
    }
  }, [id, actions.deleteSpot]);

  return (
    <SmallButton variant="secondary" onClick={handleClickDelete}>
      삭제
    </SmallButton>
  );
};

const OpenColumn = ({ id, isOpen, openUpdatedAt, onUpdate = _noop }) => {
  const { ...actions } = useSpotsStore();

  const handleToggleOpen = useCallback(async () => {
    if (id) {
      await actions
        .updateOpen(id, { open: !isOpen })
        .then(() => {
          onUpdate();
        })
        .catch((e) => {
          window.alert(e.message);
        });
    }
  }, [id, isOpen, onUpdate]);

  return (
    <div>
      <SmallButton
        onClick={handleToggleOpen}
        variant={isOpen ? "primary" : "light"}
      >
        {isOpen ? "공개중" : "비공개"}
      </SmallButton>
      <br />
      <div>
        {openUpdatedAt
          ? `(${formatDate(new Date(openUpdatedAt), "yyyy-MM-dd HH:mm")})`
          : ""}
      </div>
    </div>
  );
};

/**
 * 배송 전담 라이더
 */
//  const DeliveryRider = memo(
//   ({ spotDeliveryRider = {}, spotId }) => {
//     const { ...actions } = useSpotsStore();
//     const handleClickDelete = useCallback(async () => {
//       if (window.confirm("배송라이더를 삭제하시겠습니까?")) {
//         try {
//           // await actions.deletePickupRiderOnTheFly(
//           //   spotId,
//           //   spotDeliveryRider.rider.id
//           // );
//           actions.fetchAll();
//         } catch (e) {
//           window.alert(
//             `배송라이더 삭제에 실패하였습니다.\n에러메시지: ${e.message}`
//           );
//         }
//       }
//     }, [actions.deletePickupRiderOnTheFly, spotId, spotDeliveryRider]);

//     return (
//       <RiderItem key={spotDeliveryRider.id}>
//         {spotDeliveryRider.rider.name}
//         <RiderDeleteButton onClick={handleClickDelete}>
//           (삭제)
//         </RiderDeleteButton>
//       </RiderItem>
//     );
//   }
// );

const DeliveryRider = memo(({ spotDeliveryRider = {}, spotId }) => {
  const { ...actions } = useSpotsStore();
  const { openModal } = useModalStore();

  const handleClickAddDeliveryRider = useCallback(() => {
    openModal(
      <AddDeliveryRiderToSpotModal
        key="add-delivery-rider-to-spot-modal"
        spotId={spotId}
      />,
    );
  }, [openModal, spotId]);

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("배송라이더를 삭제하시겠습니까?")) {
      try {
        await actions.deleteDeliveryRiderOnTheFly(
          spotId,
          spotDeliveryRider.rider.id,
        );
        actions.fetchAll();
      } catch (e) {
        window.alert(
          `배송라이더 삭제에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.deleteDeliveryRiderOnTheFly, spotId, spotDeliveryRider]);

  return (
    <RiderList>
      {spotDeliveryRider ? (
        <RiderItem key={spotDeliveryRider.id}>
          {spotDeliveryRider.rider ? spotDeliveryRider.rider.name : ""}
          <RiderDeleteButton onClick={handleClickDelete}>
            (삭제)
          </RiderDeleteButton>
        </RiderItem>
      ) : (
        <AddRiderButton onClick={handleClickAddDeliveryRider}>
          + 추가
        </AddRiderButton>
      )}
    </RiderList>
  );
});

/**
 * 수거 전담 라이더
 */
const PickupRider = memo(({ spotPickupRider = {}, spotId }) => {
  const { ...actions } = useSpotsStore();
  const handleClickDelete = useCallback(async () => {
    if (window.confirm("수거라이더를 삭제하시겠습니까?")) {
      try {
        await actions.deletePickupRiderOnTheFly(
          spotId,
          spotPickupRider.rider.id,
          {
            timeFrom: spotPickupRider.timeFrom,
            timeTo: spotPickupRider.timeTo,
          },
        );
        actions.fetchAll();
      } catch (e) {
        window.alert(
          `수거라이더 삭제에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.deletePickupRiderOnTheFly, spotId, spotPickupRider]);

  return (
    <RiderItem key={spotPickupRider.id}>
      {spotPickupRider.timeFrom}~{spotPickupRider.timeTo}:{" "}
      {spotPickupRider.rider.name}
      <RiderDeleteButton onClick={handleClickDelete}>(삭제)</RiderDeleteButton>
    </RiderItem>
  );
});

const PickupRiders = memo(({ spotPickupRiders = [], spotId }) => {
  const { openModal } = useModalStore();
  const handleClickAddPickupRider = useCallback(() => {
    openModal(
      <AddPickupRiderToSpotModal
        key="add-pickup-rider-to-spot-modal"
        spotId={spotId}
      />,
    );
  }, [openModal, spotId]);

  return (
    <RiderList>
      {spotPickupRiders.map((spotPickupRider) => (
        <PickupRider
          key={spotPickupRider.id}
          spotId={spotId}
          spotPickupRider={spotPickupRider}
        />
      ))}

      <AddRiderButton onClick={handleClickAddPickupRider}>
        + 추가
      </AddRiderButton>
    </RiderList>
  );
});

export default () => {
  const { state, ...actions } = useSpotsStore();
  const { openModal } = useModalStore();
  const { handleSubmit, register } = useForm();

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize, state.query.text]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll, state.query]);

  const handleClickCreate = useCallback(() => {
    openModal(<CreateSpotModal key="create-spot-modal" onCreate={fetchAll} />);
  }, [openModal, fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(
    ({ text }) => {
      actions.setQuery({ page: 1, text });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "수거지명",
        accessor: "name",
        width: 120,
      },
      {
        Header: "연락처",
        accessor: "contact",
        width: 120,
      },
      {
        Header: "주소",
        accessor: "address",
      },
      {
        Header: "상세주소",
        accessor: "addressDetail",
        width: 120,
      },
      {
        Header: "운임",
        accessor: (row) => <div>{row.fee}원</div>,
        width: 80,
      },
      {
        Header: "수거지코드",
        accessor: "code",
        width: 90,
      },
      {
        Header: "메모",
        accessor: "memo",
        width: 100,
      },
      {
        Header: "배송출발 알림 활성화 여부",
        accessor: (row) => <div>{row.deliveryStartSmsActive ? "O" : "X"}</div>,
        width: 80,
      },
      {
        Header: "배송완료 알림 활성화 여부",
        accessor: (row) => <div>{row.deliveredSmsActive ? "O" : "X"}</div>,
        width: 80,
      },
      {
        Header: "반품 활성화 여부",
        accessor: (row) => <div>{row.returnActive ? "O" : "X"}</div>,
        width: 80,
      },
      {
        Header: "사측주문번호 배송출발 여부",
        accessor: (row) => (
          <div>{row.searchableByOrderIdFromCorp ? "O" : "X"}</div>
        ),
        width: 80,
      },
      {
        Header: "자동배송배차",
        accessor: (row) => (
          <div>
            {row.autoDeliveryAllocation === undefined ||
            row.autoDeliveryAllocation
              ? "O"
              : "X"}
          </div>
        ),
        width: 80,
      },
      {
        Header: "orderId 중복여부",
        accessor: (row) => (
          <div>{row.duplicatedByOrderIdFromCorp ? "O" : "X"}</div>
        ),
        width: 80,
      },
      {
        Header: "주말/휴일 자동수거배차 여부",
        accessor: (row) => (
          <div>{row.autoPickupAllocationOnWeekends ? "O" : "X"}</div>
        ),
        width: 80,
      },
      {
        Header: "배송지연 알림톡 여부",
        accessor: (row) => (
          <div>
            {row.postponedAlimtalkAvailable ||
            row.postponedAlimtalkAvailable === undefined
              ? "O"
              : "X"}
          </div>
        ),
        width: 80,
      },
      {
        Header: "기업용 코드",
        accessor: (row) => <div>{row.codeFromCorp}</div>,
        width: 80,
      },
      {
        Header: "공개여부",
        accessor: (row) => (
          <OpenColumn
            id={row.id}
            isOpen={row.open === undefined ? true : row.open}
            openUpdatedAt={row.openUpdatedAt}
            onUpdate={fetchAll}
          />
        ),
        selectable: false,
        width: 80,
      },
      {
        id: "pickup-riders",
        Header: "담당수거라이더",
        accessor: (row) => (
          <PickupRiders
            spotId={row.id}
            spotPickupRiders={row.spotPickupRiders}
          />
        ),
      },
      {
        id: "delivery-rider",
        Header: "담당배송라이더",
        accessor: (row) => (
          <DeliveryRider
            spotId={row.id}
            spotDeliveryRider={row.spotDeliveryRider}
          />
        ),
      },
      {
        id: "edit-delivery-hours",
        Header: "배송완료 제한 시간",
        accessor: (row) => {
          return (
            <EditDeliveryHoursColumn
              id={row.id}
              limitedDeliveryHours={row.limitedDeliveryHours}
              onUpdate={fetchAll}
            />
          );
        },
        selectable: false,
        width: 100,
      },
      {
        id: "edit-excel-scheme",
        Header: "대량접수포맷",
        accessor: (row) => (
          <EditExcelSchemeColumn
            id={row.id}
            scheme={row.excelScheme}
            onUpdate={fetchAll}
          />
        ),
        selectable: false,
        width: 100,
      },
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => <EditColumn id={row.id} onUpdate={fetchAll} />,
        selectable: false,
        width: 80,
      },
      {
        id: "delete",
        Header: "삭제",
        accessor: (row) => <DeleteColumn id={row.id} />,
        selectable: false,
        width: 80,
      },
    ],
    [state.query.page, state.query.pageSize, state.query.text],
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Title>수거지 관리</Title>
        </Col>
      </Row>
      <ButtonsRow>
        <Col>
          <ButtonGroups>
            <Button onClick={handleClickCreate}>수거지 등록</Button>
          </ButtonGroups>
        </Col>
      </ButtonsRow>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="text"
                    placeholder="수거지명"
                    ref={register}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <Row>
        <Col>
          <SpotsTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.spots}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
