/* eslint-disable no-use-before-define */
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import XLSX from "xlsx";
import { saveAs } from "file-saver";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  CreateAgPostalCodeModal,
  DeleteAgPostalCodeAllModal,
  EditAgPostalCodeModal,
  FindCorpUserModal,
} from "@/Modals";

import {
  useAgPostalCodesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";
import CreateAgPostalCodeBulkModal from "../Modals/CreateAgPostalCodeBulkModal";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 0;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const PostCodesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

// const FormSelect = styled(Form.Control)`
//   min-width: 100px;
//   flex: 1;
//   margin-right: 10px;
// `;

const ButtonDisplayInput = styled(Button)`
  color: #495057;
  background-color: #eee;
  border: 1px solid #ced4da;
  width: 100%;
  text-align: left;

  &:hover,
  &:focus,
  &:active {
    background-color: #eee;
    border: 1px solid #ced4da;
    color: #495057;
  }
`;

const DeleteColumn = memo(({ id, postalCode, onDelete = _noop }) => {
  const { ...actions } = useAgPostalCodesStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm(`${postalCode}을 정말 삭제하시겠습니까?`)) {
      try {
        await actions.deleteAgPostalCode(id);
        onDelete();
      } catch (e) {
        window.alert(`우편번호 삭제에 실패했습니다: ${e.message}`);
      }
      onDelete();
    }
  }, [actions.deleteAgPostalCode, onDelete, id, postalCode]);

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      삭제
    </SmallButton>
  );
});

const EditColumn = ({ agPostalCode, onUpdate }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditAgPostalCodeModal
        key="edit-ag-postal-codes-modal"
        agPostalCode={agPostalCode}
        onUpdate={onUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

export default () => {
  const { state, ...actions } = useAgPostalCodesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();
  const { handleSubmit } = useForm();

  const RESET_ON = "reset";
  // const RESET_OFF = "update";
  // const ADDITION = "addition";

  const [bulkUploadReset, setBulkUploadReset] = useState(RESET_ON);

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const handleClickFindCorpUser = () => {
    openModal(
      <FindCorpUserModal
        onSelect={handleSelectCorpUser}
        key="find-corp-user-modal"
      />,
    );
  };

  const handleSelectCorpUser = useCallback((corpUser) => {
    closeModal();

    actions.setQuery({
      corpUserId: corpUser.id,
      corpUserName: corpUser.corpTitle,
    });
  }, []);

  const handleChangeBulkUploadReset = useCallback((e) => {
    setBulkUploadReset(e.target.value);
  }, []);

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleUpdate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleDelete = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        await actions.bulkUpload({
          data: worksheetArr,
          reset: bulkUploadReset === RESET_ON,
        });
      } catch (e) {
        window.alert(`대량 추가에 실패했습니다: 실패 사유: ${e.message}`);
      }

      finishLoading();
      closeModal();
      fetchAll();
    },
    [actions.bulkUpload, bulkUploadReset, fetchAll],
  );

  const handleUploadNewOnlyBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        const response = await actions.bulkUploadNewOnly({
          data: worksheetArr,
        });

        window.alert(
          `대량 추가 결과\n성공건: ${response.success.length}, 실패건: ${
            response.failed.length
          } ${
            response.failed.length > 0 ? `(${response.failed[0].message})` : ""
          }`,
        );

        console.log(response);
      } catch (e) {
        window.alert(`대량 추가에 실패했습니다: 실패 사유: ${e.message}`);
      }

      finishLoading();
      closeModal();
      fetchAll();
    },
    [actions.bulkUploadNewOnly, bulkUploadReset, fetchAll],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <CreateAgPostalCodeBulkModal
        key="create-ag-postal-codes-bulk-modal"
        onCreate={handleCreate}
      />,
    );
  }, [handleUploadNewOnlyBulkFile, handleUploadBulkFile, bulkUploadReset]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateAgPostalCodeModal
        key="create-ag-postal-codes-modal"
        onCreate={handleCreate}
      />,
    );
  }, [handleCreate, openModal]);

  const handleClickDeleteAll = useCallback(() => {
    openModal(
      <DeleteAgPostalCodeAllModal
        key="delete-ag-postal-codes-all-modal"
        onDelete={handleDelete}
      />,
    );
  }, [openModal, handleDelete]);

  const handleClickDownload = useCallback(async () => {
    try {
      const agPostalCodes = await actions.download();
      saveAs(agPostalCodes, "우편번호 목록.xlsx");
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }
  }, [actions.download]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    actions.setQuery({ page: 1 });
    fetchAll();
  }, [actions.setQuery, fetchAll]);

  const columns = useMemo(() => [
    {
      id: "postalCode",
      Header: "우편번호",
      accessor: "postalCode",
      width: 200,
    },
    {
      Header: "운임",
      width: 200,
      accessor: "fee",
    },
    {
      Header: "지역그룹",
      width: 200,
      accessor: (row) =>
        `${row.allocationGroup ? row.allocationGroup.name : ""}`,
    },
    {
      Header: "기업회원(id)",
      width: 200,
      accessor: (row) =>
        `${
          row.corpUserId ? `${row.corpUser?.corpTitle}(${row.corpUserId})` : ""
        }`,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn agPostalCode={row} onUpdate={handleUpdate} />;
      },
      selectable: false,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => (
        <DeleteColumn
          id={row.id}
          postalCode={row.postalCode}
          onDelete={handleDelete}
        />
      ),
      selectable: false,
    },
  ]);

  return (
    <Container>
      <Title>우편번호 관리</Title>

      <ButtonsRow>
        <Col md={9}>
          <StyledButton type="button" onClick={handleClickCreate}>
            우편번호 추가
          </StyledButton>
          <StyledButton
            type="button"
            variant="outline-secondary"
            onClick={handleClickDownload}
          >
            우편번호 다운로드
          </StyledButton>
          <StyledButton type="button" onClick={handleClickDeleteAll}>
            우편번호 대량 삭제
          </StyledButton>
        </Col>
        <RightButtonsGroupColumn>
          {/* <FormSelect
            as="select"
            onChange={handleChangeBulkUploadReset}
            value={bulkUploadReset}
          >
            <option value={RESET_ON}>신규</option>
            <option value={RESET_OFF}>정정</option>
            <option value={ADDITION}>추가</option>
          </FormSelect> */}
          <Button type="button" onClick={handleClickBulkUpload}>
            대량 생성
          </Button>
        </RightButtonsGroupColumn>
      </ButtonsRow>

      <SearchRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={3}>
              <FormGroup>
                <Form.Control
                  name="postalCode"
                  onChange={handleChange}
                  placeholder="우편번호"
                  value={state.query.postalCode}
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={3}>
              <FormGroup>
                <Form.Control
                  name="allocationGroupName"
                  onChange={handleChange}
                  placeholder="지역그룹명"
                  value={state.query.allocationGroupName}
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={3}>
              <FormGroup>
                <ButtonDisplayInput
                  type="button"
                  onClick={handleClickFindCorpUser}
                >
                  {state.query.corpUserName
                    ? state.query.corpUserName
                    : "기업회원"}
                </ButtonDisplayInput>
                <Form.Control
                  name="corpUserId"
                  value={state.query.corpUserId}
                  hidden
                />
              </FormGroup>
            </StyledCol>
            <StyledCol md={3}>
              <Button type="submit">검색하기</Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <PostCodesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.agPostalCodes}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
