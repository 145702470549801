import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _remove from "lodash/remove";
import { CorpUser } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  UPDATE_PASSWORD: "UPDATE_PASSWORD",
  FETCH_ALL: "FETCH_ALL",
  DELETE: "DELETE",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_QUERY = {
  corpTitle: "",
};

const INITIAL_STATE = {
  corpUsers: [],
  page: 1,
  pageCount: 1,
  pageSize: 10,
  query: INITIAL_QUERY,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE:
      return {
        ...state,
      };
    case CONSTANTS.UPDATE:
      return {
        ...state,
      };
    case CONSTANTS.UPDATE_PASSWORD:
      return {
        ...state,
      };
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        corpUsers: action.corpUsers,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
        query: action.query,
      };
    case CONSTANTS.DELETE:
      return {
        ...state,
        corpUsers: _remove(
          state.corpUsers,
          (corpUser) => corpUser.id !== action.id,
        ),
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          corpTitle: action.corpTitle,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useCorpUsersStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const create = useCallback(
    async ({
      level,
      username,
      realname,
      corpTitle,
      password,
      contact,
      email,
      type,
    }) => {
      return await api.post("/corp-users", {
        level,
        username,
        realname,
        corpTitle,
        password,
        contact,
        email,
        type,
      });
    },
    [api.post],
  );

  const deleteCorpUser = useCallback(
    async (id) => {
      await api.del(`/corp-users/${id}`);
      dispatch({
        type: CONSTANTS.DELETE,
        id,
      });
    },
    [api.del],
  );

  const fetchAll = useCallback(
    async ({
      corpTitle = "",
      page = state.page,
      pageSize = state.pageSize,
    } = {}) => {
      const { corpUsers, pageCount } = await api.get("/corp-users", {
        corpTitle,
        page,
        pageSize,
      });

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        corpUsers: corpUsers.map((c) => new CorpUser(c)),
        page,
        pageCount,
        pageSize,
      });

      dispatch({
        type: CONSTANTS.SET_QUERY,
        corpTitle,
      });
    },
    [api.get, dispatch],
  );

  const fetchByIdOnTheFly = useCallback(
    async (id) => {
      const corpUser = await api.get(`/corp-users/${id}`);

      console.log(corpUser);
      return new CorpUser(corpUser);
    },
    [api.get],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/corp-users/${id}`, data);
    },
    [api.put],
  );

  const updateLogoImage = useCallback(
    (id, formData) => {
      return api.putFile(`/corp-users/${id}/logo-image`, formData);
    },
    [api.putFile],
  );

  const updatePassword = useCallback(
    (id, { password }) => {
      return api.put(`/corp-users/${id}/password`, { password });
    },
    [api.put],
  );

  const addSpot = useCallback(
    (id, spotId) => {
      return api.put(`/corp-users/${id}/spots`, {
        spotIds: [spotId],
      });
    },
    [api.put],
  );

  const deleteSpot = useCallback(
    (id, spotId) => {
      return api.del(`/corp-users/${id}/spots/${spotId}`);
    },
    [api.del],
  );

  /**
   * 스케쥴러 수동 처리 : 올리브영
   */
  const register = useCallback(
    ({ corp, date }) => {
      return api.post(`/etc/${corp}/register`, { date });
    },
    [api.put],
  );

  return {
    state,
    addSpot,
    create,
    deleteCorpUser,
    deleteSpot,
    fetchAll,
    fetchByIdOnTheFly,
    update,
    updateLogoImage,
    updatePassword,
    register,
  };
};
