import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _noop from "lodash/noop";
import { saveAs } from "file-saver";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate } from "@/lib/date";
import { CreateDongModal, EditDongModal } from "@/Modals";
import { useDongsStore, useModalStore } from "@/store/hooks";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const DongsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const DeleteColumn = memo(({ id, onDelete = _noop }) => {
  function handleClickDelete() {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      onDelete(id);
    }
  }

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      삭제
    </SmallButton>
  );
});

const EditColumn = ({ dong }) => {
  const { ...actions } = useDongsStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  function handleClickEdit() {
    openModal(
      <EditDongModal
        key="edit-dong-modal"
        dong={dong}
        onUpdate={handleUpdate}
      />,
    );
  }

  return (
    <SmallButton onClick={handleClickEdit} variant="warning">
      수정
    </SmallButton>
  );
};

const FeeColumn = memo(({ id, initFee, name, onAfterUpdate = _noop }) => {
  const { ...actions } = useDongsStore();

  const [fee, setFee] = useState("");

  useEffect(() => {
    setFee(initFee || "");
  }, [initFee]);

  const handleChange = useCallback((e) => {
    setFee(e.target.value);
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!fee || fee < 1) {
        window.alert("운임을 입력해주십시오.");
      } else {
        actions
          .update(id, { fee })
          .then(() => {
            window.alert(`${name} 지역 운임을 ${fee}원으로 변경했습니다. `);
            onAfterUpdate();
          })
          .catch((e) => {
            window.alert(`운임 수정에 실패했습니다: Status Code: ${e.status}`);
          });
      }
    },
    [fee, id, name],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Row>
        <Col sm={9}>
          <Form.Control
            name="fee"
            onChange={handleChange}
            value={fee}
            type="number"
            min={0}
          />
        </Col>
        <Col sm={3}>
          <Button type="submit">변경</Button>
        </Col>
      </Form.Row>
    </Form>
  );
});

export default () => {
  const { state, ...actions } = useDongsStore();
  const { openModal } = useModalStore();
  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleClickDownload = useCallback(async () => {
    try {
      const deliveries = await actions.download();
      saveAs(deliveries, "동목록.xlsx");
    } catch (e) {
      window.alert(`목록다운에 실패하였습니다.\n에러메시지:${e.message}`);
    }
  }, [actions.download]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateDongModal key="create-dong-modal" onCreate={handleCreate} />,
    );
  }, [handleCreate, openModal]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    actions.setQuery({ page: 1 });
    fetchAll();
  }, [actions.setQuery, fetchAll]);

  const columns = useMemo(() => [
    {
      id: "sido",
      Header: "시/도",
      accessor: "sidoName",
      width: 120,
    },
    {
      id: "sigungu",
      Header: "시/군/구",
      accessor: "sigunguName",
      width: 180,
    },
    {
      id: "name",
      Header: "동명",
      accessor: "name",
      width: 140,
    },
    {
      id: "fee",
      Header: "운임",
      accessor: (row) => (
        <FeeColumn
          id={row.id}
          initFee={row.fee}
          name={row.name}
          onAfterUpdate={fetchAll}
        />
      ),
      width: 260,
    },
    {
      id: "updatedAt",
      Header: "비고",
      accessor: (row) =>
        row.feeUpdatedAt
          ? `${formatDate(new Date(row.feeUpdatedAt), "yyyy-MM-dd HH:mm:ss")} ${
              row.fee
            }원 적용됨`
          : "",
      width: 210,
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn dong={row} />;
      },
      selectable: false,
      width: 100,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => <DeleteColumn id={row.id} />,
      selectable: false,
      width: 100,
    },
  ]);

  return (
    <Container>
      <Title>동 관리</Title>

      <ButtonsRow>
        <Col>
          <Button type="button" onClick={handleClickCreate}>
            동 추가
          </Button>
        </Col>
        <RightButtonsGroupColumn>
          <StyledButton onClick={handleClickDownload}>
            목록 다운로드
          </StyledButton>
        </RightButtonsGroupColumn>
      </ButtonsRow>

      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="name"
                    onChange={handleChange}
                    placeholder="동 이름 조회"
                    value={state.query.name}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>

      <DongsTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.dongs}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
