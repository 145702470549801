import React, { useCallback } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import CsForm from "@/forms/CsForm";
import { useCsesStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ delivery, onCreate = _noop } = {}) => {
  const { ...actions } = useCsesStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async ({ text }) => {
      const data = {
        bookId: delivery.bookId,
        text,
      };

      actions
        .create(data)
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          window.alert(`CS 생성에 실패했습니다: ${e.message}`);
        });
    },
    [delivery],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>회사 CS 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <CsForm delivery={delivery} onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
