import React, { memo, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";

import styled from "@emotion/styled";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const Self = styled.div``;

export default memo(({ accept = "", maxSize = 100000000, onUpload }) => {
  const onDrop = useCallback((acceptedFiles) => {
    if (!acceptedFiles.length) {
      alert('첨부된 파일이 없습니다. 파일 또는 파일 사이즈를 확인해주세요. ')
    } else {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = (e) => {
          onUpload({ 
            binary: e.target.result, 
            file,
          });
        };

        reader.readAsBinaryString(file);
      });
    }
  }, []);
  const { getRootProps, getInputProps } = useDropzone({ accept, maxSize, onDrop });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...activeStyle,
      ...acceptStyle,
      ...rejectStyle,
    }),
    []
  );

  return (
    <Self {...getRootProps({ style })}>
      <input {...getInputProps()} />
      <p>파일을 올려놓거나 선택해주세요.</p>
    </Self>
  );
});
