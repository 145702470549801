import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { CustomDong } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  customDongs: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 20,
    dongName: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        customDongs: action.customDongs,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useCustomDongsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { items, pageCount } = await api.get("/custom-dongs", {
      page: state.query.page,
      pageSize: state.query.pageSize,
      dongName: state.query.dongName,
    });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      customDongs: items.map((d) => new CustomDong(d)),
      pageCount,
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query]
  );

  const bulkUpload = useCallback(
    (data) => {
      return api.post(`/custom-dongs/multiple`, data);
    },
    [api.post]
  );

  const create = useCallback(
    (data) => {
      return api.post(`/custom-dongs`, data);
    },
    [api.post]
  );

  const deleteCustomDong = useCallback(
    (id) => {
      return api.del(`/custom-dongs/${id}`);
    },
    [api.del]
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/custom-dongs/${id}`, data);
    },
    [api.put]
  );

  return {
    state,
    fetchAll,
    setQuery,
    bulkUpload,
    create,
    deleteCustomDong,
    update,
  };
};
