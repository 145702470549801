import React, { useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { FindRiderModal } from "@/Modals";
import { useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const Notice = styled.div`
  padding: 10px 0 0;
  font-weight: bold;
`;

const FormCheck = styled(Form.Check)`
  margin-top: 7px;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const UserForm = ({ onSubmit, initUser, withRiderManager }) => {
  const { register, reset, handleSubmit, errors, watch, getValues } = useForm();
  const { openModal, closeModal } = useModalStore();

  const [selectedRider, setSelectedRider] = useState(null);
  const [isRider, setIsRider] = useState(false);

  useEffect(() => {
    if (initUser) {
      if (initUser.authority.toString() === "3") {
        setIsRider(true);
        setSelectedRider(initUser.rider || {});
      }

      reset({
        authorizedForAllTypes: initUser.authorizedForAllTypes ? "1" : "0",
        authority: initUser.authority.toString(),
        locked: initUser.locked ? "1" : "0",
        realname: initUser.realname,
        username: initUser.username,
        type: initUser.type,
        riderId: initUser.rider && initUser.rider.id,
      });
    } else {
      reset({
        authority: "1",
        locked: "0",
      });
    }
  }, [initUser]);

  const handleClickFindRider = useCallback(() => {
    openModal(
      <FindRiderModal onSelect={handleSelectRider} key="find-rider-modal" />,
    );
  }, [openModal]);

  const handleSelectRider = useCallback(
    (rider) => {
      closeModal();
      setSelectedRider(rider);

      reset({
        ...getValues(),
        riderId: rider.id,
      });
    },
    [closeModal, getValues, reset],
  );

  const handleChangeAuthority = useCallback(
    (e) => {
      if (e.target.value === "3") {
        setIsRider(true);

        reset({
          ...getValues(),
          type: "RIDER_GROUP1_MANAGER",
          authorizedForAllTypes: "0",
        });
      } else {
        setIsRider(false);
        setSelectedRider(null);

        reset({
          ...getValues(),
          riderId: null,
          type: null,
        });
      }
    },
    [getValues, reset],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          사용자 ID
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            minLength={2}
            name="username"
            ref={register({ required: true })}
            disabled={initUser}
          />
          <ErrorText>{errors.username && "아이디를 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          사용자 이름
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="realname" ref={register({ required: true })} />
          <ErrorText>{errors.realname && "이름를 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      {!initUser && (
        <>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              비밀번호
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                name="password"
                ref={register({ required: true })}
                type="password"
              />
              <ErrorText>
                {errors.password && "비밀번호를 입력해주세요."}
              </ErrorText>
            </Col>
          </Form.Group>

          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              비밀번호재입력
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                name="passwordConfirm"
                ref={register({
                  validate: (value) => {
                    return value === watch("password");
                  },
                })}
                type="password"
              />
              <ErrorText>
                {errors.passwordConfirm && "비밀번호를 동일하게 입력해주세요."}
              </ErrorText>
              <Notice>
                비밀번호는 영문자, 특수문자, 숫자를 혼합하여 8자 이상으로
                설정해주십시오.
              </Notice>
            </Col>
          </Form.Group>
        </>
      )}

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          권한
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            inline
            label="수퍼관리자"
            type="radio"
            id="1"
            value="1"
            name="authority"
            ref={register}
            onChange={handleChangeAuthority}
          />
          <FormCheck
            inline
            label="부관리자"
            type="radio"
            id="2"
            value="2"
            name="authority"
            ref={register}
            onChange={handleChangeAuthority}
          />
          {withRiderManager && (
            <FormCheck
              inline
              label="라이더"
              type="radio"
              id="3"
              value="3"
              name="authority"
              ref={register}
              onChange={handleChangeAuthority}
            />
          )}
        </Col>
      </Form.Group>

      {isRider && (
        <>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              구분
            </Form.Label>
            <Col sm={9}>
              <FormCheck
                inline
                label="팀장"
                type="radio"
                id="RIDER_GROUP2_MANAGER"
                value="RIDER_GROUP2_MANAGER"
                name="type"
                ref={register}
              />
              <FormCheck
                inline
                label="조장"
                type="radio"
                id="RIDER_GROUP1_MANAGER"
                value="RIDER_GROUP1_MANAGER"
                name="type"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              라이더
            </Form.Label>
            <Col sm={9}>
              <OrigRow>
                <Col sm={8}>
                  <Form.Control
                    name="riderName"
                    value={selectedRider ? selectedRider.name : ""}
                    readOnly
                  />
                  <Form.Control name="riderId" ref={register} hidden />
                </Col>
                <Col sm={4}>
                  <Button type="button" onClick={handleClickFindRider}>
                    검색
                  </Button>
                </Col>
              </OrigRow>
            </Col>
          </Form.Group>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={3}>
              반품/픽업
            </Form.Label>
            <Col sm={9}>
              <FormCheck
                inline
                label="전체 조회"
                type="radio"
                id="authorizedForAllTypes-true"
                value="1"
                name="authorizedForAllTypes"
                ref={register}
              />
              <FormCheck
                inline
                label="지역그룹 조회"
                type="radio"
                id="authorizedForAllTypes-false"
                value="0"
                name="authorizedForAllTypes"
                ref={register}
              />
            </Col>
          </Form.Group>
        </>
      )}

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          상태
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            inline
            label="이용가능"
            type="radio"
            id="locked-false"
            value="0"
            name="locked"
            ref={register}
          />
          <FormCheck
            inline
            label="이용정지"
            type="radio"
            id="locked-true"
            value="1"
            name="locked"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Col md={{ span: 7, offset: 3 }}>
          <CustomButtom type="submit">
            {initUser ? "수정하기" : "생성하기"}
          </CustomButtom>
          <CustomButtom type="button" variant="secondary" onClick={closeModal}>
            닫기
          </CustomButtom>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default UserForm;
