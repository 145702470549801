import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { saveAs } from "file-saver";
import dfSubDays from "date-fns/subDays";

import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useApiStore } from "@/store/hooks";
import { DeliveriesStore } from "@/store";

export const CONSTANTS = {
  SET_QUERY: "SET_QUERY",
};

export const DOWNLOAD_TYPE_DELIVERIES = "DOWNLOAD_TYPE_DELIVERIES";
export const DOWNLOAD_TYPE_DELIVERIES_ANALYSIS =
  "DOWNLOAD_TYPE_DELIVERIES_ANALYSIS";
export const DOWNLOAD_TYPE_BACKUP_LOGS = "DOWNLOAD_TYPE_BACKUP_LOGS";
export const DOWNLOAD_TYPE_DATADOG = "DOWNLOAD_TYPE_DATADOG";
export const DOWNLOAD_TYPE_REVIEWS = "DOWNLOAD_TYPE_REVIEWS";
export const DOWNLOAD_TYPE_BALANCE_ACCOUNT = "DOWNLOAD_TYPE_BALANCE_ACCOUNT";

export const QUERY_DATE_TYPE_RECEIPT_DATE = "RECEIPT_DATE";
export const QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE = "PICKUP_COMPLETED_DATE";
export const QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE =
  "DELIVERY_COMPLETED_DATE";

export const QUERY_BACKUP_TYPE_DEFAULT = "DEFAULT";
export const QUERY_BACKUP_TYPE_GOOKMIN_BANK = "GOOKMIN_BANK";

export const QUERY_DATADOG_TYPE_ADMIN_LOGIN = "ADMIN_LOGIN";
export const QUERY_DATADOG_TYPE_ADMIN_INQUIRY = "ADMIN_INQUIRY";
export const QUERY_DATADOG_TYPE_ADMIN_INQUIRY_DETAIL = "ADMIN_INQUIRY_DETAIL";
export const QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_LOGIN = "RIDERAPP_LOGIN";
export const QUERY_DATADOG_TYPE_ADMIN_RIDERAPP_INQUIRY = "RIDERAPP_INQUIRY";

const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 1);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDateYesterday);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_STATE = {
  query: {
    downloadType: DOWNLOAD_TYPE_DELIVERIES, // 다운로드 타입

    dateFrom: initDateFrom,
    dateTo: initDateTo,

    // 배송목록 다운로드
    dateType: QUERY_DATE_TYPE_RECEIPT_DATE,

    // 백업 다운로드
    backupType: QUERY_BACKUP_TYPE_GOOKMIN_BANK,

    // 데이터독 다운로드
    datadogType: QUERY_DATADOG_TYPE_ADMIN_LOGIN,
    indexName: "main",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDownloadsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const downloadDatadog = useCallback(async () => {
    const result = await api.get("/logs/datadog/archives", {
      dateFrom: state.query.dateFrom,
      dateTo: state.query.dateTo,
      type: state.query.datadogType,
      indexName: state.query.indexName,
    });

    saveAs(
      result,
      `datadog_${state.query.datadogType}_${state.query.dateFrom}_${state.query.dateTo}.xlsx`,
    );
  }, [dispatch, state.query]);

  const downloadBackups = useCallback(async () => {
    const result = await api.get("/backups/gookmins", {
      from: state.query.dateFrom,
      to: state.query.dateTo,
      type: state.query.backupType,
    });

    window.open(result.file);
  }, [dispatch, state.query]);

  const downloadBalanceAccount = useCallback(async () => {
    const result = await api.get("/deliveries/download", {
      dateFrom: state.query.dateFrom,
      dateTo: state.query.dateTo,
      dateType: state.query.dateType,
      queryTypes: [DeliveriesStore.QUERY_BALANCE_ACCOUNT],
    });

    return result;
  }, [dispatch, state.query]);

  const downloadDeliveries = useCallback(async () => {
    const result = await api.get("/deliveries/analysis-download", {
      dateFrom: state.query.dateFrom,
      dateTo: state.query.dateTo,
      dateType: state.query.dateType,
    });

    window.open(result.file);
  }, [dispatch, state.query]);

  const downloadDeliveriesV2 = useCallback(async () => {
    const result = await api.get("/v2/deliveries/download", {
      dateFrom: state.query.dateFrom,
      dateTo: state.query.dateTo,
      dateType: state.query.dateType,
    });

    return result;
  }, [dispatch, state.query]);

  const downloadReviews = useCallback(async () => {
    return await api.post("/reviews/download", {
      dateFrom: state.query.dateFrom,
    });
  }, [dispatch, state.query]);

  return {
    state,
    setQuery,
    downloadDatadog,
    downloadBackups,
    downloadBalanceAccount,
    downloadDeliveries,
    downloadDeliveriesV2,
    downloadReviews,
  };
};
