import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  deliveries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useHellovisionStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async (query) => {
    const deliveries = await api.get('/etc/lghellovision', query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveries,
    });
  }, [api.get, dispatch]);

  const register = useCallback(
    () => api.post('/etc/lghellovision/register'),
    [api.post]
  );

  const sendPickup = useCallback(
    () => api.post('/etc/lghellovision/pickup'),
    [api.post]
  );

  const sendDelivered = useCallback(
    () => api.post('/etc/lghellovision/delivered'),
    [api.post]
  );
  
  return {
    state,
    fetchAll,
    register,
    sendPickup,
    sendDelivered,
  };
};
