import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  riders: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
    text: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        riders: action.riders,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeletedRidersStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const restore = useCallback(
    (riderId) => {
      return api.put(`/riders/${riderId}/restore`);
    },
    [api.put]
  );

  const fetchAll = useCallback(async () => {
    const { riders, pageCount } = await api.get("/riders/deleted", {
      page: state.query.page,
      pageSize: state.query.pageSize,
      text: state.query.text,
    });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      riders,
      pageCount,
    });
  }, [api.get, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query: {
          ...state.query,
          ...query,
        },
      });
    },
    [dispatch, state.query]
  );

  return {
    state,
    restore,
    fetchAll,
    setQuery,
  };
};
