import React from "react";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

const DeliveryWrap = styled.div`
  margin-bottom: 20px;
`;

const Dl = styled.dl`
  display: flex;
  margin-bottom: 5px;
`;
const Dt = styled.dt`
  margin-right: 5px;
`;
const Dd = styled.dd`
  margin-bottom: 0;
`;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const CorpUsersForm = ({ delivery, onSubmit }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <DeliveryWrap>
        <Dl>
          <Dt>예약번호</Dt>
          <Dd>{delivery.bookId}</Dd>
        </Dl>
        <Dl>
          <Dt>사측주문번호</Dt>
          <Dd>{delivery.orderIdFromCorp}</Dd>
        </Dl>
        <Dl>
          <Dt>접수점</Dt>
          <Dd>{delivery.spot?.name || ""}</Dd>
        </Dl>
        <Dl>
          <Dt>수하인명</Dt>
          <Dd>{delivery.receiverName}</Dd>
        </Dl>
        <Dl>
          <Dt>배송주소</Dt>
          <Dd>
            {delivery.receiverAddress || delivery.receiverAddressRoad || ""}{" "}
            {delivery.receiverAddressDetail}
          </Dd>
        </Dl>
      </DeliveryWrap>

      <Form.Group>
        <Form.Label>CS내용(500자)*</Form.Label>
        <Form.Control
          as="textarea"
          rows="3"
          maxLength={500}
          name="text"
          ref={register({
            required: "CS 내용을 입력해주세요.",
            maxLength: "500자 이하로 입력해주세요.",
          })}
        />
        <ErrorText>{errors.text && errors.text.message}</ErrorText>
      </Form.Group>

      <Button type="submit">생성하기</Button>
    </Form>
  );
};

export default CorpUsersForm;
