import React, { memo, useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  renderStatusByNumber,
  DELIVERY_TYPE_NORMAL,
  DELIVERY_TYPE_PICKUP,
  DELIVERY_TYPE_RETURN,
} from "@/Models/Delivery";

import {
  CreateCsModal,
  DeliveryModal,
  EditCsModal,
  FileUploadModal,
  ImageModal,
} from "@/Modals";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { TYPE_CHAINLOGIS, TYPE_USER } from "@/store/Cses";
import {
  useCsesStore,
  useDeliveriesStore,
  useModalStore,
  useLoadingStore,
} from "@/store/hooks";

const CsesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const AddWrap = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const ImageWrap = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 200px;
`;

const ClickableColumn = styled.span`
  text-decoration: underline;
  cursor: pointer;
`;

const CheckWrap = styled.div``;
const CreatedAtWrap = styled.div``;

const StyledTabs = styled(Tabs)`
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const AddField = memo(() => {
  const { handleSubmit, register } = useForm();

  const { ...deliveriesActions } = useDeliveriesStore();

  const onSubmit = useCallback(async ({ id }) => {
    if (id) {
      try {
        const response = await deliveriesActions.fetchTokenByIdOnTheFly(id);
        if (response.tokenForPublicAPI) {
          if (response.tokenForPublicAPI.length === 1) {
            window.open(
              `https://problem.doobalhero.kr/?token=${response.tokenForPublicAPI[0]}`,
            );
          } else if (response.tokenForPublicAPI.length > 1) {
            window.alert(
              `사측주문번호 ${id} 로 완료된 접수건이 ${response.tokenForPublicAPI.length}건 확인되었습니다. 두발히어로예약번호로 다시 시도해주십시오.`,
            );
          } else {
            window.alert(`${id}에 해당하는 배송건이 없습니다.`);
          }
        }
      } catch (e) {
        window.alert(`배송 조회에 실패했습니다.\n에러메시지: ${e.message}`);
      }
    } else {
      window.alert("배송 번호를 입력해주세요.");
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledRow>
        <StyledCol md={7}>
          <Form.Control
            name="id"
            placeholder="배송번호, 사측주문번호"
            ref={register}
          />
        </StyledCol>
        <StyledCol md={5}>
          <Button type="submit">CS 직접 등록</Button>
        </StyledCol>
      </StyledRow>
    </Form>
  );
});

const AddFieldFromAdmin = memo(({ onCreate = _noop }) => {
  const { openModal } = useModalStore();
  const { handleSubmit, register, reset } = useForm();

  const { ...deliveriesActions } = useDeliveriesStore();

  const onSubmit = useCallback(
    async ({ id }) => {
      if (id) {
        try {
          const response = await deliveriesActions.fetchAllById(id);
          if (response?.rows?.length > 0) {
            if (response?.rows?.length === 1) {
              openModal(
                <CreateCsModal
                  key={`create-cs-${id}-modal`}
                  delivery={response.rows[0]}
                  onCreate={onCreate}
                />,
              );

              reset();
            } else {
              window.alert(
                `사측주문번호 ${id} 로 완료된 접수건이 ${response?.rows?.length}건 확인되었습니다. 두발히어로예약번호로 다시 시도해주십시오.`,
              );
            }
          } else {
            window.alert(`${id}에 해당하는 배송건이 없습니다.`);
          }
        } catch (e) {
          window.alert(`배송 조회에 실패했습니다.\n에러메시지: ${e.message}`);
        }
      } else {
        window.alert("배송 번호를 입력해주세요.");
      }
    },
    [deliveriesActions.fetchAllById, onCreate],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <StyledRow>
        <StyledCol md={7}>
          <Form.Control
            name="id"
            placeholder="배송번호, 사측주문번호"
            ref={register}
          />
        </StyledCol>
        <StyledCol md={5}>
          <Button type="submit">CS 직접 등록</Button>
        </StyledCol>
      </StyledRow>
    </Form>
  );
});

const CheckColumn = memo(({ id }) => {
  const { ...actions } = useCsesStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleClickConfirm = useCallback(async () => {
    if (window.confirm("단순 처리하시겠습니까?")) {
      try {
        await actions.check(id);
        handleUpdate();
      } catch (e) {
        window.alert(`CS 처리에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.check, id]);

  return <SmallButton onClick={handleClickConfirm}>완료</SmallButton>;
});

const RevertCheckColumn = memo(({ id, bookId }) => {
  const { ...actions } = useCsesStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleClickConfirm = useCallback(async () => {
    if (window.confirm(`${bookId} CS완료를 취소하시겠습니까?`)) {
      try {
        await actions.revertCheck(id);
        handleUpdate();
      } catch (e) {
        window.alert(`CS 처리에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.revertCheck, id, bookId]);

  return <SmallButton onClick={handleClickConfirm}>취소</SmallButton>;
});

const renderUpdateLog = (log) => {
  const updated = !!log.text && "내용수정";
  const checked = log.checked ? "단순완료" : "단순취소";
  const writer = log.admin
    ? `관리자: ${log.admin.username}`
    : `라이더: ${log.rider?.name}`;

  return `${renderStatusByNumber(log.status) ||
    updated ||
    checked} (${writer})`;
};

const RenderCheckColumn = memo(({ cs }) => {
  return (
    <CheckWrap>
      {cs.updateLogsArray.map((el, index) => {
        return (
          <div key={index}>
            {formatDate(getDateByTimeZone(new Date(el[0])), "yyyy/MM/dd HH:mm")}
            <br />
            {el[1] &&
              (el[1].date
                ? renderUpdateLog(el[1])
                : el[1].admin?.realname || el[1].realname)}
          </div>
        );
      })}
      {!cs.updateLogsArray.length && cs.checked && cs.completedAt ? (
        <>
          {formatDate(getDateByTimeZone(cs.completedAt), "yyyy/MM/dd HH:mm")}
          <br />({cs.withDeliveryCompleted ? "배송완료" : "단순완료"})
        </>
      ) : (
        ""
      )}
    </CheckWrap>
  );
});

const CreatedAtColumn = memo(({ cs }) => {
  return (
    <CreatedAtWrap>
      {formatDate(getDateByTimeZone(cs.createdAt), "yyyy/MM/dd HH:mm")}
    </CreatedAtWrap>
  );
});

const DeleteColumn = memo(({ id }) => {
  const { ...actions } = useCsesStore();

  const handleDelete = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteCs(id);
        handleDelete();
      } catch (e) {
        window.alert(`CS 삭제에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.deleteCs, id]);

  return (
    <SmallButton variant="danger" onClick={handleClickDelete}>
      삭제
    </SmallButton>
  );
});

const EditColumn = memo(({ cs }) => {
  const { ...actions } = useCsesStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  function handleClickEdit() {
    openModal(
      <EditCsModal key="edit-cs-modal" onUpdate={handleUpdate} cs={cs} />,
    );
  }

  return (
    <SmallButton variant="warning" onClick={handleClickEdit}>
      수정
    </SmallButton>
  );
});

const RestoreColumn = memo(({ id }) => {
  const { ...actions } = useCsesStore();

  const handleRestore = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleClickRestore = useCallback(async () => {
    if (window.confirm("취소 해제 처리 하시겠습니까?")) {
      try {
        await actions.restoreCs(id);
        handleRestore();
      } catch (e) {
        window.alert(`CS 취소 해제에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.restoreCs, id]);

  return (
    <SmallButton variant="danger" onClick={handleClickRestore}>
      취소해제
    </SmallButton>
  );
});

const ImageColumn = ({ csId, image, onUpdate }) => {
  const { ...actions } = useCsesStore();
  const { openModal } = useModalStore();

  function handleClick() {
    openModal(<ImageModal key="image-modal" image={image} />);
  }

  const handleClickDelete = async () => {
    if (window.confirm("이미지를 삭제하시겠습니까?")) {
      try {
        await actions.deleteImage(csId);
        onUpdate();
      } catch (e) {
        window.alert(`이미지 삭제에 실패했습니다. ${e.message}`);
      }
    }
  };

  return (
    <>
      <ImageWrap onClick={handleClick}>
        <Image src={image} />
      </ImageWrap>
      <SmallButton variant="danger" onClick={handleClickDelete}>
        삭제
      </SmallButton>
    </>
  );
};

const ImageUploadColumn = ({ cs }) => {
  const { ...actions } = useCsesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();

  const onUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleClick = () => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: "image/*",
          maxSize: 10000000,
          onUpload: handleUploadImage,
        }}
        key="cs-upload-modal"
        title="CS 이미지 변경"
      />,
    );
  };

  const handleUploadImage = useCallback(
    async ({ file }) => {
      if (window.confirm("이미지를 등록하시겠습니까?")) {
        try {
          startLoading();

          let formData = new FormData();
          console.log(file);
          formData.append("file", file);

          await actions.updateImage(cs.id, formData);

          finishLoading();

          onUpdate();

          closeModal();
        } catch (e) {
          window.alert(
            `로고 이미지 변경에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    },
    [cs, actions.updateImage],
  );

  if (cs.image) {
    return <ImageColumn csId={cs.id} image={cs.image} onUpdate={onUpdate} />;
  } else {
    return (
      <SmallButton variant="warning" onClick={handleClick}>
        등록
      </SmallButton>
    );
  }
};

const BookIdColumn = ({ bookId, deliveryType }) => {
  const { openModal } = useModalStore();

  const onAfterUpdate = () => {};

  function handleClick() {
    openModal(
      <DeliveryModal
        backdrop
        key="book-detail-modal"
        bookId={bookId}
        onAfterUpdate={onAfterUpdate}
        type={deliveryType}
        prevPage="CS"
      />,
    );
  }

  return (
    <ClickableColumn onClick={handleClick}>
      {deliveryType === DELIVERY_TYPE_PICKUP && "(픽)"}
      {deliveryType === DELIVERY_TYPE_RETURN && "(반)"}
      {bookId}
    </ClickableColumn>
  );
};

export default () => {
  const { state, ...actions } = useCsesStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register } = useForm();

  useEffect(() => {
    fetchAll();
  }, [
    state.query.tab,
    state.query.page,
    state.query.pageSize,
    state.query.checked,
    state.query.deleted,
    state.query.spotName,
    state.query.queryString,
    state.query.deliveryRiderName,
    state.query.orderBy,
  ]);

  const fetchAll = async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `CS 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  };

  const handleSetChecked = useCallback(
    (checked) => {
      actions.setQuery({
        checked,
        deleted: false,
        page: 1,
      });
    },
    [actions.setQuery],
  );

  const handleSetDeleted = useCallback(() => {
    actions.setQuery({
      checked: null,
      deleted: true,
      page: 1,
    });
  }, [actions.setQuery]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setQuery({ orderBy });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        accessor: (row) => (
          <BookIdColumn
            bookId={row.bookId}
            type={row.type}
            status={row.status}
            deliveryType={row.deliveryType}
          />
        ),
        selectable: false,
        width: 160,
      },
      {
        Header: "사측주문번호",
        accessor: (row) => row.deliveryOrderIdFromCorp,
        selectable: false,
        width: 100,
      },
      {
        Header: "송하인명",
        accessor: (row) => row.deliverySenderName,
        selectable: false,
        width: 100,
      },
      {
        Header: "CS 내용",
        accessor: (row) => row.text,
        selectable: false,
        width: 300,
        ellipsis: false,
      },
      {
        Header: "기사님 답변",
        accessor: (row) => row.answer,
        selectable: false,
        width: 300,
        ellipsis: false,
      },
      {
        Header: "이미지",
        accessor: (row) => <ImageUploadColumn cs={row} />,
        selectable: false,
        width: 220,
      },
      {
        id: "createdAt",
        Header: "CS 등록일",
        accessor: (row) => <CreatedAtColumn cs={row} />,
        selectable: false,
        ellipsis: false,
        sortable: true,
      },
      {
        Header: "처리 여부",
        accessor: (row) => (row.checked ? "O" : "X"),
        selectable: false,
        width: 80,
      },
      {
        id: "completedAt",
        Header: "처리 일시",
        accessor: (row) => <RenderCheckColumn cs={row} />,
        selectable: false,
        sortable: true,
        width: 130,
      },
      {
        Header: "배송주소",
        accessor: (row) => row.receiverAddress,
        selectable: false,
      },
      {
        Header: "배송/수거 라이더",
        accessor: (row) =>
          `${
            row.deliveryType === DELIVERY_TYPE_NORMAL
              ? row.deliveryRiderName
              : row.pickupRiderName
          }(${
            row.deliveryType === DELIVERY_TYPE_NORMAL
              ? row.deliveryRiderMobile
              : row.pickupRiderMobile
          })`,
        selectable: false,
        width: 160,
      },
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => <EditColumn cs={row} />,
        selectable: false,
        width: 70,
      },
      {
        Header: "완료취소",
        accessor: (row) => (
          <RevertCheckColumn id={row.id} bookId={row.bookId} />
        ),
        selectable: false,
        width: 70,
      },
      {
        Header: "단순완료",
        accessor: (row) => <CheckColumn id={row.id} />,
        selectable: false,
        width: 70,
      },
      {
        Header: "삭제",
        accessor: (row) =>
          row.deleted ? (
            <RestoreColumn id={row.id} />
          ) : (
            <DeleteColumn id={row.id} />
          ),
        selectable: false,
        width: 100,
      },
    ],
    [],
  );

  const onSubmit = useCallback(
    ({ spotName, queryString, deliveryRiderName }) => {
      actions.setQuery({ spotName, queryString, deliveryRiderName });
    },
    [actions.setQuery],
  );

  const handleSelectTabs = useCallback((e) => {
    actions.setQuery({ tab: e });
  }, []);

  return (
    <Container fluid>
      <Title>CS 관리</Title>

      <StyledTabs
        defaultActiveKey={state.query.tab}
        transition={false}
        onSelect={handleSelectTabs}
      >
        <Tab eventKey={TYPE_USER} title="고객">
          <AddWrap>
            <AddField />
          </AddWrap>
        </Tab>
        <Tab eventKey={TYPE_CHAINLOGIS} title="회사">
          <AddWrap>
            <AddFieldFromAdmin onCreate={fetchAll} />
          </AddWrap>
        </Tab>
      </StyledTabs>

      <SearchRow>
        <ButtonWrap>
          <StyledButton
            onClick={() => handleSetChecked(null)}
            variant={
              !state.query.deleted && state.query.checked === null
                ? "info"
                : "light"
            }
          >
            전체
          </StyledButton>
          <StyledButton
            onClick={() => handleSetChecked(false)}
            variant={
              !state.query.deleted && state.query.checked === false
                ? "info"
                : "light"
            }
          >
            처리대기
          </StyledButton>
          <StyledButton
            onClick={() => handleSetChecked(true)}
            variant={
              !state.query.deleted && state.query.checked === true
                ? "info"
                : "light"
            }
          >
            처리완료
          </StyledButton>
          <StyledButton
            onClick={handleSetDeleted}
            variant={state.query.deleted === true ? "info" : "light"}
          >
            취소
          </StyledButton>
        </ButtonWrap>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={3}>
              <Form.Control
                name="spotName"
                placeholder="접수점"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={4}>
              <Form.Control
                name="queryString"
                placeholder="배송번호, 사측주문번호"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={3}>
              <Form.Control
                name="deliveryRiderName"
                placeholder="배송 라이더"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={2}>
              <Button type="submit" style={{ width: "100%" }}>
                조회
              </Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <CsesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.cses}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        previousPage={handlePreviousPage}
        onClickHeader={handleClickHeader}
      />
    </Container>
  );
};
