import React, { useCallback } from "react";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import { useAgPostalCodesStore, useModalStore } from "@/store/hooks";

import AgPostalCodeForm from "@/forms/AgPostalCodeForm";

const Title = styled.h3``;

export default ({ onCreate = _noop } = {}) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useAgPostalCodesStore();

  const onSubmit = useCallback(
    async (data) => {
      await actions
        .create(data)
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          window.alert(e.message);
        });
    },
    [actions.create],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>우편번호 추가</Title>
      </Modal.Header>
      <Modal.Body>
        <AgPostalCodeForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
