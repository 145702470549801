import React, { memo, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const FormCheck = styled(Form.Check)`
  margin-top: 7px;
`;

const Wrapper = styled.div``;

const WhiteSpace = styled.span`
  display: inline-block;
  width: 10px;
`;

const EditFooter = memo(() => {
  const { closeModal } = useModalStore();

  function handleClose() {
    closeModal();
  }

  return (
    <Wrapper>
      <Button type="submit">확인</Button>
      <WhiteSpace />
      <Button onClick={handleClose}>닫기</Button>
    </Wrapper>
  );
});

const CorpUsersForm = ({ onSubmit, initCorpUser }) => {
  const { register, handleSubmit, reset, errors } = useForm();

  useEffect(() => {
    if (initCorpUser) {
      reset({
        username: initCorpUser.username,
        realname: initCorpUser.realname,
        corpTitle: initCorpUser.corpTitle,
        contact: initCorpUser.contact,
        email: initCorpUser.email,
        level: initCorpUser.level,
        type: initCorpUser.type,
        useMasking: initCorpUser.useMasking ? "true" : "false",
        useIndependentPostalCodes: initCorpUser.useIndependentPostalCodes
          ? "true"
          : "false",
        editableByWorksheet: initCorpUser.editableByWorksheet
          ? "true"
          : "false",
      });
    } else {
      reset({
        level: "normal",
        type: "NORMAL",
        useMasking: "false",
        useIndependentPostalCodes: "false",
        editableByWorksheet: "false",
      });
    }
  }, [initCorpUser]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          기업회원 ID
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            minLength={2}
            name="username"
            ref={register({ required: true })}
            disabled={initCorpUser}
          />
          <ErrorText>
            {errors.username && "기업회원 ID를 입력해주세요."}
          </ErrorText>
        </Col>
      </Form.Group>
      {!initCorpUser && (
        <Form.Group as={OrigRow}>
          <Form.Label column sm={4}>
            비밀번호
          </Form.Label>
          <Col sm={8}>
            <Form.Control
              type="password"
              name="password"
              ref={register({ required: true })}
            />
            <ErrorText>
              {errors.password && "비밀번호를 입력해주세요."}
            </ErrorText>
          </Col>
        </Form.Group>
      )}
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          성명
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            placeholder="예: 삼성, LG..."
            name="realname"
            ref={register({ minLength: 2 })}
          />
          <ErrorText>{errors.realname && "2자 이상 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          회사명
        </Form.Label>
        <Col sm={8}>
          <Form.Control name="corpTitle" ref={register({ required: true })} />
          <ErrorText>{errors.corpTitle && "회사명을 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          연락처
        </Form.Label>
        <Col sm={8}>
          <Form.Control
            type="tel"
            placeholder="01012341234"
            name="contact"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          이메일
        </Form.Label>
        <Col sm={8}>
          <Form.Control type="email" name="email" ref={register} />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          권한
        </Form.Label>
        <Col sm={8}>
          <FormCheck
            inline
            label="일반"
            type="radio"
            id="level-normal"
            value="normal"
            name="level"
            ref={register}
          />
          <FormCheck
            inline
            label="매니저"
            type="radio"
            id="level-manager"
            value="manager"
            name="level"
            ref={register}
          />
          <FormCheck
            inline
            label="슈퍼"
            type="radio"
            id="level-super"
            value="super"
            name="level"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          유형
        </Form.Label>
        <Col sm={8}>
          <FormCheck
            inline
            label="일반기업"
            type="radio"
            id="type-normal"
            value="NORMAL"
            name="type"
            ref={register}
          />
          <FormCheck
            inline
            label="포스트박스퀵"
            type="radio"
            id="type-postbox"
            value="POSTBOX"
            name="type"
            ref={register}
          />
          <FormCheck
            inline
            label="개인사용자"
            type="radio"
            id="type-persnal"
            value="PERSONAL"
            name="type"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          마스킹 여부
        </Form.Label>
        <Col sm={8}>
          <FormCheck
            inline
            label="처리"
            type="radio"
            id="useMasking-true"
            value="true"
            name="useMasking"
            ref={register}
          />
          <FormCheck
            inline
            label="미처리"
            type="radio"
            id="useMasking-false"
            value="false"
            name="useMasking"
            ref={register}
          />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          개별 우편번호 사용 여부
        </Form.Label>
        <Col sm={8}>
          <FormCheck
            inline
            label="사용"
            type="radio"
            id="useIndependentPostalCodes-true"
            value="true"
            name="useIndependentPostalCodes"
            ref={register}
          />
          <FormCheck
            inline
            label="미사용"
            type="radio"
            id="useIndependentPostalCodes-false"
            value="false"
            name="useIndependentPostalCodes"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={4}>
          대량 수정
        </Form.Label>
        <Col sm={8}>
          <FormCheck
            inline
            label="가능"
            type="radio"
            id="editableByWorksheet-true"
            value="true"
            name="editableByWorksheet"
            ref={register}
          />
          <FormCheck
            inline
            label="불가능"
            type="radio"
            id="editableByWorksheet-false"
            value="false"
            name="editableByWorksheet"
            ref={register}
          />
        </Col>
      </Form.Group>

      {initCorpUser ? <EditFooter /> : <Button type="submit">생성하기</Button>}
    </Form>
  );
};

export default CorpUsersForm;
