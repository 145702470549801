import React, { useCallback } from "react";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import Modal from "@/components/Modal";

import BaseAddressForm from "@/forms/BaseAddressForm";

import { useModalStore, useBaseAddressesStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ baseAddressMemo, onUpdate = _noop }) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useBaseAddressesStore();

  const onSubmit = useCallback(
    async (data) => {
      if (baseAddressMemo) {
        let _data = { ...data };

        const id = baseAddressMemo.id;
        await actions
          .update(id, _data)
          .then(() => {
            window.alert(`수정되었습니다.`);
            onUpdate();
            closeModal();
          })
          .catch((e) => {
            window.alert(e.message);
          });
      }
    },
    [actions.update, baseAddressMemo],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>주소 정보 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <BaseAddressForm
          onSubmit={onSubmit}
          initBaseAddressMemo={baseAddressMemo}
        />

        {/* <Form onSubmit={handleSubmit(onSubmit)}>
          <OrigRow>
            <Col>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="memo"
                  ref={register({ required: "주소 정보를 입력해주세요." })}
                />
              </Form.Group>
              <ErrorText>{errors.memo && errors.memo.message}</ErrorText>
            </Col>
          </OrigRow>

          <Form.Group as={OrigRow}>
            <Col>
              <CustomButtom type="submit">수정</CustomButtom>
              <CustomButtom
                type="button"
                variant="secondary"
                onClick={handleClose}
              >
                닫기
              </CustomButtom>
            </Col>
          </Form.Group>
        </Form> */}
      </Modal.Body>
    </Modal>
  );
};
