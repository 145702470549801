import React, { memo, useCallback, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import daumAddress from "@/lib/daum-address";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const SearchButton = styled(Button)`
  width: 100%;
`;

const AddressUpdateField = memo(({ register }) => {
  const [address, setAddress] = useState("");

  const handleClickSearchButton = useCallback(async () => {
    const result = await daumAddress();
    setAddress(result.jibunAddress);
  }, [daumAddress]);

  return (
    <Form>
      <Form.Row>
        <Col sm={9}>
          <Form.Control
            name="address"
            value={address}
            ref={register({ required: true })}
            disabled
          />
        </Col>
        <Col sm={3}>
          <SearchButton onClick={handleClickSearchButton} type="button">
            검색
          </SearchButton>
        </Col>
      </Form.Row>
    </Form>
  );
});

const BaseAddressesCorrectionForm = ({ onSubmit }) => {
  const { register, handleSubmit, errors } = useForm();

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group>
        <Form.Label>주소</Form.Label>
        <AddressUpdateField register={register} />
        <ErrorText>{errors.address && "주소를 입력해주세요."}</ErrorText>
      </Form.Group>

      <Form.Group>
        <Form.Label>상세주소</Form.Label>
        <Form.Control name="addressDetail" ref={register} />
      </Form.Group>

      <Form.Group>
        <Form.Label>빌딩명</Form.Label>
        <Form.Control name="building" ref={register} />
      </Form.Group>

      <Form.Group>
        <Form.Label>동번호</Form.Label>
        <Form.Control name="buildingDongNumber" ref={register} />
      </Form.Group>

      <Form.Group>
        <Form.Label>위도</Form.Label>
        <Form.Control
          type="number"
          name="correctedPointLat"
          ref={register({ required: true })}
          step="0.000000001"
        />
        <ErrorText>
          {errors.correctedPointLat && "위도를 입력해주세요."}
        </ErrorText>
      </Form.Group>

      <Form.Group>
        <Form.Label>경도</Form.Label>
        <Form.Control
          type="number"
          name="correctedPointLng"
          ref={register({ required: true })}
          step="0.000000001"
        />
        <ErrorText>
          {errors.correctedPointLng && "경도를 입력해주세요."}
        </ErrorText>
      </Form.Group>

      <Button type="submit">생성하기</Button>
    </Form>
  );
};

export default BaseAddressesCorrectionForm;
