import React, {
  memo,
  useCallback,
  useEffect,
} from "react";import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import RiderBox from "@/components/DeliveriesSummary/RiderBox";
import { Button } from "@/components/Buttons";
import { 
  useDeliveriesSummaryStore,
  useLoadingStore, 
  useRidersStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const Wave = styled.span`
  margin: 0 10px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`

const RidersRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`
const RidersCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
  margin-bottom: 10px;
`

export default memo(() => {
  const { state, ...actions } = useDeliveriesSummaryStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { state: ridersState, ...ridersActions } = useRidersStore();

  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchRiderGroup2Id();
  }, []);

  useEffect(() => {
    if (state.updated) {
      finishLoading();
    }
  }, [state.updated]);

  const fetchAll = useCallback(async () => { 
    startLoading();

    try {
      await actions.fetchAll();
    } catch (e) {
      finishLoading();
      window.alert(e.message);
    }
  }, [actions.fetchAll]);

  const fetchRiderGroup2Id = useCallback(async () => {
    try {
      ridersActions.fetchAllRiderGroup2s();
    } catch (e) {
      window.alert(`라이더 팀을 조회하는데 실패했습니다.\n${e.message}`)
    }
  }, [
    ridersActions.fetchAllRiderGroup2s,
  ]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    if (state.query.riderGroup2Id) {
      fetchAll();
    } else {
      window.alert('라이더팀을 선택해주세요.')
    }
  }, [state.query]);

  return (
    <Container fluid>
      <Title>배송 기록</Title>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={4}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                라이더팀 선택
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  name="riderGroup2Id"
                  onChange={handleChange}
                  value={state.query.riderGroup2Id}
                >
                  <option>라이더팀 선택</option>
                  {ridersState.riderGroup2s.map((rg) => (
                    <option key={rg.id} value={rg.id}>
                      {rg.name}
                    </option>
                  ))}
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
          <Col sm={8}>
            <Form.Group as={StyledRow}>
              <Form.Label column sm={2}>
                조회 일자
              </Form.Label>
              <StyledCol sm={3}>
                <Form.Control id="start" name="dateFrom" onChange={handleChange} type="date" value={state.query.dateFrom} />
              </StyledCol>
              <Wave>~</Wave>
              <StyledCol sm={3}>
                <Form.Control id="end" name="dateTo" onChange={handleChange} type="date" value={state.query.dateTo} />
              </StyledCol>
              <StyledCol sm={2}>
                <Button variant="success" type="submit">
                  조회
                </Button>
              </StyledCol>
            </Form.Group>
          </Col>
          
        </Row>
      </Form>
      <RidersRow>
        {state.riders.map((rider, idx) => {
          return (
            <RidersCol md={4} key={idx}>
              <RiderBox rider={rider} />
            </RidersCol>
          )
        })}
      </RidersRow>
    </Container>
  );
});
