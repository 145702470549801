import React, { useCallback } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { useForm } from "react-hook-form";

import Modal from "@/components/Modal";
import { useAgPostalCodesStore, useModalStore } from "@/store/hooks";

import { Button } from "@/components/Buttons";
import { FindCorpUserModal } from "@/Modals";

const Title = styled.h3``;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

export default ({ onDelete = _noop } = {}) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const { openModal, closeModal } = useModalStore();
  const { ...actions } = useAgPostalCodesStore();

  const onSubmit = useCallback(
    async (data) => {
      await actions
        .deleteAgPostalCodeAll(data)
        .then(() => {
          onDelete();
          closeModal();
        })
        .catch((e) => {
          window.alert(e.message);
        });
    },
    [actions.deleteAgPostalCodeAll, onDelete],
  );

  function handleClickFindCorpUser() {
    openModal(
      <FindCorpUserModal
        onSelect={handleSelectCorpUser}
        key="find-corp-user-modal"
      />,
    );
  }

  const handleSelectCorpUser = useCallback((corpUser) => {
    closeModal();
    setValue("corpUserId", corpUser?.id);
  }, []);

  return (
    <Modal>
      <Modal.Header>
        <Title>우편번호 삭제</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              기업회원 아이디
            </Form.Label>
            <StyledCol sm={6}>
              <Form.Control
                readOnly
                name="corpUserId"
                ref={register({ required: "기업회원을 입력해주세요." })}
              />
              <ErrorText>
                {errors.corpUserId && errors.corpUserId.message}
              </ErrorText>
            </StyledCol>
            <StyledCol sm={2}>
              <Button type="button" onClick={handleClickFindCorpUser}>
                검색
              </Button>
            </StyledCol>
          </Form.Group>

          <Form.Group as={StyledRow}>
            <StyledCol md={{ span: 8, offset: 4 }}>
              <CustomButtom type="submit">삭제하기</CustomButtom>
            </StyledCol>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
