import React, { memo } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import styled from "@emotion/styled";

const RiderCounts = styled.span``;
const RiderTitle = styled.h4``;

const TextRed = styled.span`
  color: red;
`;

export default memo(
  ({ rider }) => {
    return (
      <Card>
        <Card.Header>
          <RiderTitle>{rider.name}</RiderTitle>
          {rider.deliveriesCount && (
            <RiderCounts>
              {rider.deliveriesCount.pickupScheduled} / {rider.deliveriesCount.pickupCompleted} / <TextRed>{rider.deliveriesCount.deliveryStarted}</TextRed> / <TextRed>{rider.deliveriesCount.deliveryCompleted}</TextRed>
            </RiderCounts>
          )}
        </Card.Header>
        <Card.Body>
          <Table>
            <thead>
              <tr>
                <th>동명</th>
                <th>수지/수완/<TextRed>배송</TextRed>/<TextRed>배완</TextRed></th>
              </tr>
            </thead>
            <tbody>
              {rider.dongs && rider.dongs.map((dong, index) => {
                return (
                  <tr key={index}>
                    <td>{dong.name}</td>
                    <td>
                      {dong.deliveriesCount.pickupScheduled || 0} / {dong.deliveriesCount.pickupCompleted || 0} / {dong.deliveriesCount.deliveryStarted || 0} / {dong.deliveriesCount.deliveryCompleted || 0}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    );
  }
);
