import React, { memo, useCallback, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { FindAllocationGroupsModal, FindDongsModal } from "@/Modals";
import { useCustomDongsStore, useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3``;

const BeonjiWrap = styled.div``;

const BeonjiRow = memo(({ row, onDelete }) => {
  function handleDelete() {
    onDelete(row);
  }

  return (
    <BeonjiWrap>
      {row}
      <SmallButton variant="danger" onClick={handleDelete}>
        삭제
      </SmallButton>
    </BeonjiWrap>
  );
});

export default ({ onCreate = _noop } = {}) => {
  const { closeModal, openModal } = useModalStore();
  const { ...actions } = useCustomDongsStore();
  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const [selectedDong, setSelectedDong] = useState(null);
  const [selectedAllocationGroup, setSelectedAllocationGroup] = useState(null);
  const [beonji2, setBeonji2] = useState([]);

  // 동찾기
  function handleClickFindDong() {
    openModal(
      <FindDongsModal onSelect={handleSelectDong} key="find-dongs-modal" />,
    );
  }

  function handleSelectDong(dong) {
    closeModal();
    setSelectedDong(dong[0]);

    reset({
      ...getValues(),
      dong: `${dong[0].name}`,
    });
  }

  // 번지2 array 추가 화면
  const addNewBeonji2 = useCallback(() => {
    const newBeonji2 = getValues().newBeonji2;
    if (newBeonji2) {
      let _beonji2 = [...beonji2];
      _beonji2.push(newBeonji2);
      setBeonji2(_beonji2);

      reset({
        ...getValues(),
        newBeonji2: "",
      });
    }
  }, [beonji2]);

  const deleteBeonji2 = useCallback(
    (row) => {
      const index = beonji2.findIndex((el) => el === row);

      let _beonji2 = [...beonji2];
      _beonji2.splice(index, 1);
      setBeonji2(_beonji2);
    },
    [beonji2],
  );

  // 배차그룹 찾기
  function handleClickAllocationGroup() {
    openModal(
      <FindAllocationGroupsModal
        onSelect={handleSelectAllocationGroup}
        key="find-allocation-group-modal"
      />,
    );
  }

  function handleSelectAllocationGroup(group) {
    closeModal();
    setSelectedAllocationGroup(group[0]);

    reset({
      ...getValues(),
      allocationGroup: `${group[0].name}`,
    });
  }

  const onSubmit = useCallback(
    async (data) => {
      if (selectedDong) {
        let _data = { ...data };
        _data.beonji2 = beonji2;
        _data.dongId = selectedDong.id;

        if (selectedAllocationGroup) {
          _data.allocationGroupId = selectedAllocationGroup.id;
        }

        if (!data.fee) {
          _data.fee = null;
        }

        await actions
          .create(_data)
          .then(() => {
            window.alert(`${selectedDong.name}에 커스텀동을 추가했습니다.`);
            onCreate();
            closeModal();
          })
          .catch((e) => {
            window.alert(e.message);
          });
      }
    },
    [actions.create, beonji2, selectedDong, selectedAllocationGroup],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>커스텀동 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              동
            </Form.Label>
            <StyledCol sm={6}>
              <Form.Control
                readOnly
                name="dong"
                ref={register({ required: "동을 선택해주세요." })}
              />
            </StyledCol>
            <StyledCol sm={2}>
              <Button type="button" onClick={handleClickFindDong}>
                검색
              </Button>
            </StyledCol>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <ErrorText>{errors.dong && errors.dong.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              번지1
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                type="number"
                name="beonji1"
                ref={register({ required: "번지1을 입력해주세요." })}
              />
            </StyledCol>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <ErrorText>{errors.beonji1 && errors.beonji1.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              번지2
            </Form.Label>
            <StyledCol sm={8}>
              {beonji2.map((row, i) => (
                <BeonjiRow key={i} onDelete={deleteBeonji2} row={row} />
              ))}
              <StyledRow>
                <StyledCol sm={8}>
                  <Form.Control
                    type="number"
                    name="newBeonji2"
                    ref={register}
                  />
                </StyledCol>
                <StyledCol sm={4}>
                  <Button type="button" onClick={addNewBeonji2}>
                    추가
                  </Button>
                </StyledCol>
              </StyledRow>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              운임
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control type="number" name="fee" ref={register} />
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              배차그룹
            </Form.Label>
            <StyledCol sm={6}>
              <Form.Control readOnly name="allocationGroup" ref={register} />
            </StyledCol>
            <StyledCol sm={2}>
              <Button type="button" onClick={handleClickAllocationGroup}>
                검색
              </Button>
            </StyledCol>
          </Form.Group>
          <Button type="submit">생성하기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
