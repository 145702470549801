import React, { useCallback } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import DongNotSupportedForm from "@/forms/DongNotSupportedForm";
import { useDongsNotSupportedStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useDongsNotSupportedStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async (data) => {
      actions
        .create(data)
        .then((response) => {
          if (response?.success) {
            onCreate();
            closeModal();
          } else {
            window.alert(`배송불가 동 생성에 실패했습니다`);
          }
        })
        .catch((e) => {
          window.alert(`배송불가 동 생성에 실패했습니다: ${e.message}`);
        });
    },
    [actions.create, onCreate],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>배송불가 동 추가</Title>
      </Modal.Header>
      <Modal.Body>
        <DongNotSupportedForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
