import React, { memo, useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import daumAddress from "@/lib/daum-address";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const SearchButton = styled(Button)`
  width: 100%;
`;

const FormCheck = styled(Form.Check)`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const AddressUpdateField = memo(({ register, initAddress, initPostalCode }) => {
  const [address, setAddress] = useState("");
  const [postalCode, setPostalCode] = useState("");

  useEffect(() => {
    setAddress(initAddress || "");
    setPostalCode(initPostalCode || "");
  }, [initAddress, initPostalCode]);

  const handleClickSearchButton = useCallback(async () => {
    const result = await daumAddress();
    setAddress(result.jibunAddress);
    setPostalCode(result.zonecode);
  }, [daumAddress]);

  return (
    <Form>
      <Form.Row>
        <Col sm={9}>
          <Form.Control
            name="address"
            value={address}
            ref={register({ required: true })}
            disabled
          />
          <Form.Control
            name="postalCode"
            value={postalCode}
            ref={register}
            hidden
          />
        </Col>
        <Col sm={3}>
          <SearchButton onClick={handleClickSearchButton} type="button">
            검색
          </SearchButton>
        </Col>
      </Form.Row>
    </Form>
  );
});

const SpotForm = ({ onSubmit, initSpot }) => {
  const { register, handleSubmit, reset, errors } = useForm();
  const { closeModal } = useModalStore();

  useEffect(() => {
    if (initSpot) {
      reset({
        name: initSpot.name,
        contact: initSpot.contact,
        address: initSpot.address,
        postalCode: initSpot.postalCode,
        addressDetail: initSpot.addressDetail,
        code: initSpot.code,
        estimatedTimeHour: initSpot.estimatedTimeHour,
        fee: initSpot.fee,
        memo: initSpot.memo,
        deliveryStartSmsActive: initSpot.deliveryStartSmsActive,
        deliveredSmsActive: initSpot.deliveredSmsActive,
        returnActive: initSpot.returnActive,
        returnEnabled: initSpot.returnEnabled,
        gatheringReference: initSpot.gatheringReference,
        searchableByOrderIdFromCorp: initSpot.searchableByOrderIdFromCorp,
        autoDeliveryAllocation:
          initSpot.autoDeliveryAllocation === undefined
            ? true
            : initSpot.autoDeliveryAllocation,
        duplicatedByOrderIdFromCorp: initSpot.duplicatedByOrderIdFromCorp,
        autoPickupAllocationOnWeekends: initSpot.autoPickupAllocationOnWeekends,
        codeFromCorp: initSpot.codeFromCorp,
        open: initSpot.open === undefined ? true : initSpot.open,
        postponedAlimtalkAvailable:
          initSpot.postponedAlimtalkAvailable === undefined
            ? true
            : initSpot.postponedAlimtalkAvailable,
        kurlyDrink: initSpot.kurlyDrink,
        luxuryAvailable: !!initSpot.luxuryAvailable,
      });
    } else {
      reset({
        autoDeliveryAllocation: true,
      });
    }
  }, [initSpot]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          수거지명(필수)
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="name" ref={register({ required: true })} />
          <ErrorText>{errors.name && "수거지명을 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          연락처(필수)
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="contact" ref={register({ required: true })} />
          <ErrorText>{errors.contact && "연락처를 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          주소(필수)
        </Form.Label>
        <Col sm={9}>
          <AddressUpdateField
            initAddress={initSpot ? initSpot.address : ""}
            initPostalCode={initSpot ? initSpot.postalCode : ""}
            register={register}
          />
          <ErrorText>{errors.address && "주소를 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          상세주소
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="addressDetail" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          부여코드(필수)
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="code" ref={register({ required: true })} />
          <ErrorText>{errors.code && "부여코드를 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          운임
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="fee" type="number" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          메모
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="memo" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          알림톡 도착예정시간 표시
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="estimatedTimeHour" type="number" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          배송출발 알림 전송 활성화
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="deliveryStartSmsActive"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          배송완료 알림 전송 활성화
        </Form.Label>
        <Col sm={9}>
          <FormCheck type="checkbox" name="deliveredSmsActive" ref={register} />
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          반품 활성화
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            as="select"
            name="returnEnabled"
            ref={register({ required: true })}
          >
            <option value="NONE">불가능</option>
            <option value="PARTNER_ONLY">파트너웹만 가능</option>
            <option value="PARTNER_RETURN_BOTH">
              파트너웹, 반품웹 둘 다 가능
            </option>
          </Form.Control>
          <ErrorText>
            {errors.returnEnabled && "반품 활성화를 선택해주세요."}
          </ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          합배송 로직
        </Form.Label>
        <Col sm={9}>
          <Form.Control
            as="select"
            name="gatheringReference"
            ref={register({ required: true })}
          >
            <option value="DEFAULT">기본값</option>
            <option value="ORDER_ID_FROM_CORP">사측주문번호</option>
            <option value="SENDER_WITH_ORDER_ID">
              사측주문번호 &#38; 송하인정보
            </option>
            <option value="NONE">해당사항 없음</option>
          </Form.Control>
          <ErrorText>
            {errors.gatheringReference && "합배송 로직을 선택해주세요."}
          </ErrorText>
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          사측주문번호로 배송 출발
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="searchableByOrderIdFromCorp"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          자동배송배차
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="autoDeliveryAllocation"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          orderId 중복 여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="duplicatedByOrderIdFromCorp"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          주말/휴일 자동수거배차 여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="autoPickupAllocationOnWeekends"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          배송지연 알림톡 여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="postponedAlimtalkAvailable"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          고가품 설정
        </Form.Label>
        <Col sm={9}>
          <FormCheck type="checkbox" name="luxuryAvailable" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          주류사 여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck type="checkbox" name="kurlyDrink" ref={register} />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          기업용코드
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="codeFromCorp" ref={register} />
        </Col>
      </Form.Group>

      {/* <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          공개여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck type="checkbox" name="open" ref={register} />
        </Col>
      </Form.Group> */}
      <Form.Group as={OrigRow}>
        <Col md={{ span: 7, offset: 3 }}>
          <CustomButtom type="submit">
            {initSpot ? "수정하기" : "생성하기"}
          </CustomButtom>
          <CustomButtom type="button" variant="secondary" onClick={closeModal}>
            닫기
          </CustomButtom>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default SpotForm;
