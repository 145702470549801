import React, { createContext, useCallback, useContext, useReducer } from "react";
import _remove from "lodash/remove";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  logs: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        logs: action.logs,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useReallocationLogsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { reallocationLogs: logs, pageCount } = await api.get(
      "/reallocation-logs",
      state.query
    );

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      logs,
      pageCount,
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback((query = {}) => {
    dispatch({
      type: CONSTANTS.SET_QUERY,
      query,
    });
  }, [dispatch]);

  return {
    state,
    fetchAll,
    setQuery,
  };
};
