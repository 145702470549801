import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { useApiStore, useErrorsStore } from "@/store/hooks";

export const CONSTANTS = {
  CLEAR: "CLEAR",
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  allocations: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CLEAR:
      return INITIAL_STATE;
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        allocations: action.allocations,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

function mergeAllocations(obj1, obj2, obj3) {
  const objMerged = [obj1, obj2, obj3];
  const result = {};

  objMerged.forEach((allocation) => {
    for (let [riderGroup1key, riderGroup1Value] of Object.entries(allocation)) {
      if (!result[riderGroup1key]) {
        result[riderGroup1key] = {};
      }

      // 라이더 그룹2
      for (let [riderGroup1Resultkey, riderGroup1ResultValue] of Object.entries(
        riderGroup1Value,
      )) {
        if (riderGroup1Resultkey === "riderGroup1") {
          result[riderGroup1key][riderGroup1Resultkey] = {
            ...result[riderGroup1key][riderGroup1Resultkey],
            ...riderGroup1ResultValue,
          };
        } else if (riderGroup1Resultkey === "riders") {
          if (!result[riderGroup1key][riderGroup1Resultkey]) {
            result[riderGroup1key][riderGroup1Resultkey] = {};
          }

          // 라이더
          for (let [riderKey, riderValue] of Object.entries(
            riderGroup1ResultValue,
          )) {
            if (!result[riderGroup1key][riderGroup1Resultkey][riderKey]) {
              result[riderGroup1key][riderGroup1Resultkey][riderKey] = {};
            }

            for (let [riderResultKey, riderResultValue] of Object.entries(
              riderValue,
            )) {
              if (riderResultKey === "rider") {
                result[riderGroup1key][riderGroup1Resultkey][riderKey][
                  riderResultKey
                ] = riderResultValue;
              } else if (riderResultKey === "dongs") {
                if (
                  !result[riderGroup1key][riderGroup1Resultkey][riderKey][
                    riderResultKey
                  ]
                ) {
                  result[riderGroup1key][riderGroup1Resultkey][riderKey][
                    riderResultKey
                  ] = {};
                }

                // 동
                for (let [dongKey, dongValue] of Object.entries(
                  riderResultValue,
                )) {
                  if (
                    !result[riderGroup1key][riderGroup1Resultkey][riderKey][
                      riderResultKey
                    ][dongKey]
                  ) {
                    result[riderGroup1key][riderGroup1Resultkey][riderKey][
                      riderResultKey
                    ][dongKey] = {};
                  }

                  for (let [dongResultKey, dongResultValue] of Object.entries(
                    dongValue,
                  )) {
                    if (dongResultKey === "dong") {
                      result[riderGroup1key][riderGroup1Resultkey][riderKey][
                        riderResultKey
                      ][dongKey][dongResultKey] = dongResultValue;
                    } else {
                      if (
                        result[riderGroup1key][riderGroup1Resultkey][riderKey][
                          riderResultKey
                        ][dongKey][dongResultKey]
                      ) {
                        result[riderGroup1key][riderGroup1Resultkey][riderKey][
                          riderResultKey
                        ][dongKey][dongResultKey] += dongResultValue;
                      } else {
                        result[riderGroup1key][riderGroup1Resultkey][riderKey][
                          riderResultKey
                        ][dongKey][dongResultKey] = dongResultValue;
                      }
                    }
                  }
                }
              } else {
                if (
                  result[riderGroup1key][riderGroup1Resultkey][riderKey][
                    riderResultKey
                  ]
                ) {
                  result[riderGroup1key][riderGroup1Resultkey][riderKey][
                    riderResultKey
                  ] += riderResultValue;
                } else {
                  result[riderGroup1key][riderGroup1Resultkey][riderKey][
                    riderResultKey
                  ] = riderResultValue;
                }
              }
            }
          }
        } else {
          if (result[riderGroup1key][riderGroup1Resultkey]) {
            result[riderGroup1key][
              riderGroup1Resultkey
            ] += riderGroup1ResultValue;
          } else {
            result[riderGroup1key][
              riderGroup1Resultkey
            ] = riderGroup1ResultValue;
          }
        }
      }
    }
  });

  return result;
}

export const useAllocationsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);
  const { actions: errorsActions } = useErrorsStore();

  const clear = useCallback(() => {
    dispatch({
      type: CONSTANTS.CLEAR,
    });
  }, [dispatch]);

  const fetchAll = useCallback(
    async (body) => {
      try {
        const allocationsPikcup = await api.get("/allocations", {
          ...body,
          type: "PICKUP",
        });
        const allocationsDelivery = await api.get("/allocations", {
          ...body,
          type: "DELIVERY",
        });
        const allocationsNotAllocated = await api.get("/allocations", {
          ...body,
          type: "NOT_ALLOCATED",
        });

        const allocations = mergeAllocations(
          allocationsPikcup,
          allocationsDelivery,
          allocationsNotAllocated,
        );

        dispatch({
          type: CONSTANTS.FETCH_ALL,
          allocations,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "배차 현황을 불러오는데 실패하였습니다.",
        });
      }
    },
    [api.get, dispatch],
  );

  // reallocate: async ({ allocationMatchType, bookIds, riderId }) => {
  //   return await Promise.all(
  //     bookIds.map((bookId) =>
  //       api.put(`/deliveries/${bookId}/reallocate`, {
  //         allocationMatchType,
  //         riderId,
  //       })
  //     )
  //   );
  // },

  const reallocateInBulk = useCallback(
    async ({ allocationMatchType, bookIds, targetRiderId }) => {
      return await api.put(`/deliveries/reallocate-in-bulk`, {
        allocationMatchType,
        bookIds,
        targetRiderId,
      });
    },
    [api.put],
  );

  const reallocateAll = useCallback(
    async ({ deliveryStatus, dongId, fromRiderId, targetRiderId }) => {
      return await api.put("/deliveries/reallocate-all", {
        deliveryStatus,
        dongId,
        fromRiderId,
        targetRiderId,
      });
    },
    [api.put],
  );

  return {
    state,
    clear,
    fetchAll,
    reallocateInBulk,
    reallocateAll,
  };
};
