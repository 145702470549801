import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  agPostalCodes: [],
  pageCount: 1,
  query: {
    allocationGroupName: "",
    corpUserId: null,
    corpUserName: "",
    page: 1,
    pageSize: 20,
    postalCode: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        agPostalCodes: action.agPostalCodes,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAgPostalCodesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const bulkUpload = useCallback(
    async ({ corpUserId, data, reset }) => {
      await api.post("/ag-postal-codes/multiple", {
        corpUserId,
        agPostalCodes: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "지역그룹명":
                  result.allocationGroupName = value;
                  break;
                case "우편번호":
                  result.postalCode = value;
                  break;
                case "운임":
                  result.fee = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        }),
        reset,
      });
      return true;
    },
    [api.post],
  );

  const bulkUploadNewOnly = useCallback(
    async ({ corpUserId, data, reset }) => {
      return await api.put("/ag-postal-codes/multiple/new-only", {
        corpUserId,
        agPostalCodes: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "지역그룹명":
                  result.allocationGroupName = value;
                  break;
                case "우편번호":
                  result.postalCode = value;
                  break;
                case "운임":
                  result.fee = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        }),
        reset,
      });
    },
    [api.post],
  );

  const download = useCallback(() => {
    return api.get("/ag-postal-codes/download");
  }, [api.get]);

  const fetchAll = useCallback(async () => {
    let query = {
      page: state.query.page,
      pageSize: state.query.pageSize,
      allocationGroupName: state.query.allocationGroupName,
      postalCode: state.query.postalCode,
    };

    if (state.query.corpUserId) {
      query.corpUserId = state.query.corpUserId;
    }

    const { items, totalCount } = await api.get("/ag-postal-codes", query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      agPostalCodes: items,
      pageCount:
        Math.floor(totalCount / state.query.pageSize) +
        (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query],
  );

  const create = useCallback(
    (data) => {
      return api.post(`/ag-postal-codes`, data);
    },
    [api.post],
  );

  const deleteAgPostalCode = useCallback(
    (id) => {
      return api.del(`/ag-postal-codes/${id}`);
    },
    [api.del],
  );

  const deleteAgPostalCodeAll = useCallback(
    ({ corpUserId }) => {
      return api.del(`/ag-postal-codes/all`, { corpUserId });
    },
    [api.del],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/ag-postal-codes/${id}`, data);
    },
    [api.put],
  );

  return {
    state,
    bulkUpload,
    bulkUploadNewOnly,
    download,
    fetchAll,
    setQuery,
    create,
    deleteAgPostalCode,
    deleteAgPostalCodeAll,
    update,
  };
};
