import React, { useCallback, useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";

import { useAllocationGroupsStore } from "@/store/hooks";

const AllocationGroupsTable = styled(Table)`
  th:nth-of-type(2) {
    width: 100px;
  }
`;

const AllocationGroupsTableRow = styled(Row)`
  padding: 10px 0;
`;

const SearchButtonColumn = styled(Col)`
  text-align: right;
`;

const Title = styled.h3``;

const SelectedAllocationGroupsTitle = styled.h5``;
const SelectedAllocationGroups = styled.p``;

export default ({ onSelect }) => {
  const [selectedAllocationGroups, setSelectedAllocationGroups] = useState([]);
  const { state, ...actions } = useAllocationGroupsStore();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, []);

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize, state.query.name]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  function handleHide() {
    actions.clear();
  }

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  function handleSelect() {
    if (!selectedAllocationGroups.length) {
      window.alert("1개 이상의 지역그룹을 선택해주세요");
    } else {
      onSelect(selectedAllocationGroups);
    }
  }

  function handleSelectAllocationGroup({ selectedRows }) {
    setSelectedAllocationGroups(selectedRows);
  }

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = ({ name = "" }) => {
    actions.setQuery({ name });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "이름",
        accessor: "name",
      },
    ],
    [],
  );

  return (
    <Modal onHide={handleHide}>
      <Modal.Header>
        <Title>지역그룹 검색하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Control
                      name="name"
                      placeholder="지역그룹 이름"
                      ref={register}
                    />
                  </Form.Group>
                </Col>
                <SearchButtonColumn md={{ offset: 2, span: 4 }}>
                  <Button type="submit">검색하기</Button>
                </SearchButtonColumn>
              </Row>
            </Form>
          </Col>
        </Row>
        <AllocationGroupsTableRow>
          <Col>
            <AllocationGroupsTable
              columns={columns}
              currentPage={state.query.page}
              data={state.allocationGroups}
              goToPage={handleGoToPage}
              onSelect={handleSelectAllocationGroup}
              nextPage={handleNextPage}
              pageCount={state.pageCount}
              pageSize={state.query.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </Col>
        </AllocationGroupsTableRow>
        <Row>
          <Col>
            <SelectedAllocationGroupsTitle>
              선택된 지역그룹 목록
            </SelectedAllocationGroupsTitle>
            <SelectedAllocationGroups>
              {selectedAllocationGroups
                .map((allocationGroup) => allocationGroup.name)
                .join(", ")}
            </SelectedAllocationGroups>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>선택하기</Button>
      </Modal.Footer>
    </Modal>
  );
};
