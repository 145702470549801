import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import XLSX from "xlsx";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";

import { CORP_USER_NAMES } from "@/constants";

import { FileUploadModal, PostalCodeModal } from "@/Modals";
import {
  useAgPostalCodesByCorpStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const PostCodesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const TabsWrap = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
`;

const TabButton = ({
  active,
  corpUserName,
  corpUserNameStr,
  onSelectCorpUser,
}) => {
  const handleSelectCorpUser = useCallback(() => {
    onSelectCorpUser(corpUserName);
  }, [corpUserName]);

  return (
    <StyledButton
      onClick={handleSelectCorpUser}
      variant={active ? "info" : "light"}
    >
      {corpUserNameStr}
    </StyledButton>
  );
};
const DetailColumn = memo(({ postalCode }) => {
  const { openModal } = useModalStore();

  const handleClick = useCallback(() => {
    openModal(
      <PostalCodeModal postalCode={postalCode} key="postal-code-modal" />,
    );
  }, [postalCode, openModal]);

  return (
    <SmallButton onClick={handleClick} type="button">
      상세
    </SmallButton>
  );
});

export default () => {
  const { state, ...actions } = useAgPostalCodesByCorpStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();

  useEffect(() => {
    fetchAll();
  }, [state.corpUserName]);

  // useEffect(() => {
  //   fetchAll();
  // }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        await actions.bulkUpload({
          data: worksheetArr,
        });
      } catch (e) {
        window.alert(
          `우편번호 대량 업로드에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
      closeModal();
      fetchAll();
    },
    [actions.bulkUpload, fetchAll],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleUploadBulkFile,
        }}
        key="ag-postal-codes-bulk-upload-by-corp-modal"
        title="우편번호 대량생성하기"
      />,
    );
  }, [handleUploadBulkFile]);

  const handleSelectCorpUser = useCallback((corpUserName, status) => {
    actions.setCorpUserName(corpUserName);

    if (status) {
      actions.setStatus(status);
    }
  }, []);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const columns = useMemo(() => [
    {
      Header: "우편번호",
      accessor: (row) => row.split("::")[row.split("::").length - 1],
      width: 240,
    },
    {
      Header: "상세",
      accessor: (row) => (
        <DetailColumn
          postalCode={row.split("::")[row.split("::").length - 1]}
        />
      ),
      width: 200,
    },
  ]);

  return (
    <Container>
      <Title>외부업체 대응 우편번호 관리</Title>

      <SearchRow>
        <TabsWrap>
          <TabButton
            active={state.corpUserName === CORP_USER_NAMES.OLIVE}
            corpUserName={CORP_USER_NAMES.OLIVE}
            corpUserNameStr="CJOY"
            onSelectCorpUser={handleSelectCorpUser}
          />
        </TabsWrap>
        <ButtonsWrap>
          <StyledButton type="button" onClick={handleClickBulkUpload}>
            대량 생성
          </StyledButton>
        </ButtonsWrap>
      </SearchRow>

      <PostCodesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.agPostalCodesByCorp}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
