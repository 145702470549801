import React from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import RiderNoticeForm from "@/forms/RiderNoticeForm";
import { useRiderNoticesStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useRiderNoticesStore();
  const { closeModal } = useModalStore();

  const onSubmit = async (data) => {
    if (data.title && data.text) {
      actions
        .create(data)
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          window.alert(`라이더 공지사항 생성에 실패했습니다: ${e.message}`);
        });
    } else {
      window.alert("필수 항목을 입력해주세요.");
    }
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>라이더 공지사항 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <RiderNoticeForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
