import React, { memo, useMemo, useCallback, useEffect, useState } from "react";
import _noop from "lodash/noop";
import ButtonGroups from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { CORP_USER_LEVEL_SUPER } from "@/Models";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  AddApiModal,
  AddQuickModal,
  CreateCorpUsersModal,
  EditCorpUsersModal,
  EditPasswordModal,
  FileUploadModal,
  SelectSpotsModal,
} from "@/Modals";
import {
  useCorpUsersStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const CorpUsersTable = styled(Table)``;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ApiTokenColumn = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  width: 100px;
  overflow: hidden;
`;

const SpotColumnWrap = styled.div``;

const Ul = styled.ul`
  margin-bottom: 0;
  padding-left: 20px;
`;
const Li = styled.li``;

const SpotDeleteButton = styled.span`
  cursor: pointer;
`;

const LogoImage = styled.img`
  max-width: 100px;
  max-height: 100px;
`;

const ApiKeyColumn = ({ id, apiKey }) => {
  const { openModal } = useModalStore();

  function handleClickApi() {
    openModal(<AddApiModal key={`add-api-${id}-modal`} id={id} />);
  }

  if (apiKey) {
    return <div>{apiKey}</div>;
  } else {
    return <SmallButton onClick={handleClickApi}>API등록</SmallButton>;
  }
};

const EditColumn = ({ id, corpTitleQuery }) => {
  const { state, ...actions } = useCorpUsersStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page: state.page,
      pageSize: state.pageSize,
    });
  }, [actions]);

  function handleClickEdit() {
    openModal(
      <EditCorpUsersModal
        key="edit-corp-users-modal"
        id={id}
        onUpdate={handleUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const LogoEditColumn = memo(({ id, logoImage, corpTitleQuery }) => {
  const { state, ...actions } = useCorpUsersStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();

  const handleUploadLogoImage = useCallback(
    async ({ file }) => {
      try {
        startLoading();

        let formData = new FormData();
        formData.append("file", file);

        await actions.updateLogoImage(id, formData);

        finishLoading();

        await actions.fetchAll({
          corpTitle: corpTitleQuery,
          page: state.page,
          pageSize: state.pageSize,
        });

        closeModal();
      } catch (e) {
        window.alert(
          `로고 이미지 변경에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [id, actions.updateLogoImage, actions.fetchAll],
  );

  function handleClickEdit() {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: "image/*",
          maxSize: 10000000,
          onUpload: handleUploadLogoImage,
        }}
        key="corp-user-logo-upload-modal"
        title={`로고 이미지 변경`}
      />,
    );
  }

  return (
    <>
      {logoImage && <LogoImage src={logoImage} />}
      <SmallButton onClick={handleClickEdit}>수정</SmallButton>
    </>
  );
});

const PasswordColumn = memo(({ id, corpTitleQuery }) => {
  const { state, ...actions } = useCorpUsersStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page: state.page,
      pageSize: state.pageSize,
    });
  }, [actions.fetchAll]);

  function handleClickEdit() {
    openModal(
      <EditPasswordModal
        key="update-password-modal"
        id={id}
        onUpdate={handleUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>변경</SmallButton>;
});

const DeleteColumn = memo(({ id, onDelete = _noop }) => {
  const { ...actions } = useCorpUsersStore();

  const handleClickDelete = useCallback(() => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteCorpUser(id);
      onDelete();
    }
  }, [actions, onDelete]);

  return <SmallButton onClick={handleClickDelete}>삭제</SmallButton>;
});

const CorpUserSpot = memo(
  ({ onAfterDelete = _noop, spot = {}, corpUserId }) => {
    const { ...actions } = useCorpUsersStore();
    const handleClickDelete = useCallback(async () => {
      if (window.confirm("수거지를 삭제하시겠습니까?")) {
        try {
          await actions.deleteSpot(corpUserId, spot.id);

          onAfterDelete();
        } catch (e) {
          window.alert(
            `수거지 삭제에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    }, []);

    return (
      <Li key={spot.id}>
        {spot.name}
        <SpotDeleteButton onClick={handleClickDelete}>(삭제)</SpotDeleteButton>
      </Li>
    );
  },
);

const SpotColumn = memo(({ onAfterDelete = _noop, id, spots, levelString }) => {
  const { openModal } = useModalStore();

  function handleClickSpot() {
    openModal(<SelectSpotsModal key="select-spots-modal" id={id} />);
  }

  return (
    <SpotColumnWrap>
      <Ul>
        {spots.map((spot) => {
          return (
            <CorpUserSpot
              key={spot.id}
              onAfterDelete={onAfterDelete}
              corpUserId={id}
              spot={spot}
            />
          );
        })}
      </Ul>
      {(!spots.length || levelString === CORP_USER_LEVEL_SUPER) && (
        <SmallButton onClick={handleClickSpot}>수거지 등록</SmallButton>
      )}
    </SpotColumnWrap>
  );
});

const QuickColumn = memo(({ id }) => {
  const { openModal } = useModalStore();

  function handleClickQuick() {
    openModal(<AddQuickModal key={`add-quick-${id}-modal`} id={id} />);
  }

  return <SmallButton onClick={handleClickQuick}>접수</SmallButton>;
});

export default () => {
  const [corpTitleQuery, setCorpTitleQuery] = useState("");
  const { state, ...actions } = useCorpUsersStore();
  const { openModal } = useModalStore();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  function handleClickCreate() {
    openModal(
      <CreateCorpUsersModal
        key="create-corp-users-modal"
        onCreate={handleCreate}
      />,
    );
  }

  const handleCreate = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleDelete = useCallback(() => {
    actions.fetchAll();
  }, [actions]);

  const handleAfterDeleteSpot = useCallback(() => {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page: state.page,
      pageSize: state.pageSize,
    });
  });

  function handleGoToPage(page) {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page,
      pageSize: state.pageSize,
    });
  }

  function handleNextPage() {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page: state.page + 1,
    });
  }

  function handlePreviousPage() {
    actions.fetchAll({
      corpTitle: corpTitleQuery,
      page: state.page - 1,
    });
  }

  function handleSetPageSize(pageSize) {
    actions.fetchAll({ corpTitle: corpTitleQuery, page: 1, pageSize });
  }

  const onSubmit = async ({ corpTitle = "" }) => {
    setCorpTitleQuery(corpTitle);

    actions.fetchAll({
      corpTitle,
      page: 1,
      pageSize: state.pageSize,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "분류",
        accessor: "typeString",
        width: 80,
      },
      {
        Header: "회원 ID",
        accessor: "username",
      },
      {
        Header: "성명",
        accessor: "realname",
        width: 120,
      },
      {
        Header: "회사명",
        accessor: "corpTitle",
        width: 120,
      },
      {
        Header: "연락처",
        accessor: "contact",
        width: 140,
      },
      {
        Header: "권한",
        accessor: "levelString",
        width: 80,
      },
      {
        id: "spot",
        Header: "담당 수거지",
        accessor: (row) => (
          <SpotColumn
            onAfterDelete={handleAfterDeleteSpot}
            id={row.id}
            spots={row.spots}
            levelString={row.levelString}
          />
        ),
        selectable: false,
      },
      {
        id: "request",
        Header: "퀵 접수",
        accessor: (row) => <QuickColumn id={row.id} />,
        selectable: false,
        width: 80,
      },
      {
        Header: "API",
        accessor: (row) => <ApiKeyColumn id={row.id} apiKey={row.apiKey} />,
        width: 80,
      },
      {
        Header: "API 토큰",
        accessor: (row) => <ApiTokenColumn>{row.apiToken}</ApiTokenColumn>,
      },
      {
        Header: "로고 수정",
        accessor: (row) => (
          <LogoEditColumn
            id={row.id}
            logoImage={row.logoImage}
            corpTitleQuery={corpTitleQuery}
          />
        ),
        ellipsis: false,
        selectable: false,
        width: 120,
      },
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => (
          <EditColumn
            id={row.id}
            editMode={true}
            corpTitleQuery={corpTitleQuery}
          />
        ),
        selectable: false,
        width: 80,
      },
      {
        id: "password",
        Header: "비밀번호 변경",
        accessor: (row) => (
          <PasswordColumn id={row.id} corpTitleQuery={corpTitleQuery} />
        ),
        selectable: false,
        width: 80,
      },
      {
        id: "delete",
        Header: "삭제",
        accessor: (row) => <DeleteColumn id={row.id} onDelete={handleDelete} />,
        selectable: false,
        width: 80,
      },
    ],
    [corpTitleQuery],
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Title>기업회원 관리</Title>
        </Col>
      </Row>
      <ButtonsRow>
        <Col>
          <ButtonGroups>
            <Button onClick={handleClickCreate}>기업회원 등록</Button>
          </ButtonGroups>
        </Col>
        <RightButtonsGroupColumn>
          <ButtonGroups>
            <Button>대량 생성</Button>
          </ButtonGroups>
        </RightButtonsGroupColumn>
      </ButtonsRow>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="corpTitle"
                    placeholder="기업회원 회사명"
                    ref={register}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <Row>
        <Col>
          <CorpUsersTable
            responsive
            bordered
            columns={columns}
            currentPage={state.page}
            data={state.corpUsers}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
