import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  holidays: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        holidays: action.holidays,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useHolidaysStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async (year) => {
    const holidays = await api.get('/holidays', { year });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      holidays,
    });
  }, [
    api.get, 
    dispatch,
  ]);

  const create = useCallback(async (data) => {
    return await api.post("/holidays", data);
  }, [api.post]);

  const deleteHoliday = useCallback((id) => {
    return api.del(`/holidays/${id}`);
  }, [api.del]);

  return {
    state,
    create,
    fetchAll,
    deleteHoliday,
  };
};
