import React, { useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore, useSpotsStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

export default ({ id, onUpdate = _noop }) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useSpotsStore();
  const { register, handleSubmit, reset } = useForm();

  function handleClose() {
    closeModal();
  }

  useEffect(() => {
    actions.fetchByIdOnTheFly(id).then((response) => {
      reset({
        limitedDeliveryHours: response.limitedDeliveryHours,
      });

      return response;
    });
  }, [reset]);

  const onSubmit = ({ limitedDeliveryHours }) => {
    const stringToNumber = limitedDeliveryHours ? parseInt(limitedDeliveryHours) : null;

    actions
      .updateDeliveryHours(id, { limitedDeliveryHours: stringToNumber })
      .then(() => {
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(e.message);
      });
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>배송 완료 제한 시간</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <OrigRow>
            <Col>
              <Form.Group>
                <Form.Control
                  type="number"
                  name="limitedDeliveryHours"
                  ref={register}
                />
              </Form.Group>
            </Col>
          </OrigRow>

          <Form.Group as={OrigRow}>
            <Col>
              <CustomButtom type="submit">확인</CustomButtom>
              <CustomButtom type="button" variant="secondary" onClick={handleClose}>닫기</CustomButtom>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
