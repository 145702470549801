import React, { useCallback } from "react";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import { useAllocationGroupsStore, useModalStore } from "@/store/hooks";

import AllocationGroupForm from "@/forms/AllocationGroupForm";

const Title = styled.h3``;

export default ({ allocationGroup, onUpdate = _noop }) => {
  const { ...actions } = useAllocationGroupsStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async (data) => {
      const _data = {
        ...data,
      };

      if (data.limit) {
        _data.limit = Number(data.limit);
      }

      try {
        await actions.update(allocationGroup.id, _data);

        closeModal();
        onUpdate();

        window.alert("지역그룹을 수정했습니다.");
      } catch (e) {
        window.alert(
          `지역그룹 수정에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [allocationGroup, actions.update, onUpdate],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>지역그룹 생성하기</Title>
      </Modal.Header>
      <Modal.Body>
        <AllocationGroupForm
          onSubmit={onSubmit}
          initAllocationGroup={allocationGroup}
        />
      </Modal.Body>
    </Modal>
  );
};
