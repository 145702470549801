import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { CORP_USER_NAMES } from "@/constants";

import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CORP_KURLY_ID = 619;
export const CORP_HANJIN_ID = 548;

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_CORP_USER_ID: "SET_CORP_USER_ID",
};

const INITIAL_STATE = {
  corpUserId: CORP_HANJIN_ID,
  totalCount: 0,
  totalCompletedCount: 0,
  recentCallbackLog: null,
  logs: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        totalCount: action.totalCount,
        totalCompletedCount: action.totalCompletedCount,
        recentCallbackLog: action.recentCallbackLog,
        logs: action.logs,
      };
    case CONSTANTS.SET_CORP_USER_ID:
      return {
        ...state,
        corpUserId: action.corpUserId,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useCorpCallbacksStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const {
      totalCount,
      totalCompletedCount,
      recentCallbackLog,
      logs,
    } = await api.get(`/corp-users/${state.corpUserId}/callbacks/status`);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      totalCount,
      totalCompletedCount,
      recentCallbackLog,
      logs,
    });
  }, [api.get, dispatch, state.corpUserId]);

  const setCorpUserId = useCallback(
    (corpUserId) => {
      dispatch({
        type: CONSTANTS.SET_CORP_USER_ID,
        corpUserId,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setCorpUserId,
  };
};
