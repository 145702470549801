import React, { useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import ButtonGroups from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import XLSX from "xlsx";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  CreateAllocationMatchModal,
  FileUploadModal,
  FindRiderModal,
} from "@/Modals";
import {
  useAllocationMatchesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";
import { QUERY_TYPE as ALLOCATION_MATCHES_QUERY_TYPE } from "@/store/AllocationMatches";

const AllocationMatchesTable = styled(Table)`
  th:nth-of-type(2),
  th:nth-of-type(5),
  th:nth-of-type(6) {
    width: 150px;
  }

  th:nth-of-type(3) {
    width: 200px;
  }
`;

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const RiderNameWrap = styled.div``;

const RiderName = styled.span`
  margin-right: 5px;
`;

const UpdateColumn = ({ id, riderName, onUpdate = _noop }) => {
  const { ...actions } = useAllocationMatchesStore();
  const { closeModal, openModal } = useModalStore();

  const handleClickFindRider = useCallback(() => {
    openModal(
      <FindRiderModal onSelect={handleSelectRider} key="find-rider-modal" />,
    );
  }, [id, openModal]);

  const handleSelectRider = useCallback(
    async (rider) => {
      if (rider) {
        try {
          await actions.update(id, {
            riderId: rider.id,
          });
          closeModal();
          onUpdate();
        } catch (e) {
          window.alert(
            `라이더 변경에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
        }
      }
    },
    [id, closeModal, actions.update],
  );

  return (
    <RiderNameWrap>
      <RiderName>{riderName}</RiderName>

      <SmallButton onClick={handleClickFindRider}>변경</SmallButton>
    </RiderNameWrap>
  );
};

const DeleteColumn = ({ id, onDelete = _noop }) => {
  const { ...actions } = useAllocationMatchesStore();

  const handleClickDelete = useCallback(() => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteAllocationMatches(id);
      onDelete();
    }
  }, [actions.deleteAllocationMatches, onDelete]);

  return <SmallButton onClick={handleClickDelete}>배차 삭제</SmallButton>;
};

export default () => {
  const { state, ...actions } = useAllocationMatchesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { closeModal, openModal } = useModalStore();
  const { handleSubmit, register } = useForm();

  useEffect(() => {
    fetchAll();
  }, [state.query]);

  const fetchAll = useCallback(
    ({ page, pageSize } = {}) => {
      actions.fetchAll({
        page: page || state.page,
        pageSize: pageSize || state.pageSize,
      });
    },
    [actions.fetchAll, state.page, state.pageSize],
  );

  function handleClickCreate() {
    openModal(
      <CreateAllocationMatchModal
        key="create-allocation-match-modal"
        onCreate={handleCreate}
      />,
    );
  }

  function handleClickBulkUpload() {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleUploadBulkFile,
        }}
        key="allocation-groups-bulk-upload-modal"
        title="배차 대량생성하기"
      />,
    );
  }

  const handleChangeQuery = useCallback(
    (e) => {
      switch (e.target.name) {
        case "type":
          actions.changeQuery({ type: e.target.value });
          break;
        default:
          break;
      }
    },
    [actions],
  );

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleUpdate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleDelete = useCallback(() => {
    fetchAll({
      page: 1,
    });
  }, [fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      fetchAll({ page });
    },
    [fetchAll],
  );

  const handleNextPage = useCallback(() => {
    fetchAll({ page: state.page + 1 });
  }, [state.page, fetchAll]);

  const handlePreviousPage = useCallback(() => {
    fetchAll({ page: state.page - 1 });
  }, [state.page, fetchAll]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      fetchAll({ pageSize });
    },
    [fetchAll],
  );

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);

      startLoading();
      try {
        await actions.bulkUpload(worksheetArr);
        finishLoading();

        closeModal();
        fetchAll();
      } catch (e) {
        alert(e.message);
        finishLoading();
      }
    },
    [actions, closeModal, finishLoading, startLoading, fetchAll],
  );

  const onSubmit = useCallback(
    ({ allocationGroupName }) => {
      actions.setQuery({ allocationGroupName });
    },
    [actions.setQuery],
  );

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      id: "kind",
      Header: () => (
        <Row>
          <Col>
            <div>종류</div>
            <Form.Control
              as="select"
              onChange={handleChangeQuery}
              name="type"
              value={state.query.type}
            >
              <option value={ALLOCATION_MATCHES_QUERY_TYPE.ALL}>전체</option>
              <option value={ALLOCATION_MATCHES_QUERY_TYPE.PICKUP}>수거</option>
              <option value={ALLOCATION_MATCHES_QUERY_TYPE.DELIVERY}>
                배송
              </option>
            </Form.Control>
          </Col>
        </Row>
      ),
      accessor: (row) => (row.allocationType === "1" ? "수거" : "배송"),
    },
    {
      Header: "시작시간",
      accessor: "timeFrom",
    },
    {
      Header: "종료시간",
      accessor: "timeTo",
    },
    {
      id: "agName",
      Header: "지역그룹명",
      accessor: (row) => row.allocationGroup.name,
    },
    {
      id: "riderName",
      Header: "라이더명",
      accessor: (row) => (
        <UpdateColumn
          id={row.id}
          riderName={row.rider.name}
          onUpdate={handleUpdate}
        />
      ),
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => <DeleteColumn id={row.id} onDelete={handleDelete} />,
    },
  ];

  return (
    <Container>
      <Title>배차 시간표 관리</Title>

      <ButtonsRow>
        <Col>
          <ButtonGroups>
            <Button onClick={handleClickCreate}>생성</Button>
          </ButtonGroups>
        </Col>
        <RightButtonsGroupColumn>
          <Button onClick={handleClickBulkUpload}>대량 생성</Button>
        </RightButtonsGroupColumn>
      </ButtonsRow>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="allocationGroupName"
                    placeholder="지역그룹명 조회"
                    ref={register}
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
        <Col></Col>
      </SearchRow>
      <AllocationMatchesTable
        responsive
        bordered
        columns={columns}
        currentPage={state.page}
        data={state.allocationMatches}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
