import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  dongsNotSupported: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        dongsNotSupported: action.dongsNotSupported,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDongsNotSupportedStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const bulkUpload = useCallback(
    async ({ data }) => {
      await api.post("/address/dongs/not-supported/bulk", {
        dongs: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "우편번호":
                  result.postalCode = value;
                  break;
                case "동":
                case "동명칭":
                  result.dong = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        }),
      });
      return true;
    },
    [api.post, state.corpUserName],
  );

  const create = useCallback(
    (data) => {
      return api.post(`/address/dongs/not-supported`, data);
    },
    [api.post],
  );

  const fetchAll = useCallback(async () => {
    const response = await api.get(`/address/dongs/not-supported/all`);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      dongsNotSupported: response?.rows || [],
    });
  }, [api.get, dispatch]);

  const deleteDongNotSupported = useCallback(
    (data) => {
      return api.del("/address/dongs/not-supported", data);
    },
    [api.del],
  );

  return {
    state,
    bulkUpload,
    create,
    fetchAll,
    deleteDongNotSupported,
  };
};
