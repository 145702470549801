import React from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";
import { getYear, getMonth, getDate } from "date-fns";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore, useHolidaysStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useHolidaysStore();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async ({ date }) => {
    actions
      .create({
        year: getYear(new Date(date)),
        month: getMonth(new Date(date)) + 1,
        date: getDate(new Date(date)),
      })
      .then(() => {
        onCreate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`휴일 생성에 실패했습니다: ${e.message}`);
      });
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>휴일 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              일자
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                name="date"
                ref={register({ required: true })}
                type="date"
              />  
              <ErrorText>{errors.date && "일자를 선택해주세요."}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <Button type="submit">생성하기</Button>
            </StyledCol>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
