import React from "react";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";

const Image = styled.img`
  width: 100%;
`

export default ({ image } = {}) => {
  return (
    <Modal size="lg" backdrop>
      <Modal.Body>
        <Image src={image}/>
      </Modal.Body>
    </Modal>
  );
};
