import React, { createContext, useCallback, useContext, useReducer } from "react";
import _uniq from 'lodash/uniq';
import dfSubDays from "date-fns/subDays";

import { DELIVERY_STATUS_TYPES } from "@/constants";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
  SET_UPDATED: "SET_UPDATED",
};

const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 1);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDateYesterday);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_STATE = {
  riders: [],
  deliveriesCount: [],
  query: {
    riderGroup2Id: "",
    dateFrom: initDateFrom,
    dateTo: initDateTo,
  },
  updated: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        riders: action.riders,
        deliveriesCount: action.deliveriesCount,
        updated: true,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    case CONSTANTS.SET_UPDATED:
      return {
        ...state,
        updated: action.updated,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesSummaryStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(
    async () => {
      dispatch({
        type: CONSTANTS.SET_UPDATED,
        updated: false,
      });

      // 조 조회
      const { items: riderGroups1 } = await api.get("/rider-group1s/no-pagination", {
        riderGroup2Id: state.query.riderGroup2Id,
      });

      // 조에 속한 라이더 조회 
      const promiseRiders = await Promise.all(
        riderGroups1.map((rg) =>
          api.get("/riders/no-pagination/v2", { riderGroup1Id: rg.id })
        )
      );

      let riders = [];
      await promiseRiders.forEach(pr => {
        riders = [ ...riders, ...pr.items ];
      });

      let foreachCount = 0;

      if (riders.length > 0) {
        _uniq(riders).forEach(async (rider, index) => {
          // 라이더별 배차된 배송 건수 조회
          await api.get(`/allocations/count-by-riders/${rider.id}`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((result) => {
            riders[index].deliveriesCount = {
              ...riders[index].deliveriesCount,
              pickupScheduled: result.count,
            };
          });

          await api.get(`/allocations/count-by-riders/${rider.id}`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_COMPLETED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((result) => {
            riders[index].deliveriesCount = {
              ...riders[index].deliveriesCount,
              pickupCompleted: result.count,
            };
          });

          await api.get(`/allocations/count-by-riders/${rider.id}`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((result) => {
            riders[index].deliveriesCount = {
              ...riders[index].deliveriesCount,
              deliveryStarted: result.count,
            };
          });

          await api.get(`/allocations/count-by-riders/${rider.id}`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((result) => {
            riders[index].deliveriesCount = {
              ...riders[index].deliveriesCount,
              deliveryCompleted: result.count,
            };
          });

          // 라이더별 배차된 동 목록 조회(수거지정)
          let dongArr = [];
          await api.get(`/allocations/riders/${rider.id}/allocated-dongs`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((dongs) => {
            dongs.items.forEach(async (dong) => {
              await api.get(`/allocations/dongs/${dong.id}/allocated-count`, { 
                deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_SCHEDULED,
                dateFrom: state.query.dateFrom,
                dateTo: state.query.dateTo,
                riderId: rider.id,
              }).then((result) => {
                let _dong = dongArr.find(el => el.id === dong.id);
                if (_dong) {
                  _dong.deliveriesCount = {
                    ..._dong.deliveriesCount,
                    pickupScheduled: result.count,
                  }
                } else {
                  dongArr.push({
                    ...dong,
                    deliveriesCount: {
                      pickupScheduled: result.count,
                    },
                  })
                }
              })
            })
          })

          // 라이더별 배차된 동 목록 조회(수거완료)
          await api.get(`/allocations/riders/${rider.id}/allocated-dongs`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_COMPLETED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((dongs) => {
            dongs.items.forEach(async (dong) => {
              await api.get(`/allocations/dongs/${dong.id}/allocated-count`, { 
                deliveryStatus: DELIVERY_STATUS_TYPES.PICKUP_COMPLETED,
                dateFrom: state.query.dateFrom,
                dateTo: state.query.dateTo,
                riderId: rider.id,
              }).then((result) => {
                let _dong = dongArr.find(el => el.id === dong.id);
                if (_dong) {
                  _dong.deliveriesCount = {
                    ..._dong.deliveriesCount,
                    pickupCompleted: result.count,
                  }
                } else {
                  dongArr.push({
                    ...dong,
                    deliveriesCount: {
                      pickupCompleted: result.count,
                    },
                  })
                }
              })
            })
          })

          // 라이더별 배차된 동 목록 조회(배송출발)
          await api.get(`/allocations/riders/${rider.id}/allocated-dongs`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((dongs) => {
            dongs.items.forEach(async (dong) => {
              await api.get(`/allocations/dongs/${dong.id}/allocated-count`, { 
                deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
                dateFrom: state.query.dateFrom,
                dateTo: state.query.dateTo,
                riderId: rider.id,
              }).then((result) => {
                let _dong = dongArr.find(el => el.id === dong.id);
                if (_dong) {
                  _dong.deliveriesCount = {
                    ..._dong.deliveriesCount,
                    deliveryStarted: result.count,
                  }
                } else {
                  dongArr.push({
                    ...dong,
                    deliveriesCount: {
                      deliveryStarted: result.count,
                    },
                  })
                }
              })
            })
          })

          // 라이더별 배차된 동 목록 조회(배송완료)
          await api.get(`/allocations/riders/${rider.id}/allocated-dongs`, { 
            deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED,
            dateFrom: state.query.dateFrom,
            dateTo: state.query.dateTo,
          }).then((dongs) => {
            dongs.items.forEach(async (dong) => {
              await api.get(`/allocations/dongs/${dong.id}/allocated-count`, { 
                deliveryStatus: DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED,
                dateFrom: state.query.dateFrom,
                dateTo: state.query.dateTo,
                riderId: rider.id,
              }).then((result) => {
                let _dong = dongArr.find(el => el.id === dong.id);
                if (_dong) {
                  _dong.deliveriesCount = {
                    ..._dong.deliveriesCount,
                    deliveryCompleted: result.count,
                  }
                } else {
                  dongArr.push({
                    ...dong,
                    deliveriesCount: {
                      deliveryCompleted: result.count,
                    },
                  })
                }
              })
            })
          })

          riders[index].dongs = dongArr;

          foreachCount++;
          if (foreachCount === _uniq(riders).length) {
            dispatch({
              type: CONSTANTS.FETCH_ALL,
              riders,
            });

            return riders || [];
          }
        })
      } else {
        dispatch({
          type: CONSTANTS.SET_UPDATED,
          updated: true,
        });
      }
    },
    [api.get, dispatch, state.query]
  );

  const setQuery = useCallback((query = {}) => {
    dispatch({
      type: CONSTANTS.SET_QUERY,
      query,
    });
  }, [dispatch]);

  return {
    state,
    fetchAll,
    setQuery,
  };
};
