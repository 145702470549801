import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Dong } from "@/Models";
import { useApiStore, useErrorsStore } from "@/store/hooks";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import { SoojioneIncorrection } from "@/Models/SoojioneIncorrection";

const zonedDateToday = getDateByTimeZone();
const initDate = formatDate(zonedDateToday);

export const CONSTANTS = {
  FETCH_DONGS: "FETCH_DONGS",
  FETCH_SOOJIONE_INCORRECTIONS: "FETCH_SOOJIONE_INCORRECTIONS",
  REFINE_ADDRESS: "REFINE_ADDRESS",
  SET_SOOJIONE_INCORRECTIONS_QUERY: "SET_SOOJIONE_INCORRECTIONS_QUERY",
};

const INITIAL_STATE = {
  addressesSoojioneIncorrections: [],
  dongs: [],
  dongsPage: 1,
  dongsPageCount: 1,
  dongsPageSize: 10,
  addressKakao: null,
  addressOne: null,
  addressTmap: null,
  soojioneIncorrectionsQuery: {
    date: initDate,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_DONGS:
      return {
        ...state,
        dongs: action.dongs,
        dongsPage: action.dongsPage,
        dongsPageCount: action.dongsPageCount,
        dongsPageSize: action.dongsPageSize,
      };
    case CONSTANTS.FETCH_SOOJIONE_INCORRECTIONS:
      return {
        ...state,
        addressesSoojioneIncorrections: action.addressesSoojioneIncorrections,
      };
    case CONSTANTS.REFINE_ADDRESS:
      return {
        ...state,
        addressKakao: action.addressKakao,
        addressOne: action.addressOne,
        addressTmap: action.addressTmap,
      };
    case CONSTANTS.SET_SOOJIONE_INCORRECTIONS_QUERY:
      return {
        ...state,
        soojioneIncorrectionsQuery: {
          ...action.query,
        },
      };

    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAddressStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);
  const { actions: errorsActions } = useErrorsStore();

  const fetchDongs = useCallback(
    async ({
      name,
      page = state.dongsPage,
      pageSize = state.dongsPageSize,
    } = {}) => {
      try {
        const { dongs, pageCount } = await api.get("/address/dongs", {
          name,
          page,
          pageSize,
        });

        dispatch({
          type: CONSTANTS.FETCH_DONGS,
          dongs: dongs.map((d) => new Dong(d)),
          dongsPage: page,
          dongsPageCount: pageCount,
          dongsPageSize: pageSize,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "동 목록을 불러오는데 실패하였습니다.",
        });
      }
    },
    [api.get, errorsActions.apiError],
  );

  const fetchSoojioneIncorrections = useCallback(async () => {
    const { rows } = await api.get(
      "/etc/addresses/soojione-incorrections",
      state.soojioneIncorrectionsQuery,
    );

    let addressesSoojioneIncorrections = rows
      ?.map((d) => new SoojioneIncorrection(d))
      .sort((a, b) => {
        return a.distanceRefineToLimitedRefined >
          b.distanceRefineToLimitedRefined
          ? -1
          : 1;
      });

    dispatch({
      type: CONSTANTS.FETCH_SOOJIONE_INCORRECTIONS,
      addressesSoojioneIncorrections,
    });
  }, [api.get, state.soojioneIncorrectionsQuery, dispatch]);

  const refineAddress = useCallback(
    async ({ address, addressDetail } = {}) => {
      const result = await api.get("/address/test", {
        address,
        addressDetail,
      });

      dispatch({
        type: CONSTANTS.REFINE_ADDRESS,
        addressKakao: result.kakao,
        addressOne: result.one,
        addressTmap: result.tmap,
      });

      return result;
    },
    [api.get],
  );

  const setSoojioneIncorrectionsQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_SOOJIONE_INCORRECTIONS_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchDongs,
    fetchSoojioneIncorrections,
    refineAddress,
    setSoojioneIncorrectionsQuery,
  };
};
