import React, { useCallback, useEffect, useState, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { useLoadingStore, useStatisticsStore } from "@/store/hooks";
import { initDate } from "@/store/Statistics";

const SELECT_TYPE_CORP = {
  value: "CORP",
  header: "수거지명",
  fileName: "일계_고객사집계",
};
const SELECT_TYPE_RIDER = {
  value: "RIDER",
  header: "라이더명",
  fileName: "일계_라이더집계",
};
const SELECT_TYPE_REGION = {
  value: "REGION",
  header: "지역명",
  fileName: "일계_지역집계",
};

const ButtonCol = styled(Col)`
  text-align: right;
  padding: 0;
`;

const SearchButton = styled(Button)`
  margin-left: 20px;
`;

const SearchRow = styled(Row)`
  padding: 15px 0;
`;

const StatisticsTable = styled(Table)`
  th {
    min-width: 95px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

export default () => {
  const { state, ...actions } = useStatisticsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { register, handleSubmit } = useForm();

  const INITIAL_DATE =
    state.query.date.split("-")[1] + state.query.date.split("-")[2];
  const [header, setHeader] = useState(SELECT_TYPE_CORP.header);
  const [fileName, setFileName] = useState(
    INITIAL_DATE + SELECT_TYPE_CORP.fileName
  );

  useEffect(() => {
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query]);

  const onSubmit = useCallback(
    async ({ date, type }) => {
      const prefix = date.split("-")[1] + date.split("-")[2];

      try {
        startLoading();
        await actions.setQuery({ date, type });
        switch (type) {
          case SELECT_TYPE_CORP.value:
            setHeader(SELECT_TYPE_CORP.header);
            setFileName(prefix + SELECT_TYPE_CORP.fileName);
            break;
          case SELECT_TYPE_RIDER.value:
            setHeader(SELECT_TYPE_RIDER.header);
            setFileName(prefix + SELECT_TYPE_RIDER.fileName);
            break;
          case SELECT_TYPE_REGION.value:
            setHeader(SELECT_TYPE_REGION.header);
            setFileName(prefix + SELECT_TYPE_REGION.fileName);
            break;
          default:
            break;
        }
      } catch (e) {
        window.alert(e.message);
      }
      finishLoading();
    },
    [actions.setQuery, finishLoading, startLoading]
  );

  let timeline = [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

  const columnsArray = [
    {
      Header: header,
      accessor: (row) => row.renderType(),
      selectable: false,
      width: 180,
    },
    {
      Header: "수완총",
      accessor: (row) => row.pickupedTotal,
      width: 90,
    },
    {
      Header: "배출총",
      accessor: (row) => row.deliveryStartedTotal,
      width: 90,
    },
    {
      Header: "배완총",
      accessor: (row) => row.deliveryDoneTotal,
      width: 90,
    },
  ];

  timeline.forEach((time) => {
    columnsArray.push(
      {
        Header: `${time}시 수완`,
        accessor: (row) => row.renderPickupedTime(time),
        width: 90,
      },
      {
        Header: `${time}시 배출`,
        accessor: (row) => row.renderDeliveryStartedTime(time),
        width: 90,
      },
      {
        Header: `${time}시 배완`,
        accessor: (row) => row.renderDeliveryDoneTime(time),
        width: 90,
      }
    );
  });

  const columns = useMemo(() => columnsArray, [header]);

  const excelData = [];

  state.statistics.forEach((s) => {
    excelData.push({
      [header]: s.renderType(),
      수완총: s.pickupedTotal,
      배출총: s.deliveryStartedTotal,
      배완총: s.deliveryDoneTotal,
      "8시 수완": s.renderPickupedTime(8),
      "8시 배출": s.renderDeliveryStartedTime(8),
      "8시 배완": s.renderDeliveryDoneTime(8),
      "9시 수완": s.renderPickupedTime(9),
      "9시 배출": s.renderDeliveryStartedTime(9),
      "9시 배완": s.renderDeliveryDoneTime(9),
      "10시 수완": s.renderPickupedTime(10),
      "10시 배출": s.renderDeliveryStartedTime(10),
      "10시 배완": s.renderDeliveryDoneTime(10),
      "11시 수완": s.renderPickupedTime(11),
      "11시 배출": s.renderDeliveryStartedTime(11),
      "11시 배완": s.renderDeliveryDoneTime(11),
      "12시 수완": s.renderPickupedTime(12),
      "12시 배출": s.renderDeliveryStartedTime(12),
      "12시 배완": s.renderDeliveryDoneTime(12),
      "13시 수완": s.renderPickupedTime(13),
      "13시 배출": s.renderDeliveryStartedTime(13),
      "13시 배완": s.renderDeliveryDoneTime(13),
      "14시 수완": s.renderPickupedTime(14),
      "14시 배출": s.renderDeliveryStartedTime(14),
      "14시 배완": s.renderDeliveryDoneTime(14),
      "15시 수완": s.renderPickupedTime(15),
      "15시 배출": s.renderDeliveryStartedTime(15),
      "15시 배완": s.renderDeliveryDoneTime(15),
      "16시 수완": s.renderPickupedTime(16),
      "16시 배출": s.renderDeliveryStartedTime(16),
      "16시 배완": s.renderDeliveryDoneTime(16),
      "17시 수완": s.renderPickupedTime(17),
      "17시 배출": s.renderDeliveryStartedTime(17),
      "17시 배완": s.renderDeliveryDoneTime(17),
      "18시 수완": s.renderPickupedTime(18),
      "18시 배출": s.renderDeliveryStartedTime(18),
      "18시 배완": s.renderDeliveryDoneTime(18),
      "19시 수완": s.renderPickupedTime(19),
      "19시 배출": s.renderDeliveryStartedTime(19),
      "19시 배완": s.renderDeliveryDoneTime(19),
      "20시 수완": s.renderPickupedTime(20),
      "20시 배출": s.renderDeliveryStartedTime(20),
      "20시 배완": s.renderDeliveryDoneTime(20),
      "21시 수완": s.renderPickupedTime(21),
      "21시 배출": s.renderDeliveryStartedTime(21),
      "21시 배완": s.renderDeliveryDoneTime(21),
      "22시 수완": s.renderPickupedTime(22),
      "22시 배출": s.renderDeliveryStartedTime(22),
      "22시 배완": s.renderDeliveryDoneTime(22),
      "23시 수완": s.renderPickupedTime(23),
      "23시 배출": s.renderDeliveryStartedTime(23),
      "23시 배완": s.renderDeliveryDoneTime(23),
    });
  });

  const handleClickDownload = useCallback(() => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }, [state.statistics]);

  return (
    <Container fluid>
      <Row>
        <Col>
          <Title>배송 통계 관리</Title>
        </Col>
      </Row>
      <SearchRow>
        <Col md={10}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col sm={3}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>일자 선택</Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      defaultValue={state.query.date}
                      name="date"
                      ref={register}
                      type="date"
                      max={initDate}
                    />
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={3}>
                <Form.Group as={Row}>
                  <Form.Label column sm={4}>타입</Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      as="select"
                      custom
                      defaultValue={state.query.type}
                      name="type"
                      ref={register}
                    >
                      <option value={SELECT_TYPE_CORP.value}>수거지</option>
                      <option value={SELECT_TYPE_RIDER.value}>라이더</option>
                      <option value={SELECT_TYPE_REGION.value}>지역</option>
                    </Form.Control>
                  </Col>
                </Form.Group>
              </Col>
              <Col sm={2}>
                <SearchButton type="submit">검색</SearchButton>
              </Col>
            </Row>
          </Form>
        </Col>
        <ButtonCol md={2}>
          <Button onClick={handleClickDownload} variant="primary">
            엑셀파일 다운로드
          </Button>
        </ButtonCol>
      </SearchRow>
      <Row>
        <Col>
          <StatisticsTable
            responsive
            bordered
            columns={columns}
            data={state.statistics}
            pagination={false}
          />
        </Col>
      </Row>
    </Container>
  );
};
