import React, { useMemo, useCallback, useEffect, useState } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";

import { CORP_USER_NAMES, DELIVERY_STATUS_TYPES } from "@/constants";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import {
  AlertModal,
  RetryCorpUploadFailedModal,
  RetryInputCorpUploadFailedModal,
} from "@/Modals";
import { renderStatusByNumber } from "@/Models/Delivery";

import {
  useCorpUploadFailedStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const TabsWrap = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;
`;

const ButtonsWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
`;

const TabButton = ({
  active,
  corpUserName,
  corpUserNameStr,
  status,
  uploadFailedsCount,
  onSelectCorpUser,
}) => {
  const handleSelectCorpUser = useCallback(() => {
    onSelectCorpUser(corpUserName, status);
  }, [corpUserName, status]);

  return (
    <StyledButton
      onClick={handleSelectCorpUser}
      variant={active ? "info" : "light"}
    >
      {corpUserNameStr}({uploadFailedsCount})
    </StyledButton>
  );
};

const RetryColumn = ({ item, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  const handleClickRetry = useCallback(() => {
    openModal(
      <RetryCorpUploadFailedModal
        initItem={item}
        key="retry-corp-upload-failed-modal"
        onUpdate={onUpdate}
      />,
    );
  }, [onUpdate]);

  return <SmallButton onClick={handleClickRetry}>재시도</SmallButton>;
};

export default () => {
  const { state, ...actions } = useCorpUploadFailedStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const [selectedDeliveries, setSelectedDeliveries] = useState([]);

  useEffect(() => {
    if (state.corpUserName) {
      fetchAll();
    } else {
      fetchTryAll();
    }
  }, [state.corpUserName, state.status]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `${state.corpUserName} 실패 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.corpUserName]);

  const fetchTryAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchTryAll();
    } catch (e) {
      window.alert(
        `시도실패 전체 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchTryAll]);

  const handleSelect = useCallback(
    ({ selectedRows }) => {
      setSelectedDeliveries(selectedRows);
    },
    [setSelectedDeliveries],
  );

  const handleSelectAll = useCallback(() => {
    actions.setCorpUserName("");
  }, []);

  const handleSelectCorpUser = useCallback((corpUserName, status) => {
    actions.setCorpUserName(corpUserName);

    if (status) {
      actions.setStatus(status);
    }
  }, []);

  const handleClickCancelBulk = useCallback(async () => {
    if (selectedDeliveries.length > 0) {
      if (
        window.confirm(
          `${selectedDeliveries.length}건을 정말 삭제하시겠습니까?`,
        )
      ) {
        try {
          startLoading();

          const response = await actions.cancelBulk(selectedDeliveries);

          const successes = response.filter(
            (el) => el.status !== "rejected" && el.value?.success,
          );

          window.alert(
            `${selectedDeliveries.length}건 중 ${successes.length}건을 삭제했습니다.`,
          );

          fetchAll();
        } catch (e) {
          alert(`삭제에 패했습니다.\n에러메시지: ${e.message}`);

          finishLoading();
        }
      }
    } else {
      alert("배송건을 선택해주세요.");
    }
  }, [selectedDeliveries, actions.cancelBulk]);

  const handleClickRetry = useCallback(() => {
    openModal(
      <RetryCorpUploadFailedModal
        key="retry-corp-upload-failed-modal"
        onUpdate={fetchAll}
      />,
    );
  }, [fetchAll]);

  const handleClickRetryInput = useCallback(() => {
    openModal(
      <RetryInputCorpUploadFailedModal
        key="retry-input-corp-upload-failed-modal"
        onUpdate={fetchAll}
      />,
    );
  }, [fetchAll]);

  const handleClickRetryBulk = useCallback(async () => {
    if (selectedDeliveries.length > 0) {
      try {
        startLoading();

        const response = await actions.retryBulk(selectedDeliveries);

        const successes = response.filter(
          (el) => el.status !== "rejected" && el.value?.success,
        );

        let text = `${selectedDeliveries.length}건 중 ${successes.length}건을 재시도했습니다.\n\n결과 목록`;

        response.map((el, idx) => {
          const _text = `\n${selectedDeliveries[idx].delivery?.bookId}: ${
            el.status === "rejected"
              ? `실패(${el.reason?.message})`
              : !el.value?.success
              ? `실패(${el.value?.errorMessage})`
              : "성공"
          }`;

          text += _text;
        });

        openModal(
          <AlertModal key="alert-modal" title="재시도 처리 결과" text={text} />,
        );

        fetchAll();
      } catch (e) {
        alert(`재시도에 실패했습니다.\n에러메시지: ${e.message}`);

        finishLoading();
      }
    } else {
      alert("배송건을 선택해주세요.");
    }
  }, [selectedDeliveries]);

  const handleClickHeader = useCallback(
    (orderBy) => {
      actions.setOrder(orderBy);
    },
    [actions.setOrder, state.uploadFaileds],
  );

  const columns = useMemo(
    () => [
      {
        Header: "접수번호",
        accessor: (row) => row.delivery?.bookId,
        selectable: false,
        width: 200,
      },
      {
        Header: "배송상태",
        accessor: (row) => renderStatusByNumber(row.status),
        selectable: false,
        width: 200,
      },
      {
        id: "ORDER_ID_FROM_CORP",
        Header: "사측주문번호",
        accessor: (row) => row.delivery?.orderIdFromCorp,
        selectable: false,
        width: 200,
        sortable: true,
      },
      {
        id: "DATE",
        Header: "등록시간",
        accessor: (row) => row.date,
        selectable: false,
        width: 200,
        sortable: true,
      },
      {
        Header: "접수점",
        accessor: (row) => row.delivery?.spot && row.delivery?.spot.name,
        selectable: false,
        width: 200,
      },
      {
        Header: "실제배송완료시간",
        accessor: (row) =>
          row.delivery?.deliveryCompletedDate &&
          formatDate(
            getDateByTimeZone(row.delivery?.deliveryCompletedDate),
            "yyyy/MM/dd HH:mm",
          ),
        selectable: false,
        width: 200,
      },
      {
        Header: "수동재시도",
        accessor: (row) => <RetryColumn item={row} onUpdate={fetchAll} />,
      },
    ],
    [state.uploadFaileds],
  );

  const columnsAll = useMemo(
    () => [
      {
        Header: "접수번호",
        accessor: (row) => row.bookId,
        selectable: false,
        width: 200,
      },

      {
        Header: "배송상태",
        accessor: (row) => renderStatusByNumber(Number(row.status)),
        selectable: false,
        width: 200,
      },
      {
        id: "ORDER_ID_FROM_CORP",
        Header: "사측주문번호",
        accessor: (row) => row.orderIdFromCorp,
        selectable: false,
        width: 200,
        sortable: true,
      },
      {
        Header: "기업회원명",
        accessor: (row) => row.corpUser?.corpTitle || "",
        selectable: false,
        width: 200,
      },
      // {
      //   Header: "실제배송완료시간",
      //   accessor: (row) =>
      //     row.delivery?.deliveryCompletedDate &&
      //     formatDate(
      //       getDateByTimeZone(row.delivery?.deliveryCompletedDate),
      //       "yyyy/MM/dd HH:mm",
      //     ),
      //   selectable: false,
      //   width: 200,
      // },
      // {
      //   Header: "수동재시도",
      //   accessor: (row) => <RetryColumn item={row} onUpdate={fetchAll} />,
      // },
    ],
    [state.uploadFaileds],
  );

  return (
    <Container fluid>
      <Title>외부업체 업로드 실패 관리</Title>
      <SearchRow>
        <TabsWrap>
          <StyledButton
            onClick={handleSelectAll}
            variant={state.corpUserName === "" ? "info" : "light"}
          >
            시도실패 전체
          </StyledButton>
          <TabButton
            active={state.corpUserName === CORP_USER_NAMES.CJ}
            corpUserName={CORP_USER_NAMES.CJ}
            corpUserNameStr="CJ대한통운"
            uploadFailedsCount={state.uploadFailedsCount[CORP_USER_NAMES.CJ]}
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={state.corpUserName === CORP_USER_NAMES.EMART}
            corpUserName={CORP_USER_NAMES.EMART}
            corpUserNameStr="이마트24"
            uploadFailedsCount={state.uploadFailedsCount[CORP_USER_NAMES.EMART]}
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={
              state.corpUserName === CORP_USER_NAMES.OLIVE &&
              state.status === DELIVERY_STATUS_TYPES.DELIVERY_STARTED
            }
            corpUserName={CORP_USER_NAMES.OLIVE}
            corpUserNameStr="CJOY출발"
            status={DELIVERY_STATUS_TYPES.DELIVERY_STARTED}
            uploadFailedsCount={
              state.uploadFailedsCount[CORP_USER_NAMES.OLIVE_STARTED]
            }
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={
              state.corpUserName === CORP_USER_NAMES.OLIVE &&
              state.status === DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED
            }
            corpUserName={CORP_USER_NAMES.OLIVE}
            corpUserNameStr="CJOY완료"
            status={DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED}
            uploadFailedsCount={
              state.uploadFailedsCount[CORP_USER_NAMES.OLIVE_COMPLETED]
            }
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={state.corpUserName === CORP_USER_NAMES.HANJIN}
            corpUserName={CORP_USER_NAMES.HANJIN}
            corpUserNameStr="한진"
            uploadFailedsCount={
              state.uploadFailedsCount[CORP_USER_NAMES.HANJIN]
            }
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={state.corpUserName === CORP_USER_NAMES.DRX}
            corpUserName={CORP_USER_NAMES.DRX}
            corpUserNameStr="디알엑스"
            uploadFailedsCount={state.uploadFailedsCount[CORP_USER_NAMES.DRX]}
            onSelectCorpUser={handleSelectCorpUser}
          />
        </TabsWrap>
        <ButtonsWrap>
          {state.corpUserName && (
            <StyledButton variant="danger" onClick={handleClickCancelBulk}>
              삭제
            </StyledButton>
          )}
          {state.corpUserName && (
            <StyledButton onClick={handleClickRetryBulk}>재시도</StyledButton>
          )}
          <StyledButton onClick={handleClickRetry}>수동 재시도</StyledButton>
          {state.corpUserName && (
            <StyledButton onClick={handleClickRetryInput}>
              입력 재시도
            </StyledButton>
          )}
        </ButtonsWrap>
      </SearchRow>

      <Table
        responsive
        bordered
        columns={state.corpUserName === "" ? columnsAll : columns}
        data={state.uploadFaileds}
        pagination={false}
        onSelect={handleSelect}
        onClickHeader={handleClickHeader}
      />
    </Container>
  );
};
