import React, { useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import dfSubDays from "date-fns/subDays";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useHellovisionStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 40px;
  display: flex;
`

const StyledButton = styled(Button)`
  margin-right: 10px;
`

const SubTitle = styled.h5``

const Wave = styled.span`
  margin: 0 10px;
`;

const HellovisionTable = styled(Table)`
  margin-top: 10px;
  margin-bottom: 100px;
`

export default () => {
  const { state, ...actions } = useHellovisionStore();
  const { handleSubmit, register, reset } = useForm();

  const zonedDateYesterday = dfSubDays(getDateByTimeZone(), 30);
  const zonedDateToday = getDateByTimeZone();
  const initDateFrom = formatDate(zonedDateYesterday);
  const initDateTo = formatDate(zonedDateToday);

  useEffect(() => {
    reset({
      dateFrom: initDateFrom,
      dateTo: initDateTo,
    })
  }, []);

  const getDeliveries = useCallback(async () => {
    if (window.confirm('배송 목록을 가져오시겠습니까?')) {
      try {
        await actions.register();
        window.alert('배송 목록을 가져옵니다.')
      } catch (e) {
        console.log(e)
        window.alert(
          `배송 목록을 가져오는데 실패하였습니다.\n에러메시지: ${e.message}`
        );
      }
    }
  }, [actions.register]);

  const sendPickup = useCallback(async () => {
    if (window.confirm('수거 완료 처리하시겠습니까?')) {
      try {
        await actions.sendPickup();
        window.alert('수거 완료 처리를 진행합니다.')
      } catch (e) {
        console.log(e)
        window.alert(
          `수거 완료 처리에 실패하였습니다.\n에러메시지: ${e.message}`
        );
      }
    }
  }, [actions.sendPickup]);

  const sendDelivered = useCallback(async () => {
    if (window.confirm('배송 완료 처리하시겠습니까?')) {
      try {
        await actions.sendDelivered();
        window.alert('배송 완료 처리를 진행합니다.')
      } catch (e) {
        console.log(e)
        window.alert(
          `배송 완료 처리에 실패하였습니다.\n에러메시지: ${e.message}`
        );
      }
    }
  }, [actions.sendDelivered]);

  const onSubmit = useCallback(async (data) => {
    try {
      await actions.fetchAll({
        dateFrom: formatDate(new Date(data.dateFrom), 'yyyyMMdd'),
        dateTo: formatDate(new Date(data.dateTo), 'yyyyMMdd'),
      });
    } catch (e) {
      console.log(e)
      window.alert(
        `목록 조회에 실패하였습니다.\n에러메시지: ${e.message}`
      );
    }
  }, [actions.fetchAll]);

  const columns = useMemo(() => [
    {
      Header: "주문번호",
      accessor: "ORDER_NO",
    },
    {
      Header: "접수일자",
      accessor: "RCPT_DT",
    },
    {
      Header: "접수일시",
      accessor: "REG_TIME",
    },
    {
      Header: "진행상태",
      accessor: "ORDER_STAT_NM",
    },
    {
      Header: "주문상태그룹",
      accessor: "ORDER_STAT_GRP",
    },
    {
      Header: "주문상태상세",
      accessor: "ORDER_STAT_CD",
    },
    {
      Header: "계약자명",
      accessor: "CTRTR_NM",
    },
    {
      Header: "배송주소",
      accessor: "IMP_ADDR",
    },
    {
      Header: "전화번호",
      accessor: "TEL_NO",
    },
    {
      Header: "휴대번호",
      accessor: "CP_NO",
    },
  ]);

  return (
    <Container>
      <Title>헬로비전 관리</Title>

      <SubTitle>수동 처리</SubTitle>
      <ButtonWrap>
        <StyledButton onClick={getDeliveries}>배송 목록 가져오기</StyledButton>
        <StyledButton onClick={sendPickup}>수거 완료 보내기</StyledButton>
        <StyledButton onClick={sendDelivered}>배송 완료 보내기</StyledButton>
      </ButtonWrap>

      <SubTitle>목록 조회</SubTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col sm={3}>
            <Form.Control
              id="start"
              name="dateFrom"
              type="date"
              ref={register}
            />
          </Col>
          <Wave>~</Wave>
          <Col sm={3}>
            <Form.Control
              id="end"
              name="dateTo"
              type="date"
              ref={register}
            />
          </Col>
          <Button variant="success" type="submit">검색</Button>
        </Row>
      </Form>
      <HellovisionTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        data={state.deliveries}
        pagination={false}
      />
    </Container>
  );
};
