import React, { useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { renderCorpUserTitle } from "@/constants";

import { useModalStore, useCorpUploadFailedStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3`
  margin: 0;
`;

const Notice = styled.div`
  color: red;
  margin-top: 10px;
`;

export default ({ initItem, onUpdate = _noop } = {}) => {
  const { closeModal } = useModalStore();
  const { state, ...actions } = useCorpUploadFailedStore();
  const { register, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    if (initItem) {
      reset({
        bookId: initItem.delivery?.bookId,
        statusNumber: initItem.delivery?.statusNubmer,
      });
    }
  }, [initItem]);

  const onSubmit = async ({ bookId, statusNumber }) => {
    if (bookId && statusNumber) {
      try {
        const response = await actions.retry({
          bookId,
          statusNumber: Number(statusNumber),
        });

        if (response.success) {
          window.alert("재시도 처리되었습니다.");
          onUpdate();
          closeModal();
        } else {
          window.alert(`재시도에 실패했습니다: ${response.errorMessage}`);
        }
      } catch (e) {
        window.alert(`재시도에 실패했습니다: ${e.message}`);
      }
    }
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>수동 재시도</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              배송번호
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control name="bookId" ref={register({ required: true })} />
              <ErrorText>
                {errors.bookId && "배송번호를 입력해주세요."}
              </ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              배송상태
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                as="select"
                name="statusNumber"
                ref={register({ required: true })}
              >
                <option value="">선택</option>
                <option value="0">접수</option>
                <option value="1">수거지정</option>
                <option value="2">수거완료</option>
                <option value="3">입고완료</option>
                <option value="8">배송배차</option>
                <option value="4">배송출발</option>
                <option value="5">배송완료</option>
                <option value="6">반송완료</option>
                <option value="7">분실완료</option>
                <option value="9">배송불가</option>
                <option value="10">사고</option>
                <option value="11">취소</option>
                <option value="12">배송연기</option>
                <option value="14">배송인수인계</option>
                <option value="15">간선하차</option>
                <option value="16">배달대행 접수</option>
                <option value="17">배달대행 접수 실패</option>
                <option value="18">배달대행에서 취소</option>
                <option value="19">배달대행에서 라이더 배차</option>
                <option value="20">배달대행에서 라이더 배차 취소</option>
              </Form.Control>
              <ErrorText>{errors.date && "일자를 선택해주세요."}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <Button type="submit">재시도</Button>
              <Notice>
                {renderCorpUserTitle(state.corpUserName)} 재시도 처리됩니다.
                다른 기업회원 예약번호/사측주문번호는 입력하지 말아주십시오.
              </Notice>
            </StyledCol>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
