export const SELECT_TYPE_CORP = "CORP";
export const SELECT_TYPE_RIDER = "RIDER";
export const SELECT_TYPE_REGION = "REGION";

export const DELIVERY_STATUS_0 = "pickuped";
export const DELIVERY_STATUS_1 = "deliveryStarted";
export const DELIVERY_STATUS_2 = "deliveryDone";

export const REGION_SEOUL_KEY = "seoul";
export const REGION_GYEONGI_KEY = "gyeongi";

export class Statistics {
  type = "";
  pickuped = null;
  deliveryStarted = null;
  deliveryDone = null;

  constructor(statistics) {
    this.type = statistics.type;
    this.pickuped = statistics.pickuped;
    this.deliveryStarted = statistics.deliveryStarted;
    this.deliveryDone = statistics.deliveryDone;
  }

  get deliveryDoneTotal() {
    return this.deliveryDone && this.deliveryDone.total;
  }

  get deliveryStartedTotal() {
    return this.deliveryStarted && this.deliveryStarted.total;
  }

  get pickupedTotal() {
    return this.pickuped && this.pickuped.total;
  }

  renderDeliveryDoneTime(time) {
    return this.deliveryDone && this.deliveryDone[`${time}`];
  }

  renderDeliveryStartedTime(time) {
    return this.deliveryStarted && this.deliveryStarted[`${time}`];
  }

  renderPickupedTime(time) {
    return this.pickuped && this.pickuped[`${time}`];
  }

  renderType() {
    switch (this.type) {
      case REGION_SEOUL_KEY:
        return "서울";
      case REGION_GYEONGI_KEY:
        return "경기도";
      default:
        return this.type && this.type.split("_").join(" ");
    }
  }
}
