import React, { useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { FindRidersModal } from "@/Modals";
import { useModalStore, useSpotsStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ spotId } = {}) => {
  const [selectedRider, setSelectedRider] = useState(null);
  const { ...actions } = useSpotsStore();
  const { closeModal, openModal } = useModalStore();
  const { register, handleSubmit } = useForm();

  function handleClickFindRider() {
    openModal(
      <FindRidersModal onSelect={handleSelectRiders} key="find-riders-modal" />,
    );
  }

  function handleSelectRiders(rider) {
    closeModal();
    setSelectedRider(rider);
  }

  const onSubmit = async (data) => {
    if (!selectedRider) {
      alert("라이더를 지정해주세요.");
    } else {
      actions
        .addPickupRiderOnTheFly(spotId, selectedRider.id, {
          timeFrom: data.timeFrom,
          timeTo: data.timeTo,
        })
        .then(() => {
          actions.fetchAll();
          closeModal();
        })
        .catch((e) => {
          window.alert(`라이더 등록에 실패했습니다: 실패 사유: ${e.message}`);
        });
    }
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>수거지 담당 수거 라이더 추가하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={4}>
              라이더
            </Form.Label>
            <Col sm={8}>
              {selectedRider && selectedRider.name + " "}
              <Button onClick={handleClickFindRider}>검색</Button>
            </Col>
          </Form.Group>

          <Form.Group as={OrigRow}>
            <Form.Label column sm={4}>
              시작시간
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                maxLength={4}
                minLength={4}
                name="timeFrom"
                ref={register}
              />
            </Col>
          </Form.Group>

          <Form.Group as={OrigRow}>
            <Form.Label column sm={4}>
              종료시간
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                maxLength={4}
                minLength={4}
                name="timeTo"
                ref={register}
              />
            </Col>
          </Form.Group>

          <Button type="submit">추가하기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
