import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _cloneDeep from "lodash/cloneDeep";
import _remove from "lodash/remove";
import { useApiStore, useErrorsStore } from "@/store/hooks";
import { ALLOCATION_MATCH_TYPES } from "../../constants";

const DEFAULT_PAGE_SIZE = 10;

export const CONSTANTS = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

export const QUERY_TYPE = {
  ALL: "ALL",
  DELIVERY: "DELIVERY",
  PICKUP: "PICKUP",
};

const INITIAL_STATE = {
  page: 1,
  pageCount: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  allocationMatches: [],
  query: {
    allocationGroupName: "",
    type: QUERY_TYPE.ALL,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE:
      return {
        ...state,
        allocationMatches: [
          action.allocationMatch,
          ..._cloneDeep(state.allocationMatches),
        ],
      };
    case CONSTANTS.DELETE:
      return {
        ...state,
        allocationMatches: _remove(
          state.allocationMatches,
          (am) => am.id !== action.id,
        ),
      };
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        allocationMatches: action.allocationMatches,
        page: action.page,
        pageCount: action.pageCount,
        pageSize: action.pageSize,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAllocationMatchesStore = () => {
  const api = useApiStore();
  const { actions: errorsActions } = useErrorsStore();
  const { dispatch, state } = useContext(Context);

  const bulkUpload = useCallback(
    async (data) => {
      await api.post("/allocation-matches/bulk", {
        allocationMatches: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "라이더명":
                case "라이더명_1":
                  result.riderName = value;
                  break;
                case "라이더휴대폰번호":
                case "라이더휴대폰번호_1":
                  result.riderMobile = value;
                  break;
                case "시작시간":
                  result.timeFrom = value;
                  break;
                case "종료시간":
                  result.timeTo = value;
                  break;
                case "배차그룹":
                  result.allocationGroupName = value;
                  break;
                case "유형":
                  result.allocationType =
                    value === "수거"
                      ? ALLOCATION_MATCH_TYPES.PICKUP
                      : ALLOCATION_MATCH_TYPES.DELIVERY;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        }),
      });
      return true;
    },
    [api.post],
  );

  const changeQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const create = useCallback(
    async (data) => {
      return api.post("/allocation-matches", data);
    },
    [api.post],
  );

  const deleteAllocationMatches = useCallback(
    async (id) => {
      await api.del(`/allocation-matches/${id}`);
      dispatch({
        type: CONSTANTS.DELETE,
        id,
      });
    },
    [api.del, dispatch],
  );

  const fetchAll = useCallback(
    async ({ page = 1, pageSize = DEFAULT_PAGE_SIZE } = {}) => {
      try {
        const { allocationMatches, pageCount } = await api.get(
          "/allocation-matches",
          {
            page,
            pageSize,
            ...state.query,
          },
        );

        dispatch({
          type: CONSTANTS.FETCH_ALL,
          allocationMatches,
          page,
          pageCount,
          pageSize,
        });
      } catch (e) {
        errorsActions.apiError({
          ...e,
          humanMessage: "배차 시간표를 불러오는데 실패하였습니다.",
        });
      }
    },
    [api.get, dispatch, state.query],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/allocation-matches/${id}`, data);
    },
    [api.put],
  );

  return {
    state,
    bulkUpload,
    changeQuery,
    create,
    deleteAllocationMatches,
    fetchAll,
    setQuery,
    update,
  };
};
