import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  reviews: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 20,
    is5Star: null,
    date: null,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        reviews: action.reviews,
        pageCount: action.pageCount
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useReviewsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const query = {
      page: state.query.page,
      pageSize: state.query.pageSize,
    }

    if (state.query.date !== null) {
      query.date = state.query.date;
    }

    if (state.query.is5Star !== null) {
      query.is5Star = state.query.is5Star;
    }

    const { reviews, totalCount } = await api.get('/reviews', query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      reviews,
      pageCount: Math.floor(totalCount / state.query.pageSize) + (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [
    api.get, 
    dispatch,
    state.query,
  ]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query]
  );

  const update = useCallback(
    (bookId, data) => {
      return api.put(`/reviews/${bookId}`, data);
    },
    [api.put]
  );

  const postTwitter = useCallback(
    (bookId) => {
      return api.post(`/reviews/${bookId}/twitter`);
    },
    [api.post]
  );

  const deleteTwitter = useCallback(
    (bookId) => {
      return api.del(`/reviews/${bookId}/twitter`);
    },
    [api.del]
  );

  return {
    state,
    fetchAll,
    setQuery,
    update,
    postTwitter,
    deleteTwitter,
  };
};
