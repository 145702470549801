import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import _noop from "lodash/noop";

import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Table from "@/components/Table";

import { SelectDeliveryForReturnModal, WaybillModal } from "@/Modals";
import { DELIVERY_TYPE_NORMAL } from "@/Models/Delivery";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import {
  useDeliveriesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const ReturnsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
`;

const StyledForm = styled(Form)`
  flex: 1;
  max-width: 800px;
`;

const ButtonsWrap = styled.div``;

export default () => {
  const { ...actions } = useDeliveriesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const { handleSubmit } = useForm();

  const [text, setText] = useState("");
  const [returnedDeliveries, setReturnedDeliveries] = useState([]);

  const textQueryInputRef = useRef(null);

  useEffect(() => {
    resetFocus();
  }, []);

  const resetFocus = useCallback(() => {
    setText("");
    textQueryInputRef.current.focus();
  }, [textQueryInputRef.current]);

  const handleChange = useCallback((e) => {
    setText(e.target.value);
  }, []);

  const handleSelectDelivery = useCallback(
    (delivery) => {
      if (delivery) {
        const prevDelivery = returnedDeliveries.find(
          (e) => e.prevBookId === delivery.bookId,
        );

        if (prevDelivery) {
          window.alert(`${delivery.bookId}: 목록에 있는 접수건입니다.`);
          resetFocus();
        } else {
          setReturn(delivery);
        }
      }
    },
    [returnedDeliveries],
  );

  const onSubmit = useCallback(async () => {
    if (text) {
      const prevDelivery = returnedDeliveries.find(
        (e) => e.prevBookId === text,
      );

      if (prevDelivery) {
        window.alert(`${text}: 목록에 있는 접수건입니다.`);
        resetFocus();
      } else {
        startLoading();

        try {
          const response = await actions.fetchAllById(text);

          if (!response?.rows?.length) {
            window.alert(`${text} 조회 실패. 다시 입력해주십시오.`);
            resetFocus();
          } else {
            const deliveries = response?.rows.filter(
              (e) => e.type === DELIVERY_TYPE_NORMAL,
            );

            if (!deliveries?.length) {
              window.alert(`${text} 조회 실패. 다시 입력해주십시오.`);
            } else if (deliveries.length === 1) {
              setReturn(response.rows[0]);
            } else {
              openModal(
                <SelectDeliveryForReturnModal
                  key="select-delivery-for-return-modal"
                  deliveries={deliveries}
                  onSelect={handleSelectDelivery}
                />,
              );
            }
          }
        } catch (e) {
          window.alert(`배송 조회에 실패하였습니다.\n에러메시지: ${e.message}`);
          resetFocus();
        }

        finishLoading();
      }
    }
  }, [
    text,
    actions.fetchAllById,
    returnedDeliveries,
    textQueryInputRef.current,
  ]);

  const setReturn = useCallback(
    async (delivery) => {
      if (delivery?.bookId) {
        startLoading();

        const _returnedDeliveries = [...returnedDeliveries];

        try {
          const response = await actions.setReturn(delivery?.bookId);

          _returnedDeliveries.push({
            ...response,
            prevBookId: delivery?.bookId,
            spotName: delivery?.spot?.name || "",
            deliveryRiderName: delivery?.deliveryRider?.name || "",
          });

          setReturnedDeliveries(_returnedDeliveries);
          resetFocus();
        } catch (e) {
          window.alert(
            `회송 반품 처리에 실패하였습니다.\n에러메시지: ${e.message}`,
          );
          resetFocus();
        }

        finishLoading();
      }
    },
    [actions.setReturn, returnedDeliveries],
  );

  const handleClickPrint = useCallback(async () => {
    if (returnedDeliveries.length > 0) {
      try {
        startLoading();

        const waybillDeliveries = await actions.fetchAllSelectedDeliveries(
          returnedDeliveries,
        );

        openModal(
          <WaybillModal key="waybill-modal" deliveries={waybillDeliveries} />,
        );
      } catch (e) {
        window.alert(
          `운송장 출력을 위한 배송목록 조회에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }

      finishLoading();
    } else {
      window.alert(`회송 반품 건을 추가해주세요.`);
    }
  }, [returnedDeliveries]);

  const columns = useMemo(
    () => [
      {
        Header: "회송반품 예약 번호",
        accessor: (row) => row.bookId,
        selectable: false,
        width: 200,
      },
      {
        Header: "접수명점",
        accessor: (row) => row.spotName,
        selectable: false,
        width: 200,
      },
      {
        Header: "배송자",
        accessor: (row) => row.deliveryRiderName,
        selectable: false,
        width: 200,
      },
      {
        Header: "등록일자",
        accessor: (row) =>
          row.receiptDate &&
          formatDate(getDateByTimeZone(row.receiptDate), "yyyy/MM/dd HH:mm"),
        selectable: false,
        width: 200,
      },
    ],
    [],
  );

  return (
    <Container fluid>
      <Title>회송 반품 등록</Title>
      <SearchRow>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={8}>
              <Form.Group>
                <Form.Control
                  name="text"
                  onChange={handleChange}
                  type="text"
                  value={text}
                  ref={textQueryInputRef}
                />
              </Form.Group>
            </StyledCol>
            <StyledCol md={4}>
              <Button type="submit">회송상품 등록</Button>
            </StyledCol>
          </StyledRow>
        </StyledForm>

        <ButtonsWrap>
          <Button type="button" onClick={handleClickPrint}>
            운송장 다운로드
          </Button>
        </ButtonsWrap>
      </SearchRow>
      <ReturnsTable
        responsive
        bordered
        columns={columns}
        data={returnedDeliveries}
        pagination={false}
      />
    </Container>
  );
};
