import React, { useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { SmallButton, Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";

import { useCorpUsersStore, useModalStore, useSpotsStore } from "@/store/hooks";

const Title = styled.h3``;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const SpotsTable = styled(Table)``;

const SelectColumn = ({ id, spotId }) => {
  const { state, ...actions } = useCorpUsersStore();
  const { closeModal } = useModalStore();

  function handleClickEdit() {
    if (id && spotId) {
      actions
        .addSpot(id, spotId)
        .then(() => {
          closeModal();
          window.alert("수거지점을 등록했습니다.");
          actions.fetchAll({
            corpTitle: state.query.corpTitle,
            page: state.page,
            pageSize: state.pageSize,
          });
        })
        .catch((e) => {
          window.alert(e.message);
        });
    }
  }

  return <SmallButton onClick={handleClickEdit}>선택</SmallButton>;
};

export default ({ id, onHide = _noop }) => {
  const { state, ...actions } = useSpotsStore();

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      if (state.query.page === 1) {
        actions.fetchAll();
      } else {
        actions.setQuery({ page: 1 });
      }
    },
    [actions.fetchAll]
  );

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery]
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const columns = useMemo(
    () => [
      {
        Header: "선택",
        accessor: (row) => <SelectColumn id={id} spotId={row.id} />,
        selectable: false,
      },
      {
        Header: "수거지명",
        accessor: "name",
      },
      {
        Header: "수거지코드",
        accessor: "code",
      },
      {
        Header: "주소",
        accessor: "address",
      },
      {
        Header: "연락처",
        accessor: "contact",
      },
      {
        Header: "메모",
        accessor: "memo",
      },
    ],
    []
  );

  return (
    <Modal onHide={onHide} size="lg">
      <Modal.Header>
        <Title>수거지 조회</Title>
      </Modal.Header>
      <Modal.Body>
        <SearchRow>
          <Col>
            <Form onSubmit={handleSubmit}>
              <Row>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="text"
                      onChange={handleChange}
                      placeholder="수거지명"
                    />
                  </Form.Group>
                </Col>
                <Col md={{ span: 2 }}>
                  <Button type="submit">검색하기</Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </SearchRow>

        <SpotsTable
          checkbox={false}
          columns={columns}
          currentPage={state.query.page}
          data={state.spots}
          goToPage={handleGoToPage}
          nextPage={handleNextPage}
          pageCount={state.pageCount}
          pageSize={state.query.pageSize}
          previousPage={handlePreviousPage}
          setPageSize={handleSetPageSize}
        />
      </Modal.Body>
    </Modal>
  );
};
