import React, { useCallback, useEffect, useMemo, useState } from "react";
import _noop from "lodash/noop";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import { useAuthStore, useRidersStore } from "@/store/hooks";

const RidersTable = styled(Table)`
  th:nth-of-type(2) {
    width: 100px;
  }
`;

const RidersTableRow = styled(Row)`
  padding: 10px 0;
`;

const SearchButtonColumn = styled(Col)`
  text-align: right;
`;

const Title = styled.h3``;

const SelectedRidersTitle = styled.h5``;
const SelectedRiders = styled.p``;

export default ({ multiple = false, onHide = _noop, onSelect = _noop }) => {
  const [selectedRiders, setSelectedRiders] = useState([]);
  const { state: authStoreState } = useAuthStore();
  const { state, ...actions } = useRidersStore();
  const { register, handleSubmit, setValue } = useForm();

  const [ready, setReady] = useState(false);

  useEffect(() => {
    const searchedRiderName = localStorage.getItem("searchedRiderName");

    actions.setModalQuery({ 
      text: searchedRiderName || "",
    });

    if (searchedRiderName) {
      setValue('text', searchedRiderName);
    }

    setReady(true);
  }, []);

  useEffect(() => {
    if (ready) {
      fetchAll();
    }
  }, [state.modalQuery.text, ready]);

  function handleHide() {
    actions.clear();
    onHide();
  }

  function handleGoToPage(page) {
    fetchAll({ page, pageSize: state.pageSize });
  }

  function handleNextPage() {
    fetchAll({ page: state.page + 1 });
  }

  function handlePreviousPage() {
    fetchAll({ page: state.page - 1 });
  }

  const fetchAll = useCallback((body) => {
    if (authStoreState.user.authority === 3) {
      actions.fetchAllNoDeletedForRider(body);
    } else {
      actions.fetchAllNoDeleted(body);
    }    
  }, [
    actions.fetchAllNoDeleted,
    actions.fetchAllNoDeletedForRider,
    authStoreState.user,
  ]);

  function handleSelect() {
    if (!selectedRiders.length) {
      window.alert("1명 이상의 라이더를 선택해주세요");
    } else if (!multiple && selectedRiders.length > 1) {
      window.alert("1명의 라이더만 선택해주세요.");
    } else if (!multiple) {
      onSelect(selectedRiders[0]);
    } else {
      onSelect(selectedRiders);
    }
  }

  function handleSelectRider({ selectedRows }) {
    setSelectedRiders(selectedRows);
  }

  function handleSetPageSize(pageSize) {
    fetchAll({ page: 1, pageSize });
  }

  const onSubmit = useCallback(
    ({ text }) => {
      actions.setModalQuery({ text });

      localStorage.setItem("searchedRiderName", text);
    },
    [actions.setModalQuery]
  ); 

  const columns = useMemo(
    () => [
      {
        id: "group2",
        Header: "팀",
        accessor: (row) => row.riderGroup2Name,
        width: 60,
      },
      {
        id: "group1",
        Header: "조",
        accessor: (row) => row.riderGroup1Name,
        width: 60,
      },
      {
        Header: "이름",
        accessor: "name",
        width: 100,
      },
      {
        Header: "지역",
        accessor: (row) => row.allocationGroupsName,
        width: 205,
        ellipsis: false,
      },
    ],
    []
  );

  return (
    <Modal onHide={handleHide}>
      <Modal.Header>
        <Title>라이더 검색하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={6}>
                  <Form.Group>
                    <Form.Control
                      name="text"
                      placeholder="라이더 이름"
                      ref={register}
                    />
                  </Form.Group>
                </Col>
                <SearchButtonColumn md={{ offset: 2, span: 4 }}>
                  <Button type="submit">검색하기</Button>
                </SearchButtonColumn>
              </Row>
            </Form>
          </Col>
        </Row>
        <RidersTableRow>
          <Col>
            <RidersTable
              columns={columns}
              currentPage={state.page}
              data={state.ridersNoDeleted}
              goToPage={handleGoToPage}
              onSelect={handleSelectRider}
              nextPage={handleNextPage}
              pageCount={state.pageCount}
              pageSize={state.pageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </Col>
        </RidersTableRow>
        <Row>
          <Col>
            <SelectedRidersTitle>선택된 라이더 목록</SelectedRidersTitle>
            <SelectedRiders>
              {selectedRiders.map((Rider) => Rider.name).join(", ")}
            </SelectedRiders>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>선택하기</Button>
      </Modal.Footer>
    </Modal>
  );
};
