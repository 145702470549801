import React, { useState, useEffect, useCallback, useRef } from "react";
import * as firebase from "firebase/app";
import GoogleMapReact from "google-map-react";
import { Badge } from "react-bootstrap";
import styled from "@emotion/styled";

import { formatDate, getDateByTimeZone } from "@/lib/date";
import { useRidersStore } from "@/store/hooks";
import { firebase as firebaseConfig } from "../config";

import "firebase/firestore";

const MarkerIcon = styled(Badge)`
  &:before {
    content: "🏍";
    position: absolute;
    top: -17px;
    left: -12px;
    font-size: 2.5em;
  }
`;

const Self = styled.div`
  height: ${({ theme }) => `calc(100vh - ${theme.constants.NAV_HEIGHT})`};
`;

const MAP_CONFIG = {
  CENTER: {
    // 체인 로지스
    lat: 37.531209,
    lng: 127.007467,
  },
  ZOOM: 12,
};

const Marker = ({ riderId, lat, lng, text }) => {
  return (
    <MarkerIcon variant="primary" riderId={riderId} lat={lat} lng={lng}>
      {text}
    </MarkerIcon>
  );
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export default () => {
  const { ridersNoPagination } = useRidersStore().state;
  const { ...actions } = useRidersStore();

  const [riderLocations, setRiderLocations] = useState([]);

  const zonedDate = getDateByTimeZone();
  const dates = formatDate(zonedDate);

  const testLocationRef = firebase
    .firestore()
    .collection("riders-current-locations")
    .doc("data")
    .collection(dates);

  const onSnapshot = useCallback(
    (collection) => {
      const locations = [];
      collection.forEach((doc) => {
        locations.push(doc.data());
      });
      setRiderLocations(locations);
    },
    [setRiderLocations],
  );

  const onSnapshotError = useCallback((err) => {
    alert("Error getting document:", err);
  }, []);

  const unsubRef = useRef(null);

  useEffect(() => {
    actions.fetchAllNoPagination().then(() => {
      unsubRef.current = testLocationRef.onSnapshot(
        onSnapshot,
        onSnapshotError,
      );
    });

    return function() {
      if (unsubRef.current) {
        unsubRef.current();
      }
    };
  }, []);

  return (
    <Self>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
        defaultCenter={MAP_CONFIG.CENTER}
        defaultZoom={MAP_CONFIG.ZOOM}
      >
        {riderLocations.map((riderLocation) => (
          <Marker
            key={riderLocation.riderId}
            lat={riderLocation.lat}
            lng={riderLocation.lng}
            text={
              ridersNoPagination.get(riderLocation.riderId)
                ? ridersNoPagination.get(riderLocation.riderId).name
                : `알 수 없음(id: ${riderLocation.riderId})`
            }
          />
        ))}
      </GoogleMapReact>
    </Self>
  );
};
