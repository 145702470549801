import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";
import { Log } from "@/Models";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  logs: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        logs: action.logs,
        pageCount: action.totalPage,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useLogsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { rows, totalPage } = await api.get("/logs", state.query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      logs: rows.map((log) => new Log(log)),
      totalPage,
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setQuery,
  };
};
