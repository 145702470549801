export class Spot {
  id = null;

  address = "";
  addressDetail = "";
  addressRoad = "";
  code = "";
  codeFromCorp = null;
  contact = "";
  contact2 = "";
  deliveredSmsActive = false;
  deliveryStartSmsActive = false;
  dongId = null;
  estimatedTimeHour = 8;
  etc = "{}";
  excelScheme = null;
  fee = null;
  gatheringReference = null;
  limitedDeliveryHours = null;
  memo = null;
  name = "";
  point = "";
  postalCode = null;
  returnActive = false;
  returnEnabled = null;
  smsActive = false;
  turn = null;
  spotDeliveryRider = null;
  spotPickupRiders = null;

  constructor(spot) {
    this.id = spot.id;
    this.address = spot.address;
    this.addressDetail = spot.addressDetail;
    this.addressRoad = spot.addressRoad;
    this.code = spot.code;
    this.codeFromCorp = spot.codeFromCorp;
    this.contact = spot.contact;
    this.contact2 = spot.contact2;
    this.deliveredSmsActive = spot.deliveredSmsActive;
    this.deliveryStartSmsActive = spot.deliveryStartSmsActive;
    this.dongId = spot.dongId;
    this.estimatedTimeHour = spot.estimatedTimeHour;
    this.etc = spot.etc || "{}";
    this.excelScheme = spot.excelScheme;
    this.fee = spot.fee;
    this.gatheringReference = spot.gatheringReference;
    this.limitedDeliveryHours = spot.limitedDeliveryHours;
    this.memo = spot.memo;
    this.name = spot.name;
    this.point = spot.point;
    this.postalCode = spot.postalCode;
    this.returnActive = spot.returnActive;
    this.returnEnabled = spot.returnEnabled;
    this.smsActive = spot.smsActive;
    this.turn = spot.turn;

    this.spotDeliveryRider = spot.spotDeliveryRider;
    this.spotPickupRiders = spot.spotPickupRiders;
  }

  get spotEtcObj() {
    return this.etc && JSON.parse(this.etc);
  }

  get autoPickupAllocationOnWeekends() {
    return this.spotEtcObj && this.spotEtcObj.autoPickupAllocationOnWeekends
      ? this.spotEtcObj.autoPickupAllocationOnWeekends
      : false;
  }

  get duplicatedByOrderIdFromCorp() {
    return this.spotEtcObj && this.spotEtcObj.duplicatedByOrderIdFromCorp
      ? this.spotEtcObj.duplicatedByOrderIdFromCorp
      : false;
  }

  get searchableByOrderIdFromCorp() {
    return this.spotEtcObj && this.spotEtcObj.searchableByOrderIdFromCorp
      ? this.spotEtcObj.searchableByOrderIdFromCorp
      : false;
  }

  get autoDeliveryAllocation() {
    return this.spotEtcObj && this.spotEtcObj.autoDeliveryAllocation;
  }

  get open() {
    return this.spotEtcObj && this.spotEtcObj.open;
  }

  get openUpdatedAt() {
    return this.spotEtcObj && this.spotEtcObj.openUpdatedAt;
  }

  get postponedAlimtalkAvailable() {
    return this.spotEtcObj?.postponedAlimtalkAvailable;
  }

  get kurlyDrink() {
    return this.spotEtcObj?.kurlyDrink;
  }

  get luxuryAvailable() {
    return !!this.spotEtcObj?.luxuryAvailable;
  }
}
