import React, { useCallback, useEffect, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import DatePicker from "@/components/DatePicker";
import Modal from "@/components/Modal";
import daumAddress from "@/lib/daum-address";
import { DELIVERY_TYPE_NORMAL, DELIVERY_TYPE_PICKUP } from "@/Models/Delivery";

import { useCorpUsersStore, useModalStore } from "@/store/hooks";

const Title = styled.h3``;

const CorpUser = styled.div``;
const CorpUserTitle = styled.h5``;

const Row = styled(OrigRow)`
  padding: 0;
  align-items: center;
`;

const ButtonFull = styled(Button)`
  width: 100%;
`;

const DatePickerCustom = styled(DatePicker)`
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }
`;

export default ({ id, onHide = _noop }) => {
  const [corpUser, setCorpUser] = useState({});
  const { ...actions } = useCorpUsersStore();

  const { closeModal } = useModalStore();
  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    actions.fetchByIdOnTheFly(id).then((corpUserResult) => {
      const { corpTitle } = corpUserResult;

      setCorpUser({
        corpTitle,
      });

      reset({
        type: DELIVERY_TYPE_NORMAL,
        productPrice: 0,
        prepaid: "1",
        receiptType: "1",
      });
    });
  }, []);

  const [address, setAddress] = useState("");
  const handleClickSearchButton = useCallback(async () => {
    const result = await daumAddress();

    setAddress(result.jibunAddress);
  }, [daumAddress]);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleChangeDate = (date) => {
    setSelectedDate(date);
  };

  const [selectedTime, setSelectedTime] = useState("");
  const handleChangeTime = (time) => {
    setSelectedTime(time);
  };

  const [selectableDate, setSelectableDate] = useState(false);
  const handleChnageReceiptType = (e) => {
    if (Number(e.target.value) > 0) {
      setSelectableDate(false);
      setSelectedDate(new Date());
      setSelectedTime("");
    } else {
      setSelectableDate(true);
    }
  };

  const onSubmit = async (data) => {
    console.log(data);
    closeModal();
  };

  return (
    <Modal onHide={onHide} size="lg">
      <Modal.Header>
        <Title>퀵 접수하기</Title>
      </Modal.Header>
      <Modal.Body>
        <CorpUser>
          <CorpUserTitle>기업회원 정보</CorpUserTitle>
          접수점:
          <br />
          기업회원: {corpUser.corpTitle}
        </CorpUser>
        <hr />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              배송 유형
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                id="type-normal"
                inline
                label="일반배송"
                name="type"
                type="radio"
                value={DELIVERY_TYPE_NORMAL}
                ref={register}
              />
              <Form.Check
                id="type-pickup"
                inline
                label="픽업배송(반송)"
                name="type"
                type="radio"
                value={DELIVERY_TYPE_PICKUP}
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              고객명
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                minLength={2}
                name="receiverName"
                ref={register({ required: true })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              고객 휴대폰 번호
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                name="receiverMobile"
                ref={register({ required: true })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              고객 주소
            </Form.Label>
            <Col sm={7}>
              <Form.Control
                disabled
                name="receiverAddress"
                value={address}
                ref={register({ required: true })}
              />
            </Col>
            <Col sm={2}>
              <ButtonFull onClick={handleClickSearchButton} type="button">
                검색
              </ButtonFull>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              고객 상세주소
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                name="receiverAddressDetail"
                ref={register({ required: true })}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              물품 분류
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                id="product-category-cloth"
                inline
                label="의류"
                name="productCategory"
                type="radio"
                value="의류"
                ref={register}
              />
              <Form.Check
                id="product-category-paper"
                inline
                label="서신서류"
                name="productCategory"
                type="radio"
                value="서신서류"
                ref={register}
              />
              <Form.Check
                id="product-category-appliance"
                inline
                label="가전제품"
                name="productCategory"
                type="radio"
                value="가전제품"
                ref={register}
              />
              <Form.Check
                id="product-category-fruit"
                inline
                label="과일"
                name="productCategory"
                type="radio"
                value="과일"
                ref={register}
              />
              <Form.Check
                id="product-category-grain"
                inline
                label="곡물"
                name="productCategory"
                type="radio"
                value="곡물"
                ref={register}
              />
              <Form.Check
                id="product-category-medicine"
                inline
                label="한약"
                name="productCategory"
                type="radio"
                value="한약"
                ref={register}
              />
              <Form.Check
                id="product-category-food"
                inline
                label="식품"
                name="productCategory"
                type="radio"
                value="식품"
                ref={register}
              />
              <Form.Check
                id="product-category-book"
                inline
                label="잡화서적"
                name="productCategory"
                type="radio"
                value="잡화서적"
                ref={register}
              />
              <Form.Check
                id="product-category-etc"
                inline
                label="기타"
                name="productCategory"
                type="radio"
                value="기타"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              물품명
            </Form.Label>
            <Col sm={9}>
              <Form.Control name="productName" ref={register} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              물품가격
            </Form.Label>
            <Col sm={9}>
              <Form.Control type="number" name="productPrice" ref={register} />
              <b>
                *물품가격은 최대 50만원까지 보험적용됩니다. (50만원 이상 물품에
                대해서는 별도문의 부탁드립니다.)
              </b>
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              지불 방식
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                id="prepaid-pre"
                inline
                label="선불"
                name="prepaid"
                type="radio"
                value="1"
                ref={register}
              />
              <Form.Check
                id="prepaid-after"
                inline
                label="후불"
                name="prepaid"
                type="radio"
                value="0"
                ref={register}
              />
            </Col>
          </Form.Group>
          <hr />
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              고객요청사항
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as="textarea"
                rows="3"
                name="memoFromCustomer"
                ref={register}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              접수일자지정
            </Form.Label>
            <Col sm={9}>
              <Form.Check
                id="receipt-type-now"
                inline
                label="바로지정"
                name="receiptType"
                type="radio"
                value="1"
                ref={register}
                onChange={handleChnageReceiptType}
              />
              <Form.Check
                id="receipt-type-date"
                inline
                label="날짜지정"
                name="receiptType"
                type="radio"
                value="0"
                ref={register}
                onChange={handleChnageReceiptType}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 3, offset: 3 }}>
              <DatePickerCustom
                dateFormat="yyyy-MM-dd"
                name="date"
                minDate={new Date()}
                onChange={handleChangeDate}
                selected={selectedDate}
                disabled={!selectableDate}
              />
            </Col>
            <Col sm={3}>
              <DatePickerCustom
                dateFormat="HH:mm"
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={30}
                timeCaption="Time"
                onChange={handleChangeTime}
                selected={selectedTime}
                disabled={!selectableDate}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 9, offset: 3 }}>
              <Button type="submit">확인</Button>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
