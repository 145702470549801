import React, { memo, useCallback, useEffect, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import Table from "@/components/Table";
import { Button } from "@/components/Buttons";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import { useFailedScanStore, useLoadingStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const DatePickerCol = styled(StyledCol)`
  display: flex;
  flex-direction: row;

  input {
    width: calc(50% - 15px);
  }
`;

const Wave = styled.span`
  margin: 0 10px;
`;

const FailedScanTable = styled(Table)``;

export default memo(() => {
  const { state, ...actions } = useFailedScanStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    reset({
      ...state.query,
    });
  }, []);

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page,
    state.query.pageSize,
    state.query.dateFrom,
    state.query.dateTo,
    state.query.scanId,
  ]);

  const fetchAll = useCallback(() => {
    try {
      startLoading();
      actions.fetchAll();
    } catch (e) {
      window.alert(`목록 조회에 실패했습니다: ${e.message}`);
    }

    finishLoading();
  }, [actions.fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(
    ({ scanId, dateFrom, dateTo }) => {
      actions.setQuery({ scanId, dateFrom, dateTo });
    },
    [actions.setQuery],
  );

  const columns = useMemo(() => [
    {
      Header: "ID",
      accessor: "id",
      width: 50,
    },
    {
      Header: "스캔 텍스트",
      accessor: "scanId",
      width: 250,
    },
    {
      Header: "스캔 일시",
      accessor: (row) =>
        `${formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm")}`,
      width: 200,
    },
    {
      Header: "라이더",
      accessor: (row) => `${row.rider?.name} (${row.rider?.mobile1})`,
      width: 250,
    },
  ]);

  return (
    <Container fluid>
      <Title>바코드 스캔 실패 조회</Title>

      <SearchRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <DatePickerCol md={6}>
              <Form.Control name="dateFrom" type="date" ref={register} />
              <Wave>~</Wave>
              <Form.Control name="dateTo" type="date" ref={register} />
            </DatePickerCol>
            <StyledCol md={2}>
              <Form.Control name="scanId" placeholder="텍스트" ref={register} />
            </StyledCol>
            <StyledCol md={4}>
              <Button type="submit">조회</Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <FailedScanTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.failedScanIds}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        previousPage={handlePreviousPage}
        pageSize={state.query.pageSize}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
});
