import React, { memo, useCallback, useMemo, useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import { useSigungusStore } from "@/store/hooks";

const SigungusTable = styled(Table)``;

const Title = styled.h3``;

const SelectColumn = memo(({ sigungu, onSelect }) => {
  function handleClick() {
    onSelect(sigungu)
  }

  return <SmallButton onClick={handleClick}>선택</SmallButton>;
});

export default ({ onSelect }) => {
  const [name, setName] = useState("");
  const { state, ...actions } = useSigungusStore();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page, 
    state.query.pageSize, 
    name,
  ]);

  const fetchAll = useCallback(() => {
    actions.fetchAll({ name });
  }, [actions.fetchAll, name]);

  const handleGoToPage = useCallback((page) => {
    actions.setQuery({ page })
  }, [actions.setQuery]);

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 })
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 })
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback((pageSize) => {
    actions.setQuery({ pageSize })
  }, [actions.setQuery]);

  const onSubmit = useCallback(({ text }) => {
    setName(text);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "시도",
        accessor: "sido.name",
      },
      {
        Header: "시군구",
        accessor: "name",
      },
      {
        Header: "선택",
        accessor: (row) => <SelectColumn sigungu={row} onSelect={onSelect} />,
        selectable: false,
      }
    ],
    []
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>시군구 검색하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Col md={6}>
              <Form.Control
                name="text"
                placeholder="시군구명"
                ref={register}
              />
            </Col>
            <Col md={6}>
              <Button type="submit">검색하기</Button>
            </Col>
          </Form.Group>
        </Form>

        <SigungusTable
          responsive
          bordered
          checkbox={false}
          columns={columns}
          currentPage={state.query.page}
          data={state.sigungus}
          pageCount={state.pageCount}
          pageSize={state.query.pageSize}
          goToPage={handleGoToPage}
          nextPage={handleNextPage}
          previousPage={handlePreviousPage}
          setPageSize={handleSetPageSize}
        />

      </Modal.Body>
    </Modal>
  );
};
