import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import {
  CreateRiderGroup1Modal, 
  CreateRiderGroup2Modal,
  EditRiderGroup1Modal,
  EditRiderGroup2Modal,
} from "@/Modals";

import { 
  useRiderGroupsStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const RiderGroups1Table = styled(Table)``;
const RiderGroups2Table = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SubTitle = styled.h3`
  font-size: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
`

const ButtonWrap = styled.div`
  margin-bottom: 10px;
`

const UpdateRiderGroup1Column = ({ riderGroup1, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditRiderGroup1Modal
        key={`edit-rider-group1-${riderGroup1.id}-modal`}
        riderGroup1={riderGroup1}
        onUpdate={onUpdate}
      />
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const DeleteRiderGroup1Column = ({ id, onDelete = _noop }) => {
  const { ...actions } = useRiderGroupsStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteRiderGroup1(id);
        onDelete();
      } catch (e) {
        window.alert(`라이더 조 삭제에 실패했습니다: ${e.message}`);
      };      
    }
  }, [id, actions.deleteRiderGroup1, onDelete]);

  return <SmallButton onClick={handleClickDelete} variant="secondary">삭제</SmallButton>;
};

const UpdateRiderGroup2Column = ({ riderGroup2, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditRiderGroup2Modal
        key={`edit-rider-group2-${riderGroup2.id}-modal`}
        riderGroup2={riderGroup2}
        onUpdate={onUpdate}
      />
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const DeleteRiderGroup2Column = ({ id, onDelete = _noop }) => {
  const { ...actions } = useRiderGroupsStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteRiderGroup2(id);
        onDelete();
      } catch (e) {
        window.alert(`라이더 팀 삭제에 실패했습니다: ${e.message}`);
      };      
    }
  }, [id, actions.deleteRiderGroup2, onDelete]);

  return <SmallButton onClick={handleClickDelete} variant="secondary">삭제</SmallButton>;
};

export default () => {
  const { state, ...actions } = useRiderGroupsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  useEffect(() => {
    fetchRiderGroup2();
  }, []);

  useEffect(() => {
    if (state.selectedRiderGroup2) {
      fetchRiderGroup1();
    }
  }, [state.selectedRiderGroup2]);

  /**
   * 조 목록 조회
   */
  const fetchRiderGroup1 = useCallback(async () => {
    if (state.selectedRiderGroup2) {
      try {
        startLoading();
  
        await actions.fetchRiderGroup1NoPagination(state.selectedRiderGroup2.id);
      } catch (e) {
        window.alert(
          `라이더 조 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`
        );
      }
  
      finishLoading();
    }
  }, [actions.fetchRiderGroup1NoPagination, state.selectedRiderGroup2]);

  /**
   * 팀 목록 조회
   */
  const fetchRiderGroup2 = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchRiderGroup2();
    } catch (e) {
      window.alert(
        `라이더 팀 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`
      );
    }

    finishLoading();
  }, [actions.fetchRiderGroup2]);

  /**
   * 조 생성 모달
   */
  const handleClickCreateRiderGroup1 = useCallback(() => {
    openModal(
      <CreateRiderGroup1Modal key="create-rider-group1-modal" onCreate={fetchRiderGroup1} selectedRiderGroup2={state.selectedRiderGroup2} />
    );
  }, [openModal, state.selectedRiderGroup2]);

  /**
   * 팀 생성 모달
   */
  const handleClickCreateRiderGroup2 = useCallback(() => {
    openModal(
      <CreateRiderGroup2Modal key="create-rider-group2-modal" onCreate={fetchRiderGroup2} />
    );
  }, [openModal]);

  const handleSelectRiderGroup2 = useCallback((riderGroup2) => {
    actions.selectRiderGroup2(riderGroup2)
  }, [actions.selectRiderGroup2]);

  const riderGroup1scolumns = useMemo(
    () => [
      {
        Header: "id",
        accessor: (row) => row.id,
        selectable: false,
        width: 60,
      },
      {
        Header: "조명",
        accessor: (row) => row.name,
        selectable: false,
        width: 300,
      },
      {
        Header: "수정",
        accessor: (row) => <UpdateRiderGroup1Column riderGroup1={row} onUpdate={fetchRiderGroup1} />,
        selectable: false,
        width: 80,
      },
      {
        Header: "삭제",
        accessor: (row) => <DeleteRiderGroup1Column id={row.id} onDelete={fetchRiderGroup1} />,
        selectable: false,
        width: 80,
      },
    ],
    [state.selectedRiderGroup2]
  );

  const riderGroup2scolumns = useMemo(
    () => [
      {
        Header: "id",
        accessor: (row) => row.id,
        selectable: false,
        width: 60,
      },
      {
        Header: "팀명",
        accessor: (row) => row.name,
        selectable: false,
        width: 300,
      },
      {
        Header: "선택",
        accessor: (row) => <SmallButton onClick={() => handleSelectRiderGroup2(row)}>선택</SmallButton>,
        selectable: false,
        width: 80,
      },
      {
        Header: "수정",
        accessor: (row) => <UpdateRiderGroup2Column riderGroup2={row} onUpdate={fetchRiderGroup2} />,
        selectable: false,
        width: 80,
      },
      {
        Header: "삭제",
        accessor: (row) => <DeleteRiderGroup2Column id={row.id} onDelete={fetchRiderGroup2} />,
        selectable: false,
        width: 80,
      },
    ],
    []
  );

  return (
    <Container fluid>
      <Title>라이더 팀/조 관리</Title>
      <Row>
        <Col>
          <SubTitle>팀 목록</SubTitle>
          <ButtonWrap>
            <Button onClick={handleClickCreateRiderGroup2}>팀 등록</Button>
          </ButtonWrap>
          <RiderGroups2Table
            pagination={false}
            checkbox={false}
            responsive
            bordered
            columns={riderGroup2scolumns}
            data={state.riderGroup2s}
          />
        </Col>
        {state.selectedRiderGroup2 && (
          <Col>
            <SubTitle>{state.selectedRiderGroup2.name}팀의 조 목록</SubTitle>
            <ButtonWrap>
              <Button onClick={handleClickCreateRiderGroup1}>조 등록</Button>
            </ButtonWrap>
            <RiderGroups1Table
              pagination={false}
              checkbox={false}
              responsive
              bordered
              columns={riderGroup1scolumns}
              data={state.riderGroup1s}
            />
          </Col>
        )}
        
      </Row>
      
    </Container>
  );
};
