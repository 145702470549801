import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Statistics } from "@/Models";
import { useApiStore, useErrorsStore } from "@/store/hooks";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import dfSubDays from "date-fns/subDays";

export const initDate = formatDate(getDateByTimeZone());
const initType = "REGION";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const zonedDate = dfSubDays(getDateByTimeZone(), 1);

const INITIAL_STATE = {
  statistics: [],
  query: {
    date: formatDate(zonedDate),
    type: initType,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        statistics: action.statistics,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useStatisticsStore = () => {
  const api = useApiStore();
  const { actions: errorsActions } = useErrorsStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    try {
      const statistics = await api.get("/statistics", {
        date: state.query.date,
        type: state.query.type,
      });

      const formattedStatistics = [];
      const keys = Object.keys(statistics);
      const values = Object.values(statistics);

      values.forEach((value, index) => {
        formattedStatistics.push({
          type: keys[index],
          pickuped: value.pickuped,
          deliveryStarted: value.deliveryStarted,
          deliveryDone: value.deliveryDone,
        });
      });

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        statistics: formattedStatistics.map((s) => new Statistics(s)),
      });
    } catch (e) {
      errorsActions.apiError({
        ...e,
        humanMessage: "일별 통계 목록을 불러오는데 실패하였습니다.",
      });
    }
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setQuery,
  };
};
