import React, { useState, useEffect } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import UserForm from "@/forms/UserForm";
import { useUsersStore, useModalStore } from "@/store/hooks";

const Title = styled.h3``;

export default ({ id, onUpdate = _noop, withRiderManager } = {}) => {
  const [initUser, setInitUser] = useState(null);
  const { ...actions } = useUsersStore();
  const { closeModal } = useModalStore();

  useEffect(() => {
    actions.fetchByIdOnTheFly(id).then((userResult) => {
      setInitUser(userResult);
    });
  }, []);

  const onSubmit = async ({
    authority,
    authorizedForAllTypes,
    locked,
    password,
    realname,
    riderId,
    type,
  }) => {
    actions
      .update(id, {
        authority,
        authorizedForAllTypes,
        locked,
        password,
        realname,
        riderId,
        type,
      })
      .then(() => {
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`관리자 수정에 실패했습니다.\n${e.message}`);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>관리자 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm
          onSubmit={onSubmit}
          initUser={initUser}
          withRiderManager={withRiderManager}
        />
      </Modal.Body>
    </Modal>
  );
};
