import React, { useCallback, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useDeliveriesStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const ResultWrap = styled.div`
  min-height: 200px;
`;

const ErrorMessage = styled.div``;

const Dl = styled.dl`
  display: flex;
  margin-bottom: 5px;
`;

const Dt = styled.dt`
  width: 150px;
`;

const Dd = styled.dd`
  margin-bottom: 0;
  flex: 1;
`;

export default () => {
  const { ...actions } = useDeliveriesStore();
  const { register, handleSubmit } = useForm();

  const [resultSuccess, setResultSuccess] = useState(null);
  const [resultFail, setResultFail] = useState(null);

  const onSubmit = useCallback(
    async ({ agencyOrderId, bookId }) => {
      if (agencyOrderId || bookId) {
        try {
          const response = await actions.fetchAgencyDelivery({
            agencyOrderId,
            bookId
          });

          if (response?.success) {
            setResultSuccess(response.originData?.body);
            setResultFail(response?.error);
          } else {
            setResultSuccess(null);
            setResultFail(response?.error);
          }
        } catch (e) {
          window.alert(
            `배달 접수건 조회에 실패했습니다. 실패 사유: ${e.message}`
          );
        }
      } else {
        window.alert("접수번호를 입력해주세요.");
      }
    },
    [actions.fetchAgencyDelivery]
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>배달 접수건 검색</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <StyledCol md={5}>
              <Form.Control
                name="agencyOrderId"
                placeholder="배달대행 접수번호"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={5}>
              <Form.Control
                name="bookId"
                placeholder="두발히어로 접수번호"
                ref={register}
              />
            </StyledCol>
            <StyledCol md={2}>
              <Button type="submit">검색</Button>
            </StyledCol>
          </Form.Group>
        </Form>
        <ResultWrap>
          {resultFail && (
            <ErrorMessage>
              {resultFail?.message || "검색 결과가 없습니다."}
            </ErrorMessage>
          )}
          {resultSuccess && (
            <>
              <Dl>
                <Dt>CHARGE_TYPE</Dt>
                <Dd>{resultSuccess?.CHARGE_TYPE}</Dd>
              </Dl>
              <Dl>
                <Dt>DVRY_AMT</Dt>
                <Dd>{resultSuccess?.DVRY_AMT}</Dd>
              </Dl>
              <Dl>
                <Dt>EXT_ORD_NO</Dt>
                <Dd>{resultSuccess?.EXT_ORD_NO}</Dd>
              </Dl>
              <Dl>
                <Dt>MOD_DATE</Dt>
                <Dd>{resultSuccess?.MOD_DATE}</Dd>
              </Dl>
              <Dl>
                <Dt>ORD_AMT</Dt>
                <Dd>{resultSuccess?.ORD_AMT}</Dd>
              </Dl>
              <Dl>
                <Dt>ORD_CU_TEL</Dt>
                <Dd>{resultSuccess?.ORD_CU_TEL}</Dd>
              </Dl>
              <Dl>
                <Dt>ORD_NO</Dt>
                <Dd>{resultSuccess?.ORD_NO}</Dd>
              </Dl>
              <Dl>
                <Dt>ORD_STATUS</Dt>
                <Dd>{resultSuccess?.ORD_STATUS}</Dd>
              </Dl>
              <Dl>
                <Dt>PAY_TYPE</Dt>
                <Dd>{resultSuccess?.PAY_TYPE}</Dd>
              </Dl>
            </>
          )}
        </ResultWrap>
      </Modal.Body>
    </Modal>
  );
};
