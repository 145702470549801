import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";
import { CORP_USER_NAMES } from "@/constants";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_BY_POSTAL_CODE: "FETCH_BY_POSTAL_CODE",
  SET_QUERY: "SET_QUERY",
  SET_CORP_USER_NAME: "SET_CORP_USER_NAME",
};

const INITIAL_STATE = {
  agPostalCodesByCorp: [],
  agPostalCodeByCorp: {},
  corpUserName: CORP_USER_NAMES.OLIVE,
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 20,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        agPostalCodesByCorp: action.agPostalCodesByCorp,
        pageCount: action.pageCount,
      };
    case CONSTANTS.FETCH_BY_POSTAL_CODE:
      return {
        ...state,
        agPostalCodeByCorp: action.agPostalCodeByCorp,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    case CONSTANTS.SET_CORP_USER_NAME:
      return {
        ...state,
        corpUserName: action.corpUserName,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAgPostalCodesByCorpStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const bulkUpload = useCallback(
    async ({ data }) => {
      await api.post(`/corp-users/${state.corpUserName}/postal-codes`, {
        postalCodes: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            const value = `${h[k]}`.trim();
            if (value) {
              switch (k.replace(/ /g, "")) {
                case "지역그룹명":
                  result.allocationGroup = value;
                  break;
                case "우편번호":
                  result.postalCode = value;
                  break;
                case "시명칭":
                  result.sido = value;
                  break;
                case "구명칭":
                  result.sigungu = value;
                  break;
                case "동명칭":
                  result.dong = value;
                  break;
                default:
                  break;
              }
            }
          });
          return result;
        }),
      });
      return true;
    },
    [api.post, state.corpUserName],
  );

  const fetchAll = useCallback(async () => {
    const { postalCodes } = await api.get(
      `/corp-users/${state.corpUserName}/postal-codes`,
    );
    const totalCount = postalCodes?.length || 0;

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      agPostalCodesByCorp: postalCodes || [],
      pageCount: 1,
      // Math.floor(totalCount / state.query.pageSize) +
      // (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query, state.corpUserName]);

  const fetchByPostalCode = useCallback(
    async (postalCode) => {
      const postalColdeObj = await api.get(
        `/corp-users/${state.corpUserName}/postal-codes/${postalCode}`,
      );

      dispatch({
        type: CONSTANTS.FETCH_BY_POSTAL_CODE,
        agPostalCodeByCorp: postalColdeObj?.row || {},
      });

      return postalColdeObj;
    },
    [api.get, state.corpUserName],
  );

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query],
  );

  const setCorpUserName = useCallback(
    (corpUserName) => {
      dispatch({
        type: CONSTANTS.SET_CORP_USER_NAME,
        corpUserName,
      });
    },
    [dispatch],
  );

  return {
    state,
    bulkUpload,
    fetchAll,
    fetchByPostalCode,
    setQuery,
    setCorpUserName,
  };
};
