import React, { useCallback, useState } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

const List = styled.div``;

const Item = styled.div`
  padding: 10px;
  cursor: pointer;
  border-top: 1px solid #ddd;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  ${({ active }) => active && "background-color: #ddd"}
`;

const SelectItem = ({ active, delivery, onSelect }) => {
  const handleSelect = useCallback(() => {
    onSelect(delivery);
  }, [delivery]);

  return (
    <Item onClick={handleSelect} active={active}>
      {delivery?.spot?.name}
      <br /> {delivery?.bookId} / {delivery.orderIdFromCorp}
    </Item>
  );
};

export default ({ deliveries, onSelect = _noop }) => {
  const { closeModal } = useModalStore();

  const [selectedDelivery, setSelectedDelivery] = useState(null);

  const handleSelect = useCallback(() => {
    if (!selectedDelivery) {
      window.alert("배송건을 선택해주세요");
    } else {
      onSelect(selectedDelivery);
      closeModal();
    }
  }, [selectedDelivery]);

  const handleSelectDelivery = (_delvery) => {
    setSelectedDelivery(_delvery);
  };

  const handleClickClose = () => {
    closeModal();
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>반품등록 접수건 선택</Title>
      </Modal.Header>
      <Modal.Body>
        <List>
          {deliveries.map((delivery) => {
            return (
              <SelectItem
                key={delivery.bookId}
                active={selectedDelivery?.bookId === delivery.bookId}
                delivery={delivery}
                onSelect={handleSelectDelivery}
              />
            );
          })}
        </List>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClickClose}>취소</Button>
        <Button onClick={handleSelect}>확인</Button>
      </Modal.Footer>
    </Modal>
  );
};
