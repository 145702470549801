import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _cloneDeep from "lodash/cloneDeep";
import _find from "lodash/find";
import _remove from "lodash/remove";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  CLEAR: "CLEAR",
  CREATE_ALLOCATION_GROUP: "CREATE_ALLOCATION_GROUP",
  DELETE: "DELETE",
  DELETE_DONG: "DELETE_DONG",
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  pageCount: 1,
  allocationGroups: [],
  query: {
    page: 1,
    pageSize: 10,
    name: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CLEAR:
      return INITIAL_STATE;
    case CONSTANTS.CREATE_ALLOCATION_GROUP:
      return {
        ...state,
        allocationGroups: [action.allocationGroup, ...state.allocationGroups],
      };
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        allocationGroups: action.allocationGroups,
        query: {
          ...state.query,
          page: action.page,
        },
        pageCount: action.pageCount,
      };
    case CONSTANTS.DELETE:
      return {
        ...state,
        allocationGroups: _remove(
          state.allocationGroups,
          (ag) => ag.id !== action.allocationGroup.id,
        ),
      };
    case CONSTANTS.DELETE_DONG: {
      const allocationGroups = _cloneDeep(state.allocationGroups);
      const ag = _find(allocationGroups, (ag) => ag.id === action.id);
      _remove(ag._dongs, (d) => d.id === action.dongId);
      return {
        ...state,
        allocationGroups,
      };
    }
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAllocationGroupsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const addDong = useCallback(
    async (id, dongIds = []) => {
      return await api.post(`/allocation-groups/${id}/dongs-v2`, {
        dongIds,
      });
    },
    [api.post],
  );

  const bulkUpload = useCallback(
    async (data) => {
      await api.post("/allocation-groups/bulk", {
        allocationGroups: data.map((h) => {
          const result = {};
          Object.keys(h).forEach((k) => {
            switch (k) {
              case "배차그룹":
              case "지역그룹명":
                result.name = h[k];
                break;
              case "할당량":
                result.limit = h[k];
                break;
              case "시명칭":
                result.sido = h[k];
                break;
              case "구명칭":
                result.sigungu = h[k];
                break;
              case "동명칭":
                result.dong = h[k];
                break;
              default:
                break;
            }
          });
          return result;
        }),
      });
      return true;
    },
    [api.post],
  );

  const clear = useCallback(() => {
    dispatch({
      type: CONSTANTS.CLEAR,
    });
  }, []);

  const createAllocationGroup = useCallback(
    async (data) => {
      return await api.post("/allocation-groups", data);
    },
    [api.post],
  );

  const fetchAll = useCallback(
    async ({
      name = state.query.name,
      page = state.query.page,
      pageSize = state.query.pageSize,
    } = {}) => {
      const { allocationGroups, pageCount } = await api.get(
        "/allocation-groups",
        {
          v: 2,
          name,
          page,
          pageSize,
        },
      );

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        allocationGroups,
        page,
        pageCount,
      });
    },
    [api.get, dispatch, state.query],
  );

  const deleteGroup = useCallback(
    async (id) => {
      await api.del(`/allocation-groups/${id}`);
      dispatch({
        type: CONSTANTS.DELETE,
        allocationGroup: { id },
      });
    },
    [api.del, dispatch],
  );

  const deleteDong = useCallback(
    async (id, dongId) => {
      await api.del(`/allocation-groups/${id}/dongs-v2/${dongId}`);
      dispatch({
        type: CONSTANTS.DELETE_DONG,
        id,
        dongId,
      });
    },
    [api.del, dispatch],
  );

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/allocation-groups/${id}`, data);
    },
    [api.put],
  );

  return {
    state,
    addDong,
    bulkUpload,
    clear,
    createAllocationGroup,
    fetchAll,
    deleteGroup,
    deleteDong,
    setQuery,
    update,
  };
};
