import React, { 
  createContext, 
  useCallback, 
  useContext, 
  useReducer 
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  UPDATE: "UPDATE",
}

const INITIAL_STATE = {
  alimtalkNoticeMessage: {
    message: "",
  }
}

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        alimtalkNoticeMessage: action.alimtalkNoticeMessage,
      }
    case CONSTANTS.UPDATE:
      return {
        ...state,
      };
    default:
      return INITIAL_STATE;
  }
}

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useAlimtalkNoticeStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const alimtalkNoticeMessage = await api.get("/etc/alimtalk-notice-message");
    
    dispatch({
      type: CONSTANTS.FETCH_ALL,
      alimtalkNoticeMessage,
    });
  }, [api.get]);

  const update = useCallback(({ message }) => {
    return api.put("/etc/alimtalk-notice-message", {
      message,
    });
  }, [api.put]);

  return {
    state,
    fetchAll,
    update,
  }
}  