import React from "react";
import styled from "@emotion/styled";

const Container = styled.div`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 32px;
`;

const Desc = styled.p`
  text-align: center;
  font-size: 18px;
`;

export default () => {
  return (
    <Container>
      <Title>페이지를 찾을 수 없습니다.</Title>
      <Desc>URL을 다시 확인해주세요.</Desc>
    </Container>
  );
};
