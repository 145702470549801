import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

import { BaseAddressMemo } from "@/Models";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  baseAddressMemos: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
    address: "",
    addressRoad: "",
    deleted: false,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        baseAddressMemos: action.baseAddressMemos,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useBaseAddressesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    let query = {
      page: state.query.page,
      pageSize: state.query.pageSize,
      address: state.query.address,
      addressRoad: state.query.addressRoad,
    };

    if (!state.query.deleted) {
      query.deleted = false;
    }

    const { items, totalCount } = await api.get("/base-addresses/memos", query);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      baseAddressMemos: items.map((b) => new BaseAddressMemo(b)),
      pageCount:
        Math.floor(totalCount / state.query.pageSize) +
        (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  const create = useCallback(
    (data) => {
      return api.post(`/base-addresses/memos`, data);
    },
    [api.post],
  );

  const deleteMemo = useCallback(
    (id) => {
      return api.del(`/base-addresses/memos/${id}`);
    },
    [api.del],
  );

  const restoreMemo = useCallback(
    (id) => {
      return api.put(`/base-addresses/memos/${id}/restore`);
    },
    [api.del],
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/base-addresses/memos/${id}`, data);
    },
    [api.put],
  );

  return {
    state,
    fetchAll,
    setQuery,
    restoreMemo,
    deleteMemo,
    create,
    update,
  };
};
