import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { useSpotsStore } from "@/store/hooks";

const SpotsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;
        
const DeleteColumn = ({ id, onDelete = _noop }) => {
  const { ...actions } = useSpotsStore();

  const handleClickDelete = useCallback(() => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteSpotRequested(id);
      onDelete();
    }
  }, [actions, onDelete]);

  return <SmallButton variant="secondary" onClick={handleClickDelete}>삭제</SmallButton>;
};

const CreateColumn = ({ id }) => {
  const { ...actions } = useSpotsStore();

  const handleClickCreate = useCallback(() => {
    if (window.confirm("정말 추가하시겠습니까?")) {
      actions.createSpotRequested(id)
        .then(() => {
          actions.fetchAllRequested();
        })
        .catch((e) => {
          window.alert(`수거지 추가에 실패했습니다: Status Code: ${e.status}`);
        });
    }
  }, [actions]);

  return <SmallButton variant="secondary" onClick={handleClickCreate}>추가</SmallButton>;
};

export default () => {
  const { state, ...actions } = useSpotsStore();

  useEffect(() => {
    actions.fetchAllRequested();
  }, []);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const columns = useMemo(
    () => [
      {
        Header: "수거지명",
        accessor: "spot.name",
        width: 160,
      },
      {
        Header: "연락처",
        accessor: "spot.contact",
        width: 160,
      },
      {
        Header: "주소",
        accessor: "spot.address",
        width: 270,
      },
      {
        Header: "운임",
        accessor: (row) => (
          <div>{row.spot && row.spot.fee}원</div>
        ),
      },
      {
        Header: "수거지코드",
        accessor: "spot.code",
      },
      {
        id: "create",
        Header: "추가",
        accessor: (row) => <CreateColumn id={row.key} />,
        selectable: false,
        width: 90,
      },
      {
        id: "delete",
        Header: "삭제",
        accessor: (row) => <DeleteColumn id={row.key} />,
        selectable: false,
        width: 90,
      },
    ],
    []
  );

  return (
    <Container>
      <Row>
        <Col>
          <Title>임시 수거지 관리</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <SpotsTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.spotsRequested}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
