import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { Dong } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  dongs: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 20,
    name: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        dongs: action.dongs,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDongsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const download = useCallback(
    () => {
      return api.get("/address/dongs/download");
    }, 
    [api.get]
  );

  const fetchAll = useCallback(async () => {
    const { dongs, pageCount } = await api.get("/address/dongs", {
      page: state.query.page,
      pageSize: state.query.pageSize,
      name: state.query.name,
    });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      dongs: dongs.map((d) => new Dong(d)),
      pageCount,
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch, state.query]
  );

  const create = useCallback(
    (data) => {
      return api.post(`/address/dongs`, data);
    },
    [api.post]
  );

  const update = useCallback(
    (id, data) => {
      return api.put(`/address/dongs/${id}`, data);
    },
    [api.put]
  );

  return {
    state,
    download,
    fetchAll,
    setQuery,
    create,
    update,
  };
};
