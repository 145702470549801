import React, { memo, useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { useModalStore } from "@/store/hooks";

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const INITIAL_ROW = {
  key: "",
  value: "",
};

const AddRowButton = styled(Button)``;

const Row = memo(({ index, onChange, rowKey = "", rowValue = "" }) => {
  const handleChange = useCallback(
    (e) => {
      onChange(index, {
        name: e.target.name,
        value: e.target.value,
      });
    },
    [onChange]
  );

  return (
    <Form.Group as={OrigRow}>
      <Col sm={4}>
        <Form.Control onChange={handleChange} name="key" value={rowKey} />
      </Col>
      <Col sm={8}>
        <Form.Control onChange={handleChange} name="value" value={rowValue} />
      </Col>
    </Form.Group>
  );
});

const SpotExcelSchemeForm = memo(
  ({ onSubmit, initialExcelScheme, loading }) => {
    const { closeModal } = useModalStore();
    const [rows, setRows] = useState([]);

    useEffect(() => {
      if (!loading && initialExcelScheme) {
        try {
          const rows = Object.keys(initialExcelScheme).map((key) => ({
            key,
            value: initialExcelScheme[key],
          }));

          setRows(rows);
        } catch (_) {}
      }
    }, [initialExcelScheme, loading]);

    const handleClickAdd = useCallback(() => {
      setRows([...rows, INITIAL_ROW]);
    }, [rows]);

    const handleClickCancel = useCallback(() => {
      closeModal();
    }, [closeModal]);

    const handleChange = useCallback(
      (index, { name, value }) => {
        const _rows = [...rows];
        _rows[index] = { ..._rows[index], [name]: value };
        setRows(_rows);
      },
      [rows]
    );

    const handleSubmit = useCallback(() => {
      const excelScheme = {};
      rows
        .filter((r) => !!r.key && !!r.value)
        .forEach((row) => {
          excelScheme[row.key] = row.value;
        });
      onSubmit(excelScheme);
    }, [rows]);

    const renderRows = () => {
      return rows.map((row, index) => (
        <Row
          index={index}
          key={index}
          onChange={handleChange}
          rowKey={row.key}
          rowValue={row.value}
        />
      ));
    };

    return (
      <Form>
        {renderRows()}

        <Form.Group as={OrigRow}>
          <Col>
            <AddRowButton block onClick={handleClickAdd} variant="light">
              +
            </AddRowButton>
          </Col>
        </Form.Group>

        <Form.Group as={OrigRow}>
          <Col>
            <CustomButtom type="button" onClick={handleSubmit}>확인</CustomButtom>
            <CustomButtom type="button" variant="secondary" onClick={handleClickCancel}>
            취소
            </CustomButtom>
          </Col>
        </Form.Group>
      </Form>
    );
  }
);

export default SpotExcelSchemeForm;
