import React, { useCallback } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import BaseAddressForm from "@/forms/BaseAddressForm";
import { useBaseAddressesStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useBaseAddressesStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async (data) => {
      const _data = {
        address: data.address,
        addressRoad: data.addressRoad,
        addressDetail: data.addressDetail,
        postalCode: data.postalCode,
        buildingDongNumber: data.buildingDongNumber,
        memo: data.memo,
      };

      if (data.address) {
        actions
          .create(_data)
          .then(() => {
            onCreate();
            closeModal();
          })
          .catch((e) => {
            window.alert(`주소정보 생성에 실패했습니다: ${e.message}`);
          });
      } else {
        window.alert(`주소를 조회해주세요.`);
      }
    },
    [actions.create, onCreate],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>주소 정보 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <BaseAddressForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
