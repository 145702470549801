import BaseModel from "./BaseModel";
import { formatDate } from "@/lib/date";

export class Log extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  renderCreatedAt(dateFormat = "yyyy-MM-dd HH:mm") {
    if (this.createdAt) {
      return formatDate(new Date(this.createdAt), dateFormat);
    } else {
      return "";
    }
  }
}
