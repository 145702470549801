export class CustomDong {
  allocationGroup = null;
  allocationGroupId = null;
  allocationGroupWeekend = null;
  allocationGroupWeekendId = null;
  beonji1 = null;
  beonji2 = null;
  dong = null;
  dongId = null;
  fee = null;
  id = null;

  constructor(customDong) {
    this.allocationGroup = customDong.allocationGroup;
    this.allocationGroupId = customDong.allocationGroupId;
    this.allocationGroupWeekend = customDong.allocationGroupWeekend;
    this.allocationGroupWeekendId = customDong.allocationGroupWeekendId;
    this.beonji1 = customDong.beonji1;
    this.beonji2 = customDong.beonji2;
    this.dong = customDong.dong;
    this.dongId = customDong.dongId;
    this.fee = customDong.fee;
    this.id = customDong.id;

    this.sigungu = this.dong && this.dong.sigungu;
    this.sido = this.sigungu && this.sigungu.sido;
  }

  get displayBeonji2() {
    const beonji2Arr = this.beonji2 ? this.beonji2.split('_') : [];
    const beonji2ArrFiltered = beonji2Arr.filter(function (el) {
      return el !== '';
    });
    
    return beonji2ArrFiltered.join(', ')
  }

  get allocationGroupName() {
    return this.allocationGroup ? this.allocationGroup.name : "";
  }

  get allocationGroupWeekendName() {
    return this.allocationGroupWeekend ? this.allocationGroupWeekend.name : "";
  }

  get dongName() {
    return this.dong ? this.dong.name : "";
  }

  get sigunguName() {
    return this.sigungu ? this.sigungu.name : "";
  }

  get sidoName() {
    return this.sido ? this.sido.name : "";
  }
}
