import BaseModel from "./BaseModel";

import { getPointToArray } from "@/Models/Delivery";

export class BaseAddressesCorrection extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  get address() {
    return this.baseAddress?.address;
  }

  get addressRoad() {
    return this.baseAddress?.addressRoad;
  }

  get correctedPointArr() {
    return {
      longitude: getPointToArray(this.correctedPoint)[0],
      latitude: getPointToArray(this.correctedPoint)[1]
    };
  }

  get correctedPointLat() {
    return this.correctedPointArr?.latitude;
  }

  get correctedPointLng() {
    return this.correctedPointArr?.longitude;
  }
}
