import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Table from "@/components/Table";

import { formatDate, getDateByTimeZone } from "@/lib/date";

import { renderStatusByNumber } from "@/Models/Delivery";

import { useCorpCallbacksStore, useLoadingStore } from "@/store/hooks";
import { CORP_HANJIN_ID, CORP_KURLY_ID } from "@/store/CorpCallbacks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const TabsWrap = styled.div`
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-left: 5px;
  margin-right: 5px;
  min-width: 80px;
`;

const ResultWrap = styled.div`
  margin-bottom: 20px;
`;

const Dl = styled.dl`
  display: flex;
  margin-bottom: 5px;
`;
const Dt = styled.dt`
  margin-right: 5px;
`;
const Dd = styled.dd`
  margin-bottom: 0;
`;

const TabButton = ({
  active,
  corpUserId,
  corpUserNameStr,
  onSelectCorpUser,
}) => {
  const handleSelectCorpUser = () => {
    onSelectCorpUser(corpUserId);
  };

  return (
    <StyledButton
      onClick={handleSelectCorpUser}
      variant={active ? "info" : "light"}
    >
      {corpUserNameStr}
    </StyledButton>
  );
};

export default () => {
  const { state, ...actions } = useCorpCallbacksStore();
  const { finishLoading, startLoading } = useLoadingStore();

  useEffect(() => {
    fetchAll();
  }, [state.corpUserId, state.status]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `콜백 로그 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`,
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.corpUserId]);

  const handleSelectCorpUser = useCallback((corpUserId) => {
    actions.setCorpUserId(corpUserId);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "접수번호",
        accessor: (row) => row.bookId,
        selectable: false,
        width: 200,
      },
      {
        Header: "배송상태",
        accessor: (row) => renderStatusByNumber(row.status),
        selectable: false,
        width: 300,
      },
    ],
    [state.logs],
  );

  return (
    <Container fluid>
      <Title>기업회원 콜백 현황</Title>
      <SearchRow>
        <TabsWrap>
          <TabButton
            active={state.corpUserId === CORP_HANJIN_ID}
            corpUserId={CORP_HANJIN_ID}
            corpUserNameStr="한진"
            onSelectCorpUser={handleSelectCorpUser}
          />
          <TabButton
            active={state.corpUserId === CORP_KURLY_ID}
            corpUserId={CORP_KURLY_ID}
            corpUserNameStr="컬리"
            onSelectCorpUser={handleSelectCorpUser}
          />
        </TabsWrap>
      </SearchRow>

      <ResultWrap>
        <Dl>
          <Dt>접수건수</Dt>
          <Dd>{state.totalCount}건</Dd>
        </Dl>
        <Dl>
          <Dt>완료건수</Dt>
          <Dd>{state.totalCompletedCount}건</Dd>
        </Dl>
        <Dl>
          <Dt>배송출발 콜백 성공건수</Dt>
          <Dd>-건</Dd>
        </Dl>
        <Dl>
          <Dt>배송완료 콜백 성공건수</Dt>
          <Dd>-건</Dd>
        </Dl>
        <Dl>
          <Dt>최근 배송완료 콜백 상태</Dt>
          <Dd>{state.recentCallbackLog?.status}</Dd>
        </Dl>
        <Dl>
          <Dt>최근 배송완료 콜백 일시</Dt>
          <Dd>
            {formatDate(
              getDateByTimeZone(state.recentCallbackLog?.date),
              "yyyy/MM/dd HH:mm",
            )}
          </Dd>
        </Dl>
      </ResultWrap>

      <Table
        responsive
        bordered
        columns={columns}
        data={state.logs}
        pagination={false}
      />
    </Container>
  );
};
