import React, { useEffect, useCallback } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore, useReviewsStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3``;

export default ({ bookId, comment, onUpdate = _noop } = {}) => {
  const { closeModal } = useModalStore();
  const { ...actions } = useReviewsStore();
  const { register, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    reset({
      comment,
    });
  }, [reset]);
  
  const onSubmit = useCallback(async (data) => {
    if (bookId) {
      let _data = { ...data };

      await actions.update(bookId, _data).then(() => {
        window.alert(`수정되었습니다.`);
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(e.message);
      });    
    }
  }, [actions.update, bookId]);  

  return (
    <Modal>
      <Modal.Header>
        <Title>리뷰 댓글 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              댓글
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                as="textarea"
                rows="3"
                name="comment"
                ref={register({ required: "리뷰 댓글 내용을 입력해주세요." })}
              />
              <ErrorText>{errors.comment && errors.comment.message}</ErrorText>
            </StyledCol>
          </Form.Group>

          <Button type="submit">수정하기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
