import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { CreateRiderNoticeModal, EditRiderNoticeModal } from "@/Modals";
import { formatDate, getDateByTimeZone } from "@/lib/date";

import { 
  useRiderNoticesStore, 
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const RiderNoticesTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 10px;
`

const UpdateColumn = ({ riderNotice, onUpdate = _noop }) => {
  const { openModal } = useModalStore();

  function handleClickEdit() {
    openModal(
      <EditRiderNoticeModal
        key={`edit-rider-notice-${riderNotice.id}-modal`}
        riderNotice={riderNotice}
        onUpdate={onUpdate}
      />
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

const DeleteColumn = ({ id, onDelete = _noop }) => {
  const { ...actions } = useRiderNoticesStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteRiderNotice(id);
        onDelete();
      } catch (e) {
        window.alert(`라이더 공지사항 삭제에 실패했습니다: ${e.message}`);
      };      
    }
  }, [actions.deleteRiderNotice, onDelete]);

  return <SmallButton onClick={handleClickDelete} variant="secondary">삭제</SmallButton>;
};

export default () => {
  const { state, ...actions } = useRiderNoticesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  useEffect(() => {
    fetchAll();
  }, [
    state.query.page, 
    state.query.pageSize,
  ]);

  const fetchAll = useCallback(async () => {
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(
        `라이더 공지사항 목록을 불러오는데 실패하였습니다.\n에러메시지: ${e.message}`
      );
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateRiderNoticeModal key="create-rider-notice-modal" onCreate={fetchAll} />
    );
  }, [openModal]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const columns = useMemo(
    () => [
      {
        Header: "id",
        accessor: (row) => row.id,
        selectable: false,
        width: 60,
      },
      {
        Header: "제목",
        accessor: (row) => row.title,
        selectable: false,
        width: 500,
      },
      {
        Header: "공개여부",
        accessor: (row) => row.open ? 'O' : 'X',
        selectable: false,
        width: 100,
      },
      {
        Header: "등록일",
        accessor: (row) => (
          <div>{formatDate(getDateByTimeZone(row.createdAt), "yyyy/MM/dd HH:mm")}</div>
        ),
      },
      {
        Header: "수정",
        accessor: (row) => <UpdateColumn riderNotice={row} onUpdate={fetchAll} />,
        selectable: false,
        width: 80,
      },
      {
        Header: "삭제",
        accessor: (row) => <DeleteColumn id={row.id} onDelete={fetchAll} />,
        selectable: false,
        width: 80,
      },
    ],
    []
  );

  return (
    <Container fluid>
      <Title>라이더 공지사항 관리</Title>
      <ButtonWrap>
        <Button onClick={handleClickCreate}>공지사항 등록</Button>
      </ButtonWrap>
      <RiderNoticesTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.riderNotices}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        previousPage={handlePreviousPage}
      />
    </Container>
  );
};
