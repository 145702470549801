import React, { useCallback, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";
import XLSX from "xlsx";

import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { FileUploadModal, FindCorpUserModal } from "@/Modals";

import {
  useAgPostalCodesStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Title = styled.h3``;

const RESET_ON = "reset";
const RESET_OFF = "update";
const ADDITION = "addition";

const FormSelect = styled(Form.Control)`
  min-width: 100px;
  flex: 1;
  margin-right: 10px;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useAgPostalCodesStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();

  const [bulkUploadReset, setBulkUploadReset] = useState(RESET_ON);
  const [selectedCorpUser, setSelectedCorpUser] = useState(null);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        await actions.bulkUpload({
          corpUserId: selectedCorpUser?.id,
          data: worksheetArr,
          reset: bulkUploadReset === RESET_ON,
        });
      } catch (e) {
        window.alert(`대량 추가에 실패했습니다: 실패 사유: ${e.message}`);
      }

      finishLoading();
      closeModal();
      closeModal();
      onCreate();
    },
    [actions.bulkUpload, bulkUploadReset, onCreate, selectedCorpUser],
  );

  const handleUploadNewOnlyBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        const response = await actions.bulkUploadNewOnly({
          corpUserId: selectedCorpUser?.id,
          data: worksheetArr,
        });

        window.alert(
          `대량 추가 결과\n성공건: ${response.success.length}, 실패건: ${
            response.failed.length
          } ${
            response.failed.length > 0 ? `(${response.failed[0].message})` : ""
          }`,
        );

        console.log(response);
      } catch (e) {
        window.alert(`대량 추가에 실패했습니다: 실패 사유: ${e.message}`);
      }

      finishLoading();
      closeModal();
      closeModal();
      onCreate();
    },
    [actions.bulkUploadNewOnly, bulkUploadReset, onCreate, selectedCorpUser],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload:
            bulkUploadReset === ADDITION
              ? handleUploadNewOnlyBulkFile
              : handleUploadBulkFile,
        }}
        key="ag-postal-codes-bulk-upload-modal"
        title="우편번호 대량생성하기"
      />,
    );
  }, [handleUploadNewOnlyBulkFile, handleUploadBulkFile, bulkUploadReset]);

  const handleChangeBulkUploadReset = useCallback((e) => {
    setBulkUploadReset(e.target.value);
  }, []);

  function handleClickFindCorpUser() {
    openModal(
      <FindCorpUserModal
        onSelect={handleSelectCorpUser}
        key="find-corp-user-modal"
      />,
    );
  }

  const handleSelectCorpUser = useCallback((corpUser) => {
    closeModal();
    setSelectedCorpUser(corpUser);
    console.log(corpUser);
  }, []);

  return (
    <Modal>
      <Modal.Header>
        <Title>우편번호 대량 추가</Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group as={StyledRow}>
          <Form.Label column sm={4}>
            구분
          </Form.Label>
          <StyledCol sm={8}>
            <FormSelect
              as="select"
              onChange={handleChangeBulkUploadReset}
              value={bulkUploadReset}
            >
              <option value={RESET_ON}>신규</option>
              <option value={RESET_OFF}>정정</option>
              <option value={ADDITION}>추가</option>
            </FormSelect>
          </StyledCol>
        </Form.Group>
        <Form.Group as={StyledRow}>
          <Form.Label column sm={4}>
            기업회원
          </Form.Label>
          <StyledCol sm={6}>
            <Form.Control
              readOnly
              name="corpUserName"
              value={selectedCorpUser?.corpTitle || ""}
            />
          </StyledCol>
          <StyledCol sm={2}>
            <Button type="button" onClick={handleClickFindCorpUser}>
              검색
            </Button>
          </StyledCol>
        </Form.Group>
        <Form.Group as={StyledRow}>
          <StyledCol md={{ span: 8, offset: 4 }}>
            <CustomButtom type="button" onClick={handleClickBulkUpload}>
              대량 생성
            </CustomButtom>
          </StyledCol>
        </Form.Group>
      </Modal.Body>
    </Modal>
  );
};
