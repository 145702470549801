import React, { useCallback } from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import BaseAddressesCorrectionForm from "@/forms/BaseAddressesCorrectionForm";
import { useBaseAddressesCorrectionsStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useBaseAddressesCorrectionsStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async (data) => {
      if (data.building && data.buildingDongNumber) {
        window.alert(
          "빌딩동과 동번호는 동시에 입력할 수 없습니다. 하나만 입력해주세요.",
        );
      } else {
        const _data = {
          ...data,
          correctedPoint: [
            Number(data.correctedPointLng),
            Number(data.correctedPointLat),
          ],
        };

        await actions
          .create(_data)
          .then(() => {
            onCreate();
            closeModal();
          })
          .catch((e) => {
            window.alert(`주소용어집 생성에 실패했습니다: ${e.message}`);
          });
      }
    },
    [actions.create, onCreate],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>주소용어집 추가</Title>
      </Modal.Header>
      <Modal.Body>
        <BaseAddressesCorrectionForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
