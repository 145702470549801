import React from "react";
import Col from "react-bootstrap/Col";
import styled from "@emotion/styled";

import Dropzone from "@/components/Dropzone";
import Modal from "@/components/Modal";

const Title = styled.h3``;

export default ({ dropzoneProps = {}, title }) => {
  return (
    <Modal>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <Col>
          <Dropzone {...dropzoneProps} />
        </Col>
      </Modal.Body>
    </Modal>
  );
};
