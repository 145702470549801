import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const AllocationGroupForm = ({ onSubmit, initAllocationGroup }) => {
  const { register, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    if (initAllocationGroup) {
      reset({
        name: initAllocationGroup.name,
        limit: initAllocationGroup.limit,
      });
    }
  }, [initAllocationGroup]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={12}>
          지역그룹명
        </Form.Label>
        <Col sm={12}>
          <Form.Control name="name" ref={register({ required: true })} />
          <ErrorText>{errors.name && "지역그룹명을 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={12}>
          할당량
        </Form.Label>
        <Col sm={12}>
          <Form.Control type="number" name="limit" ref={register} />
        </Col>
      </Form.Group>

      <Button type="submit">
        {initAllocationGroup ? "수정하기" : "생성하기"}
      </Button>
    </Form>
  );
};

export default AllocationGroupForm;
