import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { convertToRaw, ContentState, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { Button } from "@/components/Buttons";
import { useModalStore } from "@/store/hooks";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const FormCheck = styled(Form.Check)`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const RiderNoticeForm = ({ onSubmit, initRiderNotice }) => {
  const { register, handleSubmit, reset, errors, setValue } = useForm();
  const { closeModal } = useModalStore();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  useEffect(() => {
    if (initRiderNotice) {
      reset({
        title: initRiderNotice.title,
        text: initRiderNotice.text,
        open: initRiderNotice.open,
      });

      const contentBlock = htmlToDraft(initRiderNotice.text);

      if (contentBlock) {
        const _contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const _editorState = EditorState.createWithContent(_contentState);
        
        setEditorState(_editorState);
      }
    } else {
      reset({
        open: true,
      });
    }
  }, [initRiderNotice]);

  const handleChangeEditor = (_editorState) => {
    setEditorState(_editorState);

    setValue('text', draftToHtml(convertToRaw(_editorState.getCurrentContent()))); 
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          제목
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="title" ref={register({ required: true })} />
          <ErrorText>{errors.title && "제목을 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          내용
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="text" hidden ref={register} />
          <Editor
            editorState={editorState}
            toolbarClassName="wysiwyg-toolbar"
            wrapperClassName="wysiwyg-wrapper"
            editorClassName="wysiwyg-editor"
            onEditorStateChange={handleChangeEditor}
            localization={{
              locale: 'ko',
            }}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          공개여부
        </Form.Label>
        <Col sm={9}>
          <FormCheck
            type="checkbox"
            name="open"
            ref={register}
          />
        </Col>
      </Form.Group>

      <Form.Group as={OrigRow}>
        <Col md={{ span: 7, offset: 3 }}>
          <CustomButtom type="submit">{initRiderNotice ? '수정하기' : '생성하기'}</CustomButtom>
          <CustomButtom type="button" variant="secondary" onClick={closeModal}>닫기</CustomButtom>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default RiderNoticeForm;
