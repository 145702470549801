import React, { useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 120px;
`;

const RiderNoticeForm = ({ onSubmit, initRiderGroup1, selectedRiderGroup2 }) => {
  const { register, handleSubmit, reset, errors } = useForm();
  const { closeModal } = useModalStore();

  useEffect(() => {
    if (initRiderGroup1) {
      reset({
        name: initRiderGroup1.name,
        riderGroup2Id: initRiderGroup1.riderGroup2Id,
      });
    } else {
      reset({
        riderGroup2Id: selectedRiderGroup2.id,
      });
    }
  }, [initRiderGroup1]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={OrigRow}>
        <Form.Label column sm={3}>
          조명
        </Form.Label>
        <Col sm={9}>
          <Form.Control name="name" ref={register({ required: true })} />
          <ErrorText>{errors.name && "조명을 입력해주세요."}</ErrorText>
        </Col>
      </Form.Group>
      <Form.Control name="riderGroup2Id" ref={register({ required: true })} hidden />
      <Form.Group as={OrigRow}>
        <Col md={{ span: 7, offset: 3 }}>
          <CustomButtom type="submit">{initRiderGroup1 ? '수정하기' : '생성하기'}</CustomButtom>
          <CustomButtom type="button" variant="secondary" onClick={closeModal}>닫기</CustomButtom>
        </Col>
      </Form.Group>
    </Form>
  );
};

export default RiderNoticeForm;
