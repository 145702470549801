import React from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import { useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

const Row = styled(OrigRow)`
  padding: 0;
  align-items: center;
`;

export default ({ id, onHide = _noop }) => {
  // const { ...actions } = useCorpUsersStore();

  const { closeModal } = useModalStore();
  const { register, handleSubmit } = useForm();

  const onSubmit = async (data) => {
    console.log(id);
    console.log(data);
    closeModal();
  };

  return (
    <Modal onHide={onHide}>
      <Modal.Header>
        <Title>API 등록하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              API KEY
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                minLength={2}
                name="apiKey"
                ref={register({ required: true })}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={{ span: 9, offset: 3 }}>
              <Button type="submit">확인</Button>
            </Col>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
