import React, { useEffect, useMemo, useState } from "react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import { useAddressStore } from "@/store/hooks";

const DongsTable = styled(Table)`
  /* th:nth-of-type(1),
  th:nth-of-type(4) {
    width: 150px;
  }

  th:nth-of-type(2) {
    width: 200px;
  } */
`;

const DongsTableRow = styled(Row)`
  padding: 10px 0;
`;

const SearchButtonColumn = styled(Col)`
  text-align: right;
`;

const Title = styled.h3``;

const SelectedDongTitle = styled.h5``;
const SelectedDongs = styled.p``;

export default ({ onSelect }) => {
  const [nameQuery, setNameQuery] = useState("");
  const [selectedDongs, setSelectedDongs] = useState([]);
  const { state, ...actions } = useAddressStore();
  const { register, handleSubmit } = useForm();

  useEffect(() => {
    actions.fetchDongs();
  }, []);

  function handleGoToPage(page) {
    actions.fetchDongs({ name: nameQuery, page });
  }

  function handleNextPage() {
    actions.fetchDongs({ name: nameQuery, page: state.dongsPage + 1 });
  }

  function handlePreviousPage() {
    actions.fetchDongs({ name: nameQuery, page: state.dongsPage - 1 });
  }

  function handleSelect() {
    onSelect(selectedDongs);
  }

  function handleSelectDong({ selectedRows }) {
    setSelectedDongs(selectedRows);
  }

  function handleSetPageSize(pageSize) {
    actions.fetchDongs({
      name: nameQuery,
      pageSize,
    });
  }

  const onSubmit = async ({ name = "" }) => {
    setNameQuery(name);
    actions.fetchDongs({
      name,
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "시도",
        accessor: (row) => row.sigungu.sido.name,
      },
      {
        Header: "시군구",
        accessor: (row) => row.sigungu.name,
      },
      {
        Header: "이름",
        accessor: "name",
      },
    ],
    []
  );

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>동 검색하기</Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Row>
                <Col md={8}>
                  <Form.Group>
                    <Form.Control
                      name="name"
                      placeholder="동 이름"
                      ref={register}
                    />
                  </Form.Group>
                </Col>
                <SearchButtonColumn md={{ offset: 2, span: 2 }}>
                  <Button type="submit">검색하기</Button>
                </SearchButtonColumn>
              </Row>
            </Form>
          </Col>
        </Row>
        <DongsTableRow>
          <Col>
            <DongsTable
              columns={columns}
              currentPage={state.dongsPage}
              data={state.dongs}
              goToPage={handleGoToPage}
              nextPage={handleNextPage}
              onSelect={handleSelectDong}
              pageCount={state.dongsPageCount}
              pageSize={state.dongsPageSize}
              previousPage={handlePreviousPage}
              setPageSize={handleSetPageSize}
            />
          </Col>
        </DongsTableRow>
        <Row>
          <Col>
            <SelectedDongTitle>선택된 동 목록</SelectedDongTitle>
            <SelectedDongs>
              {selectedDongs.map((dong) => dong.name).join(", ")}
            </SelectedDongs>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleSelect}>선택하기</Button>
      </Modal.Footer>
    </Modal>
  );
};
