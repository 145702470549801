import React from "react";
import _noop from "lodash/noop";
import Form from "react-bootstrap/Form";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";

import {
  useModalStore,
  useDeliveriesMisclassificationStore,
} from "@/store/hooks";

import { AlertModal } from "@/Modals";
const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const Title = styled.h3`
  margin: 0;
`;

export default ({ onUpdate = _noop } = {}) => {
  const { openModal } = useModalStore();
  const { ...actions } = useDeliveriesMisclassificationStore();
  const { register, handleSubmit, errors } = useForm();

  const onSubmit = async ({ bookIds }) => {
    if (bookIds) {
      const bookIdsArr = bookIds.split(/\n/);

      try {
        const response = await actions.setMisclassification({
          bookIds: bookIdsArr,
        });

        const successes = response.filter(
          (el) => el.status !== "rejected" && el.value?.success,
        );

        let text = `${bookIdsArr.length}건 중 ${successes.length}건을 오분류 처리했습니다.\n\n실패 목록`;

        response.map((el, idx) => {
          if (el.status === "rejected" || !el.value?.success) {
            const _text = `\n${bookIdsArr[idx]}(실패사유: ${el.reason
              ?.message || ""})`;

            text += _text;
          }
        });

        openModal(
          <AlertModal key="alert-modal" title="오분류 처리 결과" text={text} />,
        );

        onUpdate();
      } catch (e) {
        window.alert(`물품확인 등록에 실패했습니다: ${e.message}`);
      }
    }
  };

  return (
    <Modal size="sm">
      <Modal.Header>
        <Title>물품확인 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>배송번호/사측주문번호</Form.Label>
            <Form.Control
              as="textarea"
              rows={10}
              name="bookIds"
              ref={register({ required: true })}
            />
            <ErrorText>
              {errors.bookIds && "배송번호/사측주문번호를 입력해주세요."}
            </ErrorText>
          </Form.Group>
          <Form.Group>
            <Button type="submit">오분류 처리</Button>
          </Form.Group>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
