import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { CreateBaseAddressesCorrectionModal } from "@/Modals";

import {
  useBaseAddressesCorrectionsStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledForm = styled(Form)`
  flex: 1;
`;

const FormGroup = styled(Form.Group)`
  margin-bottom: 0;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

const BaseAddressesCorrectionsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const DeleteColumn = memo(({ id, address, onDelete = _noop }) => {
  const { ...actions } = useBaseAddressesCorrectionsStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm(`${address} 주소용어집을 정말 삭제하시겠습니까?`)) {
      try {
        await actions.deleteBaseAddressesCorrection(id);
        onDelete();
      } catch (e) {
        window.alert(`주소용어집 삭제에 실패했습니다: ${e.message}`);
      }
      onDelete();
    }
  }, [actions.deleteBaseAddressesCorrection, onDelete, id, address]);

  return (
    <SmallButton onClick={handleClickDelete} variant="secondary">
      삭제
    </SmallButton>
  );
});

export default () => {
  const { state, ...actions } = useBaseAddressesCorrectionsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal } = useModalStore();

  const { handleSubmit } = useForm();

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(() => {
    try {
      startLoading();
      actions.fetchAll();
    } catch (e) {
      window.alert(`목록 조회에 실패하였습니다.\n에러메시지:${e.message}`);
    }

    finishLoading();
  }, [actions.fetchAll, state.query]);

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateBaseAddressesCorrectionModal
        key="create-base-addresses-correction-modal"
        onCreate={handleCreate}
      />,
    );
  }, [handleCreate, openModal]);

  const handleDelete = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(() => {
    actions.setQuery({ page: 1 });
    fetchAll();
  }, [actions.setQuery, fetchAll]);

  const columns = useMemo(() => [
    {
      Header: "도로명 주소",
      accessor: "addressRoad",
      selectable: false,
      width: 300,
    },
    {
      Header: "지번 주소",
      accessor: "address",
      selectable: false,
      width: 300,
    },
    {
      Header: "빌딩",
      accessor: "building",
      selectable: false,
    },
    {
      Header: "동번호",
      accessor: "buildingDongNumber",
      selectable: false,
    },
    {
      Header: "위도",
      accessor: "correctedPointLat",
      selectable: false,
    },
    {
      Header: "경도",
      accessor: "correctedPointLng",
      selectable: false,
    },
    {
      id: "delete",
      Header: "삭제",
      accessor: (row) => (
        <DeleteColumn
          id={row.id}
          address={row.address}
          onDelete={handleDelete}
        />
      ),
      selectable: false,
    },
  ]);

  return (
    <Container>
      <Title>주소용어집 관리</Title>

      <SearchRow>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <StyledCol md={5}>
              <FormGroup>
                <Form.Control
                  name="address"
                  onChange={handleChange}
                  placeholder="도로명/지번 주소"
                  value={state.query.address}
                />
              </FormGroup>
            </StyledCol>

            <StyledCol md={3}>
              <Button type="submit">검색하기</Button>
            </StyledCol>
          </StyledRow>
        </StyledForm>
        <StyledButton type="button" onClick={handleClickCreate}>
          주소용어집 추가
        </StyledButton>
      </SearchRow>

      <BaseAddressesCorrectionsTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.baseAddressesCorrections}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
};
