import React, { useMemo, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Button } from "@/components/Buttons";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import Table from "@/components/Table";
import { useAddressStore, useLoadingStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const StyledRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const SearchRow = styled.div`
  margin-bottom: 10px;
`;

const DatePickerCol = styled(StyledCol)`
  display: flex;
  flex-direction: row;

  input {
    width: 100%;
  }
`;

export default () => {
  const { state, ...actions } = useAddressStore();

  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register, reset } = useForm();

  useEffect(() => {
    reset({
      ...state.soojioneIncorrectionsQuery,
    });
  }, []);

  useEffect(() => {
    fetchSoojioneIncorrections();
  }, [state.soojioneIncorrectionsQuery.date]);

  const fetchSoojioneIncorrections = async () => {
    try {
      startLoading();

      await actions.fetchSoojioneIncorrections();
    } catch (e) {
      window.alert(`목록 조회에 실패했습니다. \n에러메시지: ${e.message}`);
    }

    finishLoading();
  };

  const columns = useMemo(
    () => [
      {
        Header: "고객입력주소값",
        accessor: (row) => `${row.address} ${row.addressDetail}`,
        width: 300,
      },
      {
        Header: "자동주소정제결과도로명주소값",
        accessor: (row) =>
          `${row.refinedAddress.addressRoad} ${row.refinedAddress.addressRoadDetail}`,
        width: 300,
      },
      {
        Header: "자동주소정제결과위치값",
        accessor: (row) =>
          `${row.refinedAddressPointArr.latitude} ${row.refinedAddressPointArr.longitude}`,
        width: 200,
      },
      {
        Header: "자동조정제한주소정제결과도로명주소값",
        accessor: (row) =>
          `${row.limitedRefinedAddress.addressRoad} ${row.limitedRefinedAddress.addressRoadDetail}`,
        width: 300,
      },
      {
        Header: "자동조정제한주소정제결과위치값",
        accessor: (row) =>
          `${row.limitedRefinedAddressPointArr.latitude} ${row.limitedRefinedAddressPointArr.longitude}`,
        width: 200,
      },
      {
        Header: "위치간거리",
        accessor: (row) => `${row.distanceRefineToLimitedRefined}m`,
        width: 200,
      },
    ],
    [],
  );

  const onSubmit = ({ date }) => {
    actions.setSoojioneIncorrectionsQuery({ date });
  };

  return (
    <Container fluid>
      <Title>수지원 자동 정제 결과</Title>

      <SearchRow>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <StyledRow>
            <Form.Label column sm={2}>
              일자
            </Form.Label>
            <DatePickerCol md={4}>
              <Form.Control name="date" type="date" ref={register} />
            </DatePickerCol>
            <StyledCol md={2}>
              <Button type="submit">조회</Button>
            </StyledCol>
          </StyledRow>
        </Form>
      </SearchRow>

      <Table
        responsive
        bordered
        checkbox={false}
        columns={columns}
        data={state.addressesSoojioneIncorrections}
        pagination={false}
      />
    </Container>
  );
};
