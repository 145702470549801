import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { CORP_USER_NAMES, DELIVERY_STATUS_TYPES } from "@/constants";

import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  FETCH_ALL_ORIGIN: "FETCH_ALL_ORIGIN",
  FETCH_TRY_ALL: "FETCH_TRY_ALL",
  FETCH_COUNT: "FETCH_COUNT",
  SET_CORP_USER_NAME: "SET_CORP_USER_NAME",
  SET_STATUS: "SET_STATUS",
};

const INITIAL_STATE = {
  corpUserName: CORP_USER_NAMES.EMART,
  status: DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
  uploadFailedsOrigin: [],
  uploadFaileds: [],
  uploadFailedsCount: {
    [CORP_USER_NAMES.CJ]: 0,
    [CORP_USER_NAMES.EMART]: 0,
    [CORP_USER_NAMES.OLIVE_STARTED]: 0,
    [CORP_USER_NAMES.OLIVE_COMPLETED]: 0,
    [CORP_USER_NAMES.HANJIN]: 0,
    [CORP_USER_NAMES.DRX]: 0,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        uploadFaileds: action.uploadFaileds,
      };
    case CONSTANTS.FETCH_ALL_ORIGIN:
      return {
        ...state,
        uploadFailedsOrigin: action.uploadFailedsOrigin,
      };
    case CONSTANTS.FETCH_COUNT:
      return {
        ...state,
        uploadFailedsCount: {
          ...state.uploadFailedsCount,
          ...action.uploadFailedsCount,
        },
      };
    case CONSTANTS.SET_CORP_USER_NAME:
      return {
        ...state,
        corpUserName: action.corpUserName,
      };
    case CONSTANTS.SET_STATUS:
      return {
        ...state,
        status: action.status,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useCorpUploadFailedStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { items } = await api.get(
      `/etc/${state.corpUserName}/deliveries/update-failed/v2`,
    );

    let uploadFaileds = [];

    if (
      state.corpUserName === CORP_USER_NAMES.OLIVE &&
      state.status === DELIVERY_STATUS_TYPES.DELIVERY_STARTED
    ) {
      uploadFaileds = items.filter(
        (item) =>
          item.delivery?.status === DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
      );

      dispatch({
        type: CONSTANTS.FETCH_COUNT,
        uploadFailedsCount: {
          [CORP_USER_NAMES.OLIVE_STARTED]: uploadFaileds.length,
        },
      });
    } else if (
      state.corpUserName === CORP_USER_NAMES.OLIVE &&
      state.status === DELIVERY_STATUS_TYPES.OLIVE_COMPLETED
    ) {
      uploadFaileds = items.filter(
        (item) =>
          item.delivery?.status !== DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
      );

      dispatch({
        type: CONSTANTS.FETCH_COUNT,
        uploadFailedsCount: {
          [CORP_USER_NAMES.OLIVE_COMPLETED]: uploadFaileds.length,
        },
      });
    } else if (state.corpUserName === CORP_USER_NAMES.HANJIN) {
      uploadFaileds = items.filter(
        (item) =>
          Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED ||
          Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_SENT_BACK || // 반송완료
          Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_LOST, // 분실완료
      );

      dispatch({
        type: CONSTANTS.FETCH_COUNT,
        uploadFailedsCount: {
          [CORP_USER_NAMES.HANJIN]: uploadFaileds.length,
        },
      });
    } else {
      uploadFaileds = items;

      dispatch({
        type: CONSTANTS.FETCH_COUNT,
        uploadFailedsCount: {
          [state.corpUserName]: items.length,
        },
      });
    }

    const _uploadFaileds = uploadFaileds.sort((a, b) => {
      return a.date > b.date ? -1 : 1;
    });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      uploadFaileds: _uploadFaileds.map((d) => {
        return {
          ...d,
          delivery: new Delivery(d.delivery),
          status: Number(d.status),
        };
      }),
    });
    dispatch({
      type: CONSTANTS.FETCH_ALL_ORIGIN,
      uploadFailedsOrigin: uploadFaileds,
    });
  }, [api.get, dispatch, state.corpUserName, state.status]);

  const fetchTryAll = useCallback(async () => {
    const { items } = await api.get(`/etc/deliveries/update-try-failed`);

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      uploadFaileds: items,
    });
  }, [api.get, dispatch]);

  const fetchCount = useCallback(async () => {
    const { items: cj } = await api.get(
      `/etc/${CORP_USER_NAMES.CJ}/deliveries/update-failed/v2`,
    );
    const { items: emart } = await api.get(
      `/etc/${CORP_USER_NAMES.EMART}/deliveries/update-failed/v2`,
    );

    const { items: olive } = await api.get(
      `/etc/${CORP_USER_NAMES.OLIVE}/deliveries/update-failed/v2`,
    );

    const { items: hanjin } = await api.get(
      `/etc/${CORP_USER_NAMES.HANJIN}/deliveries/update-failed/v2`,
    );

    const { items: drx } = await api.get(
      `/etc/${CORP_USER_NAMES.DRX}/deliveries/update-failed/v2`,
    );

    dispatch({
      type: CONSTANTS.FETCH_COUNT,
      uploadFailedsCount: {
        [CORP_USER_NAMES.CJ]: cj.length,
        [CORP_USER_NAMES.EMART]: emart.length,
        [CORP_USER_NAMES.OLIVE_STARTED]: olive.filter(
          (item) =>
            item.delivery?.status === DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
        ).length,
        [CORP_USER_NAMES.OLIVE_COMPLETED]: olive.filter(
          (item) =>
            item.delivery?.status !== DELIVERY_STATUS_TYPES.DELIVERY_STARTED,
        ).length,
        [CORP_USER_NAMES.HANJIN]: hanjin.filter(
          (item) =>
            Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_COMPLETED ||
            Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_SENT_BACK || // 반송완료
            Number(item.status) === DELIVERY_STATUS_TYPES.DELIVERY_LOST, // 분실완료,
        ).length,
        [CORP_USER_NAMES.DRX]: drx.length,
      },
    });
  }, [api.get, dispatch]);

  const setCorpUserName = useCallback(
    (corpUserName) => {
      dispatch({
        type: CONSTANTS.SET_CORP_USER_NAME,
        corpUserName,
      });
    },
    [dispatch],
  );

  const setStatus = useCallback(
    (status) => {
      dispatch({
        type: CONSTANTS.SET_STATUS,
        status,
      });
    },
    [dispatch],
  );

  const setOrder = useCallback(
    (orderBy) => {
      let _uploadFaileds = [...state.uploadFailedsOrigin];

      if (orderBy === "-DATE") {
        _uploadFaileds.sort((a, b) => {
          return a.date > b.date ? -1 : 1;
        });
      } else if (orderBy === "DATE") {
        _uploadFaileds.sort((a, b) => {
          return a.date < b.date ? -1 : 1;
        });
      } else if (orderBy === "-ORDER_ID_FROM_CORP") {
        _uploadFaileds.sort((a, b) => {
          return a.delivery?.orderIdFromCorp > b.delivery?.orderIdFromCorp
            ? -1
            : 1;
        });
      } else if (orderBy === "ORDER_ID_FROM_CORP") {
        _uploadFaileds.sort((a, b) => {
          return a.delivery?.orderIdFromCorp < b.delivery?.orderIdFromCorp
            ? -1
            : 1;
        });
      }

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        uploadFaileds: _uploadFaileds,
      });
    },
    [dispatch, state.uploadFaileds],
  );

  const cancelBulk = useCallback(
    async (deliveries) => {
      return await Promise.allSettled(
        deliveries.map((item) =>
          api.del(
            `/etc/${state.corpUserName}/deliveries/update-failed/${item.delivery?.bookId}`,
            {
              status: item.delivery?.statusNubmer,
            },
          ),
        ),
      );
    },
    [api.del, state.corpUserName],
  );

  const retry = useCallback(
    async (data) => {
      return await api.put(
        `/etc/${state.corpUserName}/deliveries/update-failed/${data.bookId}`,
        {
          status: data.statusNumber,
        },
      );
    },
    [api.put, state.corpUserName],
  );

  const retryBulk = useCallback(
    async (deliveries) => {
      return await Promise.allSettled(
        deliveries.map((item) =>
          api.put(
            `/etc/${state.corpUserName}/deliveries/update-failed/${item.delivery?.bookId}`,
            {
              status: item.delivery?.statusNubmer,
              reasonType: item.reasonType,
            },
          ),
        ),
      );
    },
    [api.put, state.corpUserName],
  );

  const retryBulkByBookIds = useCallback(
    async ({ bookIds, type }) => {
      return await Promise.allSettled(
        bookIds.map((bookId) =>
          api.put(
            `/etc/${state.corpUserName}/deliveries/update-failed/${bookId}`,
            { type },
            // {
            //   status: item.delivery?.statusNubmer,
            //   reasonType: item.reasonType,
            // },
          ),
        ),
      );
    },
    [api.put, state.corpUserName],
  );

  return {
    state,
    fetchAll,
    fetchTryAll,
    fetchCount,
    setCorpUserName,
    setStatus,
    setOrder,
    cancelBulk,
    retry,
    retryBulk,
    retryBulkByBookIds,
  };
};
