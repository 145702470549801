import React, { memo } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

const PaginationRow = styled(Row)`
  margin-bottom: 1rem;
  margin-top: 1rem;
`;

export default memo(
  ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    previousPage,
    setPageSize,
  }) => {
    function handleChangeSelectPageCount(e) {
      setPageSize(Number(e.target.value));
    }

    function handleClickFirstPage() {
      gotoPage(1);
    }

    function handleClickLastPage() {
      gotoPage(pageCount);
    }

    function handleClickNextPage() {
      nextPage();
    }

    function handleClickPreviousPage() {
      previousPage();
    }

    return (
      <PaginationRow>
        <Col>
          <ButtonGroup>
            <Button onClick={handleClickFirstPage} disabled={!canPreviousPage}>
              {"<<"}
            </Button>{" "}
            <Button
              onClick={handleClickPreviousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>{" "}
          </ButtonGroup>
        </Col>
        <Col>
          <span>
            페이지{" "}
            <strong>
              {pageIndex + 1}/{pageCount}
            </strong>{" "}
          </span>
        </Col>
        <Col>
          <ButtonGroup>
            <Button onClick={handleClickNextPage} disabled={!canNextPage}>
              {">"}
            </Button>{" "}
            <Button onClick={handleClickLastPage} disabled={!canNextPage}>
              {">>"}
            </Button>{" "}
          </ButtonGroup>
        </Col>
        <Col>
          {setPageSize && (
            <Form.Control
              as="select"
              value={pageSize}
              onChange={handleChangeSelectPageCount}
            >
              {[5, 10, 20, 25, 50, 100, 500, 1000].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}개씩 보기
                </option>
              ))}
            </Form.Control>
          )}
        </Col>
      </PaginationRow>
    );
  },
);
