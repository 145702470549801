import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  deliveries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useHanjinStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const deliveries = await api.get("/etc/hanjin/deliveries/update-failed");

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveries,
    });
  }, [api.get, dispatch]);

  const retry = useCallback(
    (orderNumber) => api.put(`/etc/hanjin/deliveries/${orderNumber}/retry`),
    [api.post],
  );

  const retryAll = useCallback(() => api.put("/etc/hanjin/deliveries/retry"), [
    api.post,
  ]);

  return {
    state,
    fetchAll,
    retry,
    retryAll,
  };
};
