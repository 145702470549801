import React, { useCallback } from "react";
import _noop from "lodash/noop";

import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import { useAllocationGroupsStore, useModalStore } from "@/store/hooks";

import AllocationGroupForm from "@/forms/AllocationGroupForm";

const Title = styled.h3``;

export default ({ onCreate = _noop }) => {
  const { ...actions } = useAllocationGroupsStore();
  const { closeModal } = useModalStore();

  const onSubmit = useCallback(
    async (data) => {
      const _data = {
        ...data,
      };

      if (data.limit) {
        _data.limit = Number(data.limit);
      }

      try {
        await actions.createAllocationGroup(_data);

        closeModal();
        onCreate();

        window.alert("지역그룹을 추가했습니다.");
      } catch (e) {
        window.alert(
          `지역그룹 추가에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    },
    [actions.createAllocationGroup, onCreate],
  );

  return (
    <Modal>
      <Modal.Header>
        <Title>지역그룹 생성하기</Title>
      </Modal.Header>
      <Modal.Body>
        <AllocationGroupForm onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
