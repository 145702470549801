import React, { useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { FindAllocationGroupsModal, FindCorpUserModal } from "@/Modals";
import { useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const CustomButtom = styled(Button)`
  margin-right: 5px;
  width: 160px;
`;

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;

const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const AgPostalCodeForm = ({ onSubmit, initAgPostalCode }) => {
  const {
    register,
    reset,
    handleSubmit,
    errors,
    setValue,
    getValues,
  } = useForm();
  const { openModal, closeModal } = useModalStore();

  // const [selectedAllocationGroup, setSelectedAllocationGroup] = useState(null);

  useEffect(() => {
    if (initAgPostalCode) {
      reset({
        fee: initAgPostalCode.fee,
        postalCode: initAgPostalCode.postalCode,
        allocationGroupName: initAgPostalCode.allocationGroup.name,
        corpUserId: initAgPostalCode.corpUserId,
      });

      // setSelectedAllocationGroup(initAgPostalCode.allocationGroup);
    }
  }, [initAgPostalCode]);

  function handleClickFindAllocationGroup() {
    openModal(
      <FindAllocationGroupsModal
        onSelect={handleSelectAllocationGroups}
        key="find-allocation-groups-modal"
      />,
    );
  }

  function handleSelectAllocationGroups(allocationGroups) {
    closeModal();
    // setSelectedAllocationGroup(allocationGroups[0]);

    reset({
      ...getValues(),
      allocationGroupName: allocationGroups[0].name,
    });
  }

  function handleClickFindCorpUser() {
    openModal(
      <FindCorpUserModal
        onSelect={handleSelectCorpUser}
        key="find-corp-user-modal"
      />,
    );
  }

  const handleSelectCorpUser = useCallback((corpUser) => {
    closeModal();
    setValue("corpUserId", corpUser?.id);
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group as={StyledRow}>
        <Form.Label column sm={4}>
          우편번호
        </Form.Label>
        <StyledCol sm={8}>
          <Form.Control
            minLength={2}
            name="postalCode"
            ref={register({ required: "우편번호를 입력해주세요." })}
          />
          <ErrorText>
            {errors.postalCode && errors.postalCode.message}
          </ErrorText>
        </StyledCol>
      </Form.Group>

      <Form.Group as={StyledRow}>
        <Form.Label column sm={4}>
          운임
        </Form.Label>
        <StyledCol sm={8}>
          <Form.Control minLength={2} name="fee" ref={register} />
        </StyledCol>
      </Form.Group>

      <Form.Group as={StyledRow}>
        <Form.Label column sm={4}>
          배차그룹
        </Form.Label>
        <StyledCol sm={6}>
          <Form.Control
            readOnly
            name="allocationGroupName"
            ref={register({ required: "배차그룹 선택해주세요." })}
          />
        </StyledCol>
        <StyledCol sm={2}>
          <Button type="button" onClick={handleClickFindAllocationGroup}>
            검색
          </Button>
        </StyledCol>
        <StyledCol sm={{ span: 8, offset: 4 }}>
          <ErrorText>
            {errors.allocationGroupName && errors.allocationGroupName.message}
          </ErrorText>
        </StyledCol>
      </Form.Group>

      <Form.Group as={StyledRow}>
        <Form.Label column sm={4}>
          기업회원 아이디
        </Form.Label>
        <StyledCol sm={6}>
          <Form.Control readOnly name="corpUserId" ref={register} />
        </StyledCol>
        <StyledCol sm={2}>
          <Button type="button" onClick={handleClickFindCorpUser}>
            검색
          </Button>
        </StyledCol>
      </Form.Group>

      <Form.Group as={StyledRow}>
        <StyledCol md={{ span: 8, offset: 4 }}>
          <CustomButtom type="submit">
            {initAgPostalCode ? "수정하기" : "생성하기"}
          </CustomButtom>
        </StyledCol>
      </Form.Group>
    </Form>
  );
};

export default AgPostalCodeForm;
