import React from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useModalStore, useRidersStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ riderId, onUpdate = _noop }) => {
  const { closeModal } = useModalStore();
  const { ...ridersActions } = useRidersStore();
  const { register, handleSubmit } = useForm();

  function handleClose() {
    closeModal();
  }

  const onSubmit = async ({ password }) => {
    ridersActions
      .updatePassword(riderId, {
        password
      })
      .then(() => {
        onUpdate();
        closeModal();
        window.alert(`변경되었습니다.`);
      })
      .catch((e) => {
        window.alert(`라이더 비밀번호 수정에 실패했습니다:\nStatus Code: ${e.status}\nMessage: ${e.message}`);
      });
  };

  return (
    <Modal>
      <Modal.Header>
        <Title>비밀번호 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={OrigRow}>
            <Form.Label column sm={4}>
              비밀번호
            </Form.Label>
            <Col sm={8}>
              <Form.Control
                name="password"
                ref={register({ required: true })}
              />
            </Col>
          </Form.Group>
          <Button type="submit">확인</Button>{" "}
          <Button onClick={handleClose}>닫기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
