import BaseModel from "./BaseModel";
import { formatDate } from "@/lib/date";

import { renderStatusByNumber } from "@/Models/Delivery";

export class DeliveryStatusLog extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  get deliveryStatusLogEtcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get isAgency() {
    return !!this.agencyCorpUserId;
  }

  get logWriter() {
    if (this.user) {
      return `${this.user?.realname}(관제)`;
    } else if (this.rider) {
      return `${this.rider?.name}(라이더앱)`;
    } else {
      return "";
    }
  }

  get agencyDeliveryRiderName() {
    return this.deliveryStatusLogEtcObj?.agencyDeliveryRiderName || "";
  }

  get agencyFailedReason() {
    return this.deliveryStatusLogEtcObj?.agencyFailedReason || "";
  }

  renderCreatedAt(dateFormat = "yyyy-MM-dd HH:mm") {
    if (this.deliveryStatusLogEtcObj?.agencyUpdatedAt) {
      return formatDate(
        new Date(this.deliveryStatusLogEtcObj.agencyUpdatedAt),
        dateFormat,
      );
    } else if (this.createdAt) {
      return formatDate(new Date(this.createdAt), dateFormat);
    } else {
      return "";
    }
  }

  renderLogStatus = () => {
    switch (this.after) {
      case 0:
        if (this.before === 1) {
          return "접수(이전단계로)";
        } else {
          return "접수";
        }
      case 1:
        if (this.before === 2) {
          return "수지(이전단계로)";
        } else if (this.before === 1) {
          return "재배차";
        } else {
          return "수지";
        }
      case 2:
        if (this.before === 3) {
          return "수완(이전단계로)";
        } else {
          return "수완";
        }
      case 3:
        if (this.before === 8) {
          return "입고(이전단계로)";
        } else if (this.before === 1) {
          return "미수거입고";
        } else if (this.before === 3) {
          return "재배차";
        } else {
          return "입고";
        }
      case 8:
        if (this.before === 4) {
          return "배차(이전단계로)";
        } else if (this.before === 8) {
          return "재배차";
        } else {
          return "배차";
        }
      case 4:
        if (this.before === 5) {
          return "배출(이전단계로)";
        } else if (this.before === 4) {
          return "재배차";
        } else {
          return "배출";
        }
      case 5:
        return "배완";

      case 6:
        return "반송";
      case 7:
        return "분실";
      case 10:
        return "사고";
      case 11:
        return "취소";
      case 12:
        return "연기";

      case 16:
        return "배달대행 접수";
      case 17:
        return "배달대행 접수 실패";
      case 18:
        return "배달대행에서 취소";
      case 19:
        return "배달대행에서 라이더 배차";
      case 20:
        return "배달대행에서 라이더 배차 취소";
      case 21:
        return "오분류";
      default:
        return "";
    }
  };

  renderStatusBefore = () => {
    return renderStatusByNumber(this.before);
  };

  renderStatusAfter = () => {
    return renderStatusByNumber(this.after);
  };
}
