import React, { useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { useAlimtalkNoticeStore, useModalStore } from "@/store/hooks";

const Row = styled(OrigRow)`
  padding: 10px 0;
`;

const Title = styled.h3``;

export default ({ message, onUpdate = _noop }) => {
  const { ...actions } = useAlimtalkNoticeStore();
  const { closeModal } = useModalStore();
  const { register, handleSubmit, reset } = useForm();

  function handleClose() {
    closeModal();
  }

  useEffect(() => {
    reset({
      message,
    });
  }, []);

  const onSubmit = ({ message }) => {
    actions
      .update({ message })
      .then(() => {
        onUpdate();
        closeModal();
      })
      .catch((e) => {
        window.alert(e.message);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>알림톡 공지메시지 수정</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Group>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="message"
                  ref={register}
                />
              </Form.Group>
            </Col>
          </Row>
          <Button type="submit">확인</Button>{" "}
          <Button onClick={handleClose}>닫기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
