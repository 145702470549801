import React, { useCallback } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useForm } from "react-hook-form";

import { Button } from "@/components/Buttons";
import styled from "@emotion/styled";

import { PLATFORM } from "@/store/Translate";
import { useTranslateStore, useLoadingStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;

const OrigRow = styled(Row)`
  margin-left: -5px;
  margin-right: -5px;
`;
const OrigCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const StyledButton = styled(Button)`
  width: 100%;
  max-width: 240px;
`;

const Result = styled.div`
  font-size: 18px;
`;

const ResultTitle = styled.h5`
  font-weight: bold;
  font-size: 18px;
`;

export default () => {
  const { state, ...actions } = useTranslateStore();
  const { finishLoading, startLoading } = useLoadingStore();

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = useCallback(async () => {
    startLoading();

    try {
      await actions.translateText();
    } catch (e) {
      window.alert(`번역 테스트에 실패하였습니다.\n에러메시지: ${e.message}`);
    }

    finishLoading();
  }, [actions.translateText, startLoading, finishLoading]);

  const handleChange = useCallback(
    (e) => {
      actions.setBody({
        [e.target.name]: e.target.value,
      });
    },
    [actions.setBody],
  );

  return (
    <Container>
      <Title>번역 테스트</Title>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={OrigRow}>
          <OrigCol sm={2}>
            <Form.Label>텍스트</Form.Label>
          </OrigCol>
          <OrigCol sm={10}>
            <Form.Control
              value={state.body.text}
              onChange={handleChange}
              name="text"
              ref={register({ required: true })}
            />
            <ErrorText>{errors.text && "텍스트를 입력해주세요."}</ErrorText>
          </OrigCol>
        </Form.Group>
        <Form.Group as={OrigRow}>
          <OrigCol sm={2}>
            <Form.Label>플랫폼</Form.Label>
          </OrigCol>
          <OrigCol sm={10}>
            <Form.Control
              as="select"
              name="platform"
              value={state.body.platform}
              onChange={handleChange}
              ref={register({ required: true })}
            >
              <option value={PLATFORM.DEEPL}>{PLATFORM.DEEPL}</option>
              <option value={PLATFORM.PAPAGO}>{PLATFORM.PAPAGO}</option>
              <option value={PLATFORM.OPENAI}>{PLATFORM.OPENAI}</option>
            </Form.Control>
          </OrigCol>
        </Form.Group>
        <Form.Group as={OrigRow}>
          <OrigCol sm={{ span: 10, offset: 2 }}>
            <StyledButton type="submit">번역 테스트</StyledButton>
          </OrigCol>
        </Form.Group>
      </Form>
      {state.textTranslated && (
        <OrigRow>
          <OrigCol sm={{ span: 10, offset: 2 }}>
            <Result>
              <ResultTitle>번역 결과</ResultTitle>
              {state.textTranslated}
            </Result>
          </OrigCol>
        </OrigRow>
      )}
    </Container>
  );
};
