import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import XLSX from "xlsx";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { CreateDongNotSupportedModal, FileUploadModal } from "@/Modals";

import {
  useDongsNotSupportedStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const StyledButton = styled(Button)`
  margin-right: 5px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const DongsNotSupportedTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const DongContainer = styled.div``;

const Dong = ({ postalCode, dong, onDelete }) => {
  const { ...actions } = useDongsNotSupportedStore();

  const handleDelete = useCallback(async () => {
    if (
      window.confirm(`우편번호 ${postalCode}-${dong} 정보를 삭제하시겠습니까?`)
    ) {
      try {
        await actions.deleteDongNotSupported({ postalCode, dong });
        onDelete();
      } catch (e) {
        window.alert(`배송불가 동 삭제에 실패했습니다: ${e.message}`);
      }
    }
  }, [actions.deleteDongNotSupported, postalCode, dong]);

  return (
    <DongContainer>
      {dong}{" "}
      <SmallButton variant="danger" onClick={handleDelete}>
        삭제
      </SmallButton>
    </DongContainer>
  );
};

export default () => {
  const { state, ...actions } = useDongsNotSupportedStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { openModal, closeModal } = useModalStore();

  useEffect(() => {
    fetchAll();
  }, []);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleCreate = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);
      try {
        startLoading();

        await actions.bulkUpload({
          data: worksheetArr,
        });
      } catch (e) {
        window.alert(`대량 추가에 실패했습니다: 실패 사유: ${e.message}`);
      }

      finishLoading();
      closeModal();
      fetchAll();
    },
    [actions.bulkUpload, fetchAll],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleUploadBulkFile,
        }}
        key="dongs-not-supported-bulk-upload-modal"
        title="배송불가 동 대량생성하기"
      />,
    );
  }, [handleUploadBulkFile]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateDongNotSupportedModal
        key="create-dong-not-supported-modal"
        onCreate={handleCreate}
      />,
    );
  }, [handleCreate, openModal]);

  const handleDelete = useCallback(() => {
    fetchAll();
  }, [fetchAll]);

  const columns = useMemo(() => [
    {
      Header: "우편번호",
      accessor: "postalCode",
      width: 300,
    },
    {
      Header: "동",
      accessor: (row) =>
        row.dongs.map((dong, index) => {
          return (
            <Dong
              key={index}
              postalCode={row.postalCode}
              dong={dong}
              onDelete={handleDelete}
            />
          );
        }),
      width: 300,
    },
  ]);

  return (
    <Container>
      <Title>배송불가지역 동정보 관리</Title>

      <ButtonsRow>
        <Col md={9}>
          <StyledButton type="button" onClick={handleClickCreate}>
            배송불가지역 동정보 추가
          </StyledButton>
        </Col>
        <RightButtonsGroupColumn>
          <Button type="button" onClick={handleClickBulkUpload}>
            대량 생성
          </Button>
        </RightButtonsGroupColumn>
      </ButtonsRow>

      <DongsNotSupportedTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        data={state.dongsNotSupported}
        pagination={false}
      />
    </Container>
  );
};
