import React, { useCallback, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { EditAlimtalkNoticeModal } from "@/Modals";
import { useAlimtalkNoticeStore, useModalStore } from "@/store/hooks";

const AlimtalkNoticeTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const EditColumn = ({ message }) => {
  const { ...actions } = useAlimtalkNoticeStore();
  const { openModal } = useModalStore();

  const handleUpdate = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  function handleClickEdit() {
    openModal(
      <EditAlimtalkNoticeModal
        key="edit-alimtal-notice-modal"
        message={message}
        onUpdate={handleUpdate}
      />,
    );
  }

  return <SmallButton onClick={handleClickEdit}>수정</SmallButton>;
};

export default () => {
  const { state, ...actions } = useAlimtalkNoticeStore();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  const columns = [
    {
      Header: "공지 메시지",
      accessor: "message",
    },
    {
      id: "edit",
      Header: "수정",
      accessor: (row) => {
        return <EditColumn message={row.message} />;
      },
      selectable: false,
    },
  ];

  return (
    <Container>
      <Row>
        <Col>
          <Title>알림톡 공지메시지</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <AlimtalkNoticeTable
            columns={columns}
            data={[state.alimtalkNoticeMessage]}
            pagination={false}
          />
        </Col>
      </Row>
    </Container>
  );
};
