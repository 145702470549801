import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import dfSubDays from "date-fns/subDays";
import { formatDate, getDateByTimeZone } from "@/lib/date";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  SET_QUERY: "SET_QUERY",
  FETCH_ALL: "FETCH_ALL",
};

export const QUERY_DATE_TYPE_RECEIPT_DATE = "RECEIPT_DATE";
export const QUERY_DATE_TYPE_DELIVERY_ALLOCATION_DATE =
  "DELIVERY_ALLOCATION_DATE";
export const QUERY_DATE_TYPE_PICKUP_COMPLETED_DATE = "PICKUP_COMPLETED_DATE";
export const QUERY_DATE_TYPE_DELIVERY_COMPLETED_DATE =
  "DELIVERY_COMPLETED_DATE";

export const QUERY_DELIVERY_TYPE_ALL = "ALL";
export const QUERY_DELIVERY_TYPE_RECEIPTED = "RECEIPTED";
export const QUERY_DELIVERY_TYPE_PICKUP_SCHEDULED = "PICKUP_SCHEDULED";
export const QUERY_DELIVERY_TYPE_PICKUP_COMPLETED = "PICKUP_COMPLETED";
export const QUERY_DELIVERY_TYPE_WAREHOUSED = "WAREHOUSED";
export const QUERY_DELIVERY_TYPE_DELIVERY_ALLOCATED = "DELIVERY_ALLOCATED";
export const QUERY_DELIVERY_TYPE_DELIVERY_POSTPONED = "DELIVERY_POSTPONED";
export const QUERY_DELIVERY_TYPE_DELIVERY_STARTED = "DELIVERY_STARTED";
export const QUERY_DELIVERY_TYPE_DELIVERY_COMPLETED = "DELIVERY_COMPLETED";
export const QUERY_DELIVERY_TYPE_ACCIDENT = "ACCIDENT";
export const QUERY_DELIVERY_TYPE_CANCELED = "CANCELED";
export const QUERY_DELIVERY_TYPE_TYPE_PICKUP = "TYPE_PICKUP";
export const QUERY_DELIVERY_TYPE_TYPE_RETURN = "TYPE_RETURN";
export const QUERY_DELIVERY_TYPE_ADDRESS_ERROR = "ADDRESS_ERROR";
export const QUERY_DELIVERY_TYPE_ADDRESS_ERROR_CANCELED =
  "ADDRESS_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_DELIVERY_SENT_BACK = "DELIVERY_SENT_BACK";
export const QUERY_DELIVERY_TYPE_DELIVERY_LOST = "DELIVERY_LOST";
export const QUERY_DELIVERY_TYPE_MOBILE_ERROR = "MOBILE_ERROR";
export const QUERY_DELIVERY_TYPE_MOBILE_ERROR_CANCELED =
  "MOBILE_ERROR_CANCELED";
export const QUERY_DELIVERY_TYPE_DELAYED = "DELAYED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED =
  "ADDRESS_NOT_SUPPORTED";
export const QUERY_DELIVERY_TYPE_ADDRESS_NOT_SUPPORTED_CANCELED =
  "ADDRESS_NOT_SUPPORTED_CANCELED";

const zonedDate10day = dfSubDays(getDateByTimeZone(), 10);
const zonedDateToday = getDateByTimeZone();
const initDateFrom = formatDate(zonedDate10day);
const initDateTo = formatDate(zonedDateToday);

const INITIAL_QUERY = {
  bookId: "",
  dateType: QUERY_DATE_TYPE_RECEIPT_DATE,
  dateFrom: initDateFrom,
  dateTo: initDateTo,
  page: 1,
  pageSize: 20,
  types: "2",
  queryType: QUERY_DELIVERY_TYPE_ALL,
  queryTypes: [QUERY_DELIVERY_TYPE_ALL],
};

const INITIAL_STATE = {
  deliveriesReturns: [],
  pageCount: 1,
  query: INITIAL_QUERY,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveriesReturns: action.deliveriesReturns,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

const makeQueryStringObj = (query = INITIAL_QUERY) => {
  const queryTypes = query.queryTypes.includes(QUERY_DELIVERY_TYPE_ALL)
    ? ""
    : query.queryTypes.join(";");

  return {
    ...query,
    queryTypes,
  };
};

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useDeliveriesPickupsStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const fetchAll = useCallback(async () => {
    const { deliveries, pageCount } = await api.get("/deliveries", {
      ...makeQueryStringObj(state.query),
    });

    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveriesReturns: deliveries.map((d) => new Delivery(d)),
      pageCount,
    });

    return { deliveries, pageCount };
  }, [api.get, state.query, dispatch]);

  const setQuery = useCallback(
    (query = {}) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query,
      });
    },
    [dispatch],
  );

  return {
    state,
    fetchAll,
    setQuery,
  };
};
