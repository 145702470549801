import React from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import RiderGroup2Form from "@/forms/RiderGroup2Form";
import { useRiderGroupsStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop } = {}) => {
  const { ...actions } = useRiderGroupsStore();
  const { closeModal } = useModalStore();

  const onSubmit = async (data) => {
    if (data.name) {
      actions
        .createRiderGroup2(data)
        .then(() => {
          onCreate();
          closeModal();
        })
        .catch((e) => {
          window.alert(`라이더 팀 생성에 실패했습니다: ${e.message}`);
        });
    } else {
      window.alert("필수 항목을 입력해주세요.");
    }
  };

  return (
    <Modal size="md">
      <Modal.Header>
        <Title>라이더 팀 등록</Title>
      </Modal.Header>
      <Modal.Body>
        <RiderGroup2Form onSubmit={onSubmit} />
      </Modal.Body>
    </Modal>
  );
};
