import React, { useCallback, useEffect, useMemo, useState } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { CreateHolidayModal } from "@/Modals";
import { useHolidaysStore, useModalStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 10px;
`

const HolidaysTable = styled(Table)`
  margin-bottom: 100px;
`

const DeleteColumn = ({ id, onDelete = _noop }) => {
  const { ...actions } = useHolidaysStore();

  const handleClickDelete = useCallback(async () => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await actions.deleteHoliday(id);
        onDelete();
      } catch (e) {
        window.alert(`휴일 삭제에 실패했습니다: ${e.message}`);
      };      
    }
  }, [actions.deleteHoliday, onDelete]);

  return <SmallButton onClick={handleClickDelete}>삭제</SmallButton>;
};

export default () => {
  const { state, ...actions } = useHolidaysStore();
  const { openModal } = useModalStore();

  const [year, setYear] = useState('2021');

  useEffect(() => {
    fetchAll();
  }, [year]);

  const fetchAll = useCallback(() => {
    actions.fetchAll(year);
  }, [actions.fetchAll, year]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateHolidayModal key="create-holyday-modal" onCreate={fetchAll} />
    );
  }, [openModal]);

  const handleSelectYear = useCallback((e) => {
    setYear(e.target.value);
  }, []);

  const columns = useMemo(() => [
    {
      Header: "날짜",
      accessor: (row) => `${row.year}-${row.month < 10 && '0'}${row.month}-${row.date}`,
      width: 400,
    },
    {
      Header: "삭제",
      accessor: (row) => <DeleteColumn id={row.id} onDelete={fetchAll} />,
      selectable: false,
    },
  ]);

  return (
    <Container>
      <Title>휴일 관리</Title>
      <ButtonWrap>
        <Button onClick={handleClickCreate}>휴일 등록</Button>
      </ButtonWrap>
      <Form>
        <Row>
          <Col sm={4}>
            <Form.Group as={Row}>
              <Form.Label column sm={4}>
                연도 선택
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  as="select"
                  onChange={handleSelectYear}
                  value={year}
                >
                  <option value="2021">2021</option>
                  <option value="2022">2022</option>
                  <option value="2023">2023</option>
                </Form.Control>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Form>

      <HolidaysTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        data={state.holidays}
        pagination={false}
      />
    </Container>
  );
};
