import React, { useCallback, useState } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import OrigRow from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import { FindSigungusModal } from "@/Modals";
import { useDongsStore, useModalStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`

const StyledRow = styled(OrigRow)`
  margin-left: -5px;
  margin-right: -5px;
`;
const StyledCol = styled(Col)`
  padding-left: 5px;
  padding-right: 5px;
`;

const Title = styled.h3``;

export default ({ onCreate = _noop } = {}) => {
  const { closeModal, openModal } = useModalStore();
  const { ...actions } = useDongsStore();
  const { register, handleSubmit, errors, reset, getValues } = useForm();

  const [selectedSigungu, setSelectedSigungu] = useState(null);

  function handleClickFindSigungu() {
    openModal(
      <FindSigungusModal onSelect={handleSelectSigungu} key="find-sigungus-modal" />
    );
  }

  function handleSelectSigungu(sigungu) {
    closeModal();
    setSelectedSigungu(sigungu);

    reset({
      name: getValues().name,
      fee: getValues().fee,
      sigungu: `${sigungu.sido.name} ${sigungu.name}`,
    });
  }

  const onSubmit = useCallback(async (data) => {
    if (selectedSigungu) {
      let _data = { ...data };
      _data.sigunguId = selectedSigungu.id;

      await actions.create(_data).then(() => {
        window.alert(`${data.name}을/를 추가했습니다.`);
        onCreate();
        closeModal();
      })
      .catch((e) => {
        window.alert(e.message);
      });    
    }
  }, [actions.create, selectedSigungu]);  

  return (
    <Modal>
      <Modal.Header>
        <Title>동 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              시군구
            </Form.Label>
            <StyledCol sm={6}>
              <Form.Control
                readOnly
                name="sigungu"
                ref={register({ required: "시군구를 선택해주세요." })}
              />
            </StyledCol>
            <StyledCol sm={2}>
              <Button type="button" onClick={handleClickFindSigungu}>검색</Button>
            </StyledCol>
            <StyledCol sm={{ span: 8, offset: 4 }}>
              <ErrorText>{errors.sigungu && errors.sigungu.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              이름
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                minLength={2}
                name="name"
                ref={register({ required: "동 이름을 입력해주세요." })}
              />
              <ErrorText>{errors.name && errors.name.message}</ErrorText>
            </StyledCol>
          </Form.Group>
          <Form.Group as={StyledRow}>
            <Form.Label column sm={4}>
              운임
            </Form.Label>
            <StyledCol sm={8}>
              <Form.Control
                type="number"
                name="fee"
                ref={register}
              />
            </StyledCol>
          </Form.Group>
          <Button type="submit">생성하기</Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
