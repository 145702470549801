export class Cs {
  id = null;
  answer = null;
  bookId = null;
  checked = false;
  completedAt = null;
  createdAt = null;
  deleted = false;
  etc = null;
  image = null;
  text = "";
  updateLogs = null;
  updatedAt = null;
  delivery = null;

  constructor(cs) {
    this.id = cs.id;
    this.answer = cs.answer;
    this.bookId = cs.bookId;
    this.checked = cs.checked;
    this.completedAt = cs.completedAt;
    this.createdAt = cs.createdAt;
    this.deleted = cs.deleted;
    this.etc = cs.etc;
    this.image = cs.image;
    this.text = cs.text;
    this.updateLogs = cs.updateLogs;
    this.updatedAt = cs.updatedAt;
    this.delivery = cs.delivery;
  }

  get deliveryEtc4Obj() {
    try {
      return (
        this.delivery && this.delivery.etc4 && JSON.parse(this.delivery.etc4)
      );
    } catch (e) {
      return {};
    }
  }

  get deliveryType() {
    return this.delivery?.type;
  }

  get pickupRiderName() {
    return this.delivery?.pickupRider?.name;
  }

  get pickupRiderMobile() {
    return this.delivery?.pickupRider?.mobile1;
  }

  get deliveryOrderIdFromCorp() {
    if (this.delivery) {
      return this.delivery.orderIdFromCorp;
    } else {
      return "";
    }
  }

  get deliveryRiderMobile() {
    if (this.delivery && this.delivery.deliveryRider) {
      return this.delivery.deliveryRider.mobile1;
    } else {
      return "";
    }
  }

  get deliveryRiderName() {
    if (this.delivery && this.delivery.deliveryRider) {
      return this.delivery.deliveryRider.name;
    } else {
      return "";
    }
  }

  get deliverySenderName() {
    if (this.delivery) {
      return this.delivery.senderName;
    } else {
      return "";
    }
  }

  get csEtcObj() {
    try {
      return this.etc && JSON.parse(this.etc);
    } catch (e) {
      return {};
    }
  }

  get receiverAddress() {
    if (this.delivery) {
      return this.delivery.receiverAddress;
    } else {
      return "";
    }
  }

  get updateLogsObj() {
    try {
      return this.updateLogs && JSON.parse(this.updateLogs);
    } catch (e) {
      return {};
    }
  }

  get updateLogsArray() {
    try {
      return this.updateLogsObj ? Object.entries(this.updateLogsObj) : [];
    } catch (e) {
      return [];
    }
  }

  // get updateLogsArrayOld() {
  //   try {
  //     return this.updateLogsObj ? Object.entries(this.updateLogsObj) : [];
  //   } catch (e) {
  //     return [];
  //   }
  // }

  get updateLastLog() {
    if (this.updateLogs) {
      return {
        updatedAt: Object.keys(this.updateLogsObj).slice(-1)[0],
        admin: Object.values(this.updateLogsObj).slice(-1)[0],
      };
    } else {
      return null;
    }
  }

  get withDeliveryCompleted() {
    return this.csEtcObj && this.csEtcObj.withDeliveryCompleted;
  }
}
