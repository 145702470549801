import React, { createContext, useCallback, useContext, useReducer } from "react";
import _remove from "lodash/remove";
import { Delivery } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
};

const INITIAL_STATE = {
  deliveries: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        deliveries: action.deliveries,
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useReallocatedDeliveriesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const decideOnTheFly = useCallback(async ({ bookId, decision = false }) => {
    return api.put(`/reallocated-deliveries/${bookId}`, {
      decision,
    });
  }, [api.put]);

  const fetchAll = useCallback(async () => {
    const deliveries = await api.get("/reallocated-deliveries");
    dispatch({
      type: CONSTANTS.FETCH_ALL,
      deliveries: deliveries.map((d) => new Delivery(d)),
    });
  }, [api.get, dispatch]);

  return {
    state,
    decideOnTheFly,
    fetchAll,
  };
};
