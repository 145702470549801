import React, {
  createContext,
  useCallback,
  useContext,
  useReducer,
} from "react";
import _remove from "lodash/remove";
import { User } from "@/Models";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  CREATE: "CREATE",
  DELETE: "DELETE",
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
  UPDATE: "UPDATE",
};

const INITIAL_STATE = {
  users: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 10,
    text: "",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE:
      return {
        ...state,
      };
    case CONSTANTS.UPDATE:
      return {
        ...state,
      };
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        users: action.users,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...action.query,
        },
      };
    case CONSTANTS.DELETE:
      return {
        ...state,
        users: _remove(state.users, (user) => user.id !== action.id),
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useUsersStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const create = useCallback(
    async ({
      authority,
      authorizedForAllTypes,
      locked,
      password,
      realname,
      riderId,
      type,
      username,
    }) => {
      return await api.post("/users", {
        authority,
        authorizedForAllTypes: authorizedForAllTypes === "1",
        locked: locked === "1",
        password,
        realname,
        riderId,
        type,
        username,
      });
    },
    [api.post],
  );

  const deleteUser = useCallback(
    async (id) => {
      await api.del(`/users/${id}`);
      dispatch({
        type: CONSTANTS.DELETE,
        id,
      });
    },
    [api.del],
  );

  const fetchAll = useCallback(
    async ({ withRiderManager = "0" }) => {
      const { users, pageCount } = await api.get("/users", {
        page: state.query.page,
        pageSize: state.query.pageSize,
        text: state.query.text,
        withRiderManager,
      });

      dispatch({
        type: CONSTANTS.FETCH_ALL,
        users: users.map((u) => new User(u)),
        pageCount,
      });
    },
    [api.get, dispatch, state.query],
  );

  const fetchByIdOnTheFly = useCallback(
    async (id) => {
      const user = await api.get(`/users/${id}`);

      return new User(user);
    },
    [api.get],
  );

  const lock = useCallback(
    (id, body) => {
      return api.put(`/users/${id}/lock`, body);
    },
    [api.put],
  );

  const setQuery = useCallback(
    (query) => {
      dispatch({
        type: CONSTANTS.SET_QUERY,
        query: {
          ...state.query,
          ...query,
        },
      });
    },
    [dispatch, state.query],
  );

  const resetPassword = useCallback(
    (id, body) => {
      return api.put(`/users/${id}/password`, body);
    },
    [api.put],
  );

  const update = useCallback(
    (
      id,
      {
        authority,
        authorizedForAllTypes,
        locked,
        password,
        realname,
        riderId,
        type,
      },
    ) => {
      return api.put(`/users/${id}`, {
        authority,
        authorizedForAllTypes: authorizedForAllTypes === "1",
        locked: locked === "1",
        password,
        realname,
        riderId: riderId || null,
        type: type || null,
      });
    },
    [api.put],
  );

  const updatePassword = useCallback(
    (body) => {
      return api.put(`/auth/password`, body);
    },
    [api.put],
  );

  return {
    state,
    create,
    deleteUser,
    fetchAll,
    fetchByIdOnTheFly,
    lock,
    setQuery,
    resetPassword,
    update,
    updatePassword,
  };
};
