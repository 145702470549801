import React, { memo, useCallback, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";

import daumAddress from "@/lib/daum-address";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";

import { useAddressStore, useLoadingStore } from "@/store/hooks";

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;

const SearchButton = styled(Button)`
  width: 100%;
`;

const AddressUpdateField = memo(({ register, watch }) => {
  const [address, setAddress] = useState("");
  const [addressRoad, setAddressRoad] = useState("");
  const [addressDetail, setAddressDetail] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const { ...actions } = useAddressStore();

  const { finishLoading, startLoading } = useLoadingStore();

  const handleClickSearchButton = useCallback(async () => {
    if (watch("searchAddress")) {
      try {
        startLoading();

        const response = await actions.refineAddress({
          address: watch("searchAddress"),
          addressDetail: watch("searchAddressDetail"),
        });

        if (response.one?.success) {
          if (response.one?.data?.oneResult?.DATA.length > 2) {
            window.alert(
              response.one?.data?.oneResult?.RMG3 || "조회 결과가 많습니다.",
            );
            setAddress("");
            setAddressRoad("");
            setAddressDetail("");
            setPostalCode("");
          } else {
            setAddress(response.one?.data?.address?.address);
            setAddressRoad(response.one?.data?.address?.addressRoad);
            setAddressDetail(response.one?.data?.address?.addressDetail);
            setPostalCode(response.one?.data?.address?.postalCode);
          }
        } else if (response.tmap?.success) {
          setAddress(response.tmap?.data?.result?.address);
          setAddressRoad(response.tmap?.data?.result?.addressRoad);
          setAddressDetail(response.tmap?.data?.result?.addressDetail);
          setPostalCode(response.tmap?.data?.result?.postalCode);
        } else if (response.kakao?.success && response.kakao?.data) {
          setAddress(response.kakao?.data[0].address);
          setAddressRoad(response.kakao?.data[0].addressRoad);
          setAddressDetail(response.kakao?.data[0].addressDetail);
          setPostalCode(response.kakao?.data[0].postalCode);
        } else {
          window.alert(`주소 정제에 실패하였습니다. 다시 시도해주세요.`);
          setAddress("");
          setAddressRoad("");
          setAddressDetail("");
          setPostalCode("");
        }
      } catch (e) {
        window.alert(`주소 정제에 실패하였습니다.\n에러메시지: ${e.message}`);
        setAddress("");
        setAddressRoad("");
        setAddressDetail("");
        setPostalCode("");
      }

      finishLoading();
    }
  }, []);

  return (
    <Form>
      <Form.Row>
        <Col sm={9}>
          <Form.Control
            name="searchAddress"
            ref={register}
            placeholder="주소"
            style={{ marginBottom: "10px" }}
          />
          <Form.Control
            name="searchAddressDetail"
            ref={register}
            placeholder="상세 주소"
          />
          <Form.Control name="address" value={address} ref={register} hidden />
          <Form.Control
            name="addressRoad"
            value={addressRoad}
            ref={register}
            hidden
          />
          <Form.Control
            name="addressDetail"
            value={addressDetail}
            ref={register}
            hidden
          />
          <Form.Control
            name="postalCode"
            value={postalCode}
            ref={register}
            hidden
          />
        </Col>
        <Col sm={3}>
          <SearchButton onClick={handleClickSearchButton} type="button">
            검색
          </SearchButton>
        </Col>
      </Form.Row>
      <Form.Row>
        <Col sm={12}>
          지번주소: {address}
          <br />
          도로명주소: {addressRoad}
          <br />
          상세주소: {addressDetail}
          <br />
          우편번호: {postalCode}
          <br />
        </Col>
      </Form.Row>
    </Form>
  );
});

const BaseAddressForm = ({ onSubmit, initBaseAddressMemo }) => {
  const { register, handleSubmit, errors, reset, watch } = useForm();

  useEffect(() => {
    if (initBaseAddressMemo) {
      reset({
        memo: initBaseAddressMemo.memo,
        buildingDongNumber: initBaseAddressMemo.buildingDongNumber,
      });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {!initBaseAddressMemo && (
        <>
          <Form.Group>
            <Form.Label>주소</Form.Label>
            <AddressUpdateField register={register} watch={watch} />
            <ErrorText>{errors.address && "주소를 입력해주세요."}</ErrorText>
          </Form.Group>
          {/* <Form.Group>
            <Form.Label>상세주소</Form.Label>
            <Form.Control name="addressDetail" ref={register} />
          </Form.Group> */}
        </>
      )}

      <Form.Group>
        <Form.Label>빌딩 동</Form.Label>
        <Form.Control name="buildingDongNumber" ref={register} />
      </Form.Group>

      <Form.Group>
        <Form.Label>주소정보</Form.Label>
        <Form.Control name="memo" ref={register({ required: true })} />
      </Form.Group>

      <Button type="submit">생성하기</Button>
    </Form>
  );
};

export default BaseAddressForm;
