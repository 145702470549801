import React, { memo, useCallback, useEffect, useMemo } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Table from "@/components/Table";
import { useDeletedRidersStore } from "@/store/hooks";

const DeletedRidersTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const RestoreColumn = memo(({ id, onRestore = _noop }) => {
  function handleClicRestore() {
    if (window.confirm("정말 복구하시겠습니까?")) {
      onRestore(id);
    }
  }

  return <SmallButton onClick={handleClicRestore}>복구</SmallButton>;
});

export default () => {
  const { state, ...actions } = useDeletedRidersStore();
  const { handleSubmit } = useForm();

  useEffect(() => {
    actions.fetchAll();
  }, []);

  useEffect(() => {
    actions.fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const handleChange = useCallback(
    (e) => {
      actions.setQuery({ [e.target.name]: e.target.value });
    },
    [actions.setQuery]
  );

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const handleRestore = useCallback(async (id) => {
    try {
      await actions.restore(id);
      window.alert("복구되었습니다.");
      actions.fetchAll();
    } catch (e) {
      window.alert(
        `삭제된 라이더 복구에 실패하였습니다.\n에러메시지: ${e.message}`
      );
    }
  }, []);

  const onSubmit = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const columns = useMemo(() => [
    {
      Header: "라이더번호",
      accessor: "code",
    },
    {
      Header: "이름",
      accessor: "name",
    },
    {
      Header: "연락처",
      accessor: "mobile1",
    },
    {
      id: "restore",
      Header: "복구",
      accessor: (row) => (
        <RestoreColumn id={row.id} onRestore={handleRestore} />
      ),
      selectable: false,
    },
  ]);

  return (
    <Container>
      <Row>
        <Col>
          <Title>삭제된 라이더 관리</Title>
        </Col>
      </Row>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="text"
                    onChange={handleChange}
                    placeholder="ID, 번호, 이름, 연락처 조회"
                    value={state.query.text}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <Row>
        <Col>
          <DeletedRidersTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.riders}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
