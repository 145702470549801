import React, { useCallback, useEffect } from "react";
import styled from "@emotion/styled";
import Table from "react-bootstrap/Table";

import Modal from "@/components/Modal";

import {
  useAgPostalCodesByCorpStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const Td = styled.td`
  width: calc(100% - 200px);
  padding: 0.5rem !important;
  word-break: break-all;
`;

const Th = styled.th`
  width: 200px;
  padding: 0.5rem !important;
`;

const Title = styled.h3`
  margin: 0;
`;

export default ({ postalCode } = {}) => {
  const { state, ...actions } = useAgPostalCodesByCorpStore();

  useEffect(() => {
    if (postalCode) {
      fetchByPostalCode();
    }
  }, [postalCode]);

  const fetchByPostalCode = useCallback(async () => {
    try {
      await actions.fetchByPostalCode(postalCode);
    } catch (e) {
      window.alert(`우편번호 조회에 실패했습니다: ${e.message}`);
    }
  }, [postalCode]);

  return (
    <Modal backdrop>
      <Modal.Header>
        <Title>우편번호 상세</Title>
      </Modal.Header>
      <Modal.Body>
        <Table bordered>
          <tbody>
            <tr>
              <Th>우편번호</Th>
              <Td>{state.agPostalCodeByCorp?.postalCode}</Td>
            </tr>
            <tr>
              <Th>지역그룹명</Th>
              <Td>{state.agPostalCodeByCorp?.allocationGroup?.name}</Td>
            </tr>
            <tr>
              <Th>시/도</Th>
              <Td>{state.agPostalCodeByCorp?.dong?.sigungu?.sido?.name}</Td>
            </tr>
            <tr>
              <Th>시/군/구</Th>
              <Td>{state.agPostalCodeByCorp?.dong?.sigungu?.name}</Td>
            </tr>
            <tr>
              <Th>읍/면/동</Th>
              <Td>{state.agPostalCodeByCorp?.dong?.name}</Td>
            </tr>
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
