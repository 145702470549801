import BaseModel from "./BaseModel";
import { formatDate } from "@/lib/date";

export class BaseAddressMemo extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  get address() {
    return this.baseAddress?.address;
  }

  get addressRoad() {
    return this.baseAddress?.addressRoad;
  }

  get riderName() {
    return this.rider?.name || "관리자";
  }

  renderCreatedAt(dateFormat = "yyyy-MM-dd HH:mm") {
    if (this.createdAt) {
      return formatDate(new Date(this.createdAt), dateFormat);
    } else {
      return "";
    }
  }
}
