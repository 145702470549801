import React, { createContext, useCallback, useContext, useReducer } from "react";
import { useApiStore } from "@/store/hooks";

export const CONSTANTS = {
  FETCH_ALL: "FETCH_ALL",
  SET_QUERY: "SET_QUERY",
};

const INITIAL_STATE = {
  riderNotices: [],
  pageCount: 1,
  query: {
    page: 1,
    pageSize: 20,
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case CONSTANTS.FETCH_ALL:
      return {
        ...state,
        riderNotices: action.riderNotices,
        pageCount: action.pageCount,
      };
    case CONSTANTS.SET_QUERY:
      return {
        ...state,
        query: {
          ...state.query,
          ...action.query,
        },
      };
    default:
      return INITIAL_STATE;
  }
};

export const Context = createContext(INITIAL_STATE);

export const Provider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

  return (
    <Context.Provider value={{ dispatch, state }}>{children}</Context.Provider>
  );
};

export const useRiderNoticesStore = () => {
  const api = useApiStore();
  const { dispatch, state } = useContext(Context);

  const create = useCallback(async (data) => {
    return await api.post("/rider-notices", data);
  }, [api.post]);

  const deleteRiderNotice = useCallback((id) => {
    return api.del(`/rider-notices/${id}`);
  }, [api.del]);

  const fetchAll = useCallback(async () => {
    const { items, totalCount } = await api.get("/rider-notices", state.query);
        
    dispatch({
      type: CONSTANTS.FETCH_ALL,
      riderNotices: items,
      pageCount: Math.floor(totalCount / state.query.pageSize) + (totalCount % state.query.pageSize ? 1 : 0),
    });
  }, [api.get, dispatch, state.query]);

  const setQuery = useCallback((query = {}) => {
    dispatch({
      type: CONSTANTS.SET_QUERY,
      query,
    });
  }, [dispatch]);

  const update = useCallback(async (id, data) => {
    return await api.put(`/rider-notices/${id}`, data);
  }, [api.put]);

  return {
    state,
    create,
    deleteRiderNotice,
    fetchAll,
    setQuery,
    update,
  };
};
