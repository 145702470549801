import React, { memo } from "react";
import Table from "react-bootstrap/Table";
import styled from "@emotion/styled";
import Modal from "@/components/Modal";

const Td = styled.td`
  width: calc(100% - 200px);
  padding: .5rem !important;
`;

const Th = styled.th`
  width: 200px;
  padding: .5rem !important;
`;

const Title = styled.h3`
  margin: 0;
`;
const SubTitle = styled.h6`
  margin-top: 10px;
  font-weight: bold;
`;
export default memo(({ 
  rowsDefault, 
  rowsSender,
  rowsReceiver,
  rowsProduct,
  rowsTracking,
  title, 
  ...props
}) => {
  const TableRows = ({ row }) => {
    return (
      <tr>
        <Th>{row.header}</Th>
        <Td>
          {typeof(row.data) === 'string' ? 
            row.data.split('\n').map((line, idx) => {
              return <span key={idx}>{line}<br/></span>
            }) 
            : row.data
          }
        </Td>
      </tr>
    )
  }; 

  return (
    <Modal size="lg" {...props}>
      <Modal.Header>
        <Title>{title}</Title>
      </Modal.Header>
      <Modal.Body>
        <SubTitle>기본정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsDefault.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>송하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsSender.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>수하인 정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsReceiver.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>물품정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsProduct.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
        <SubTitle>배송정보</SubTitle>
        <Table bordered>
          <tbody>
            {rowsTracking.map((row, i) => (
              <TableRows row={row} key={i} />
            ))}
          </tbody>
        </Table>
       
      </Modal.Body>
    </Modal>
  );
});
