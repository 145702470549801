import BaseModel from "./BaseModel";
import { getPointToArray } from "./Delivery";

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

function getDistance(lat1, lng1, lat2, lng2) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lng2 - lng1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km

  return Math.round(d * 1000);
}

export class SoojioneIncorrection extends BaseModel {
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);
  }

  get refinedAddressPointArr() {
    return {
      longitude: this.refinedAddress
        ? getPointToArray(this.refinedAddress?.point)[0]
        : null,
      latitude: this.refinedAddress
        ? getPointToArray(this.refinedAddress?.point)[1]
        : null,
    };
  }

  get limitedRefinedAddressPointArr() {
    return {
      longitude: this.limitedRefinedAddress
        ? getPointToArray(this.limitedRefinedAddress?.point)[0]
        : null,
      latitude: this.limitedRefinedAddress
        ? getPointToArray(this.limitedRefinedAddress?.point)[1]
        : null,
    };
  }

  get distanceRefineToLimitedRefined() {
    if (this.limitedRefinedAddress && this.refinedAddress) {
      return getDistance(
        Number(this.refinedAddressPointArr.latitude),
        Number(this.refinedAddressPointArr.longitude),
        Number(this.limitedRefinedAddressPointArr.latitude),
        Number(this.limitedRefinedAddressPointArr.longitude),
      );
    } else {
      return null;
    }
  }
}
