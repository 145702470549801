import React, { useCallback, useMemo } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";

import styled from "@emotion/styled";

import { Button, SmallButton } from "@/components/Buttons";
import Table from "@/components/Table";
import { useHanjinStore } from "@/store/hooks";

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 40px;
`;

const ButtonWrap = styled.div`
  margin-bottom: 40px;
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-right: 10px;
`;

const SubTitle = styled.h5``;

const HanjinTable = styled(Table)`
  margin-top: 10px;
  margin-bottom: 100px;
`;

const RetryColumn = ({ wblNum }) => {
  const { ...actions } = useHanjinStore();

  const handleClickRetry = useCallback(async () => {
    if (window.confirm(`${wblNum}: 배송상태 업데이트 처리하시겠습니까?`)) {
      try {
        await actions.retry(wblNum);
        window.alert("배송상태를 업데이트합니다.");
      } catch (e) {
        console.log(e);
        window.alert(
          `배송상태 업데이트에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.retry]);

  return (
    <SmallButton variant="secondary" onClick={handleClickRetry}>
      재시도
    </SmallButton>
  );
};

export default () => {
  const { state, ...actions } = useHanjinStore();

  const fetchFailedDeliveries = useCallback(async () => {
    if (window.confirm("배송 목록을 가져오시겠습니까?")) {
      try {
        await actions.fetchAll();
        window.alert("배송 목록을 가져옵니다.");
      } catch (e) {
        console.log(e);
        window.alert(
          `배송 목록을 가져오는데 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.register]);

  const retryAll = useCallback(async () => {
    if (
      window.confirm("전체 실패건에 대해 배송상태 업데이트 처리하시겠습니까?")
    ) {
      try {
        await actions.retryAll();
        window.alert("배송상태를 업데이트합니다.");
      } catch (e) {
        console.log(e);
        window.alert(
          `배송상태 업데이트에 실패하였습니다.\n에러메시지: ${e.message}`,
        );
      }
    }
  }, [actions.sendDelivered]);

  const columns = useMemo(() => [
    {
      Header: "운송장번호",
      accessor: "WBL_NUM",
      width: 200,
    },
    {
      Header: "스캔일시",
      accessor: "WRK_DAT",
      width: 200,
    },
    {
      Header: "배송상태값",
      accessor: "JOB_STA",
      width: 200,
    },
    {
      Header: "사유코드",
      accessor: "RSN_COD",
      width: 200,
    },
    {
      id: "retry",
      Header: "재시도",
      accessor: (row) => <RetryColumn wblNum={row.WBL_NUM} />,
      selectable: false,
    },
  ]);

  return (
    <Container>
      <Title>한진 연동 관리</Title>

      <SubTitle>수동 처리</SubTitle>
      <ButtonWrap>
        <StyledButton onClick={fetchFailedDeliveries}>
          배송 목록 가져오기
        </StyledButton>
        <StyledButton onClick={retryAll}>배송상태 업데이트</StyledButton>
      </ButtonWrap>

      <SubTitle>목록 조회</SubTitle>

      <HanjinTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        data={state.deliveries}
        pagination={false}
      />
    </Container>
  );
};
