import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import _noop from "lodash/noop";
import ButtonGroups from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import XLSX from "xlsx";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { SmallButton, Button } from "@/components/Buttons";
import Modal from "@/components/Modal";
import Table from "@/components/Table";
import {
  CreateAllocationGroupModal,
  EditAllocationGroupModal,
  FindDongsModal,
  FileUploadModal,
} from "@/Modals";
import {
  useAllocationGroupsStore,
  useLoadingStore,
  useModalStore,
} from "@/store/hooks";

const AllocationGroupsTable = styled(Table)`
  th:nth-of-type(2),
  th:nth-of-type(5),
  th:nth-of-type(6) {
    width: 150px;
  }

  th:nth-of-type(3) {
    width: 200px;
  }
`;

const ButtonsRow = styled(Row)`
  margin-bottom: 10px;
`;

const RightButtonsGroupColumn = styled(Col)`
  text-align: right;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

const RiderDeleteColumn = styled(Col)`
  text-align: right;
`;

const RiderName = styled.b``;

const SearchRow = styled(Row)`
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

const StyledTabs = styled(Tabs)`
  font-size: 16px;
  font-weight: bold;
`;

const FormRadio = styled(Form.Check)`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  min-width: 90px;
  padding-right: 15px;
  text-align: right;
`;

const AddDongColumn = ({ id, onCreate = _noop }) => {
  const { ...actions } = useAllocationGroupsStore();
  const { closeModal, openModal } = useModalStore();

  const handleSelectDongToAdd = useCallback(
    async (dongs) => {
      try {
        await actions.addDong(
          id,
          dongs.map((dong) => dong.id),
        );
        closeModal();
        onCreate();
      } catch (e) {
        window.alert(`동 추가에 실패하였습니다.\n에러메시지: ${e.message}`);
      }
    },
    [id, actions.addDong, closeModal],
  );

  function handleClickAddDong() {
    openModal(
      <FindDongsModal
        key="find-dongs-modal"
        onSelect={handleSelectDongToAdd}
      />,
    );
  }

  return <SmallButton onClick={handleClickAddDong}>동 추가</SmallButton>;
};

const DeleteColumn = ({ id }) => {
  const { ...actions } = useAllocationGroupsStore();

  function handleClickDelete() {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      actions.deleteGroup(id);
    }
  }

  return <SmallButton onClick={handleClickDelete}>그룹 삭제</SmallButton>;
};

const EditColumn = memo(({ allocationGroup, onUpdate }) => {
  const { openModal } = useModalStore();

  const handleClickEdit = useCallback(() => {
    openModal(
      <EditAllocationGroupModal
        key="edit-allocation-group-modal"
        onUpdate={onUpdate}
        allocationGroup={allocationGroup}
      />,
    );
  }, [onUpdate, allocationGroup]);

  return (
    <SmallButton variant="warning" onClick={handleClickEdit}>
      수정
    </SmallButton>
  );
});

const AgPostalCodeColumn = ({ agPostalCodes }) => {
  const rows = () => {
    return (
      agPostalCodes &&
      agPostalCodes.map((row) => {
        return <span key={row.id}>{row.postalCode}, </span>;
      })
    );
  };

  return <div>{rows()}</div>;
};

const CustomDongColumn = ({ customDongs, customDongsWeekend }) => {
  const customDongsRows = () => {
    return (
      customDongs &&
      customDongs.map((row) => {
        return (
          <div key={row.id}>
            {row.dong.name} {row.beonji1}
            {row.beonji2 ? `-${row.beonji2}` : ""}(주중)
          </div>
        );
      })
    );
  };

  const customDongsWeekendRows = () => {
    return (
      customDongsWeekend &&
      customDongsWeekend.map((row) => {
        return (
          <div key={row.id}>
            {row.dong.name} {row.beonji1}
            {row.beonji2 ? `-${row.beonji2}` : ""}(주말)
          </div>
        );
      })
    );
  };

  return (
    <div>
      {customDongsRows()}
      {customDongsWeekendRows()}
    </div>
  );
};

const Dong = memo(({ allocationGroupId, dong }) => {
  const { ...actions } = useAllocationGroupsStore();
  const { openModal } = useModalStore();

  const handleClickDongDelete = useCallback(() => {
    if (window.confirm(`${dong.name} 삭제?`)) {
      actions.deleteDong(allocationGroupId, dong.id);
    }
  }, []);

  const handleClickDongName = useCallback(() => {
    openModal(<Modal key="dong-modal">hello modal</Modal>);
  }, []);

  return (
    <ListGroup.Item key={dong.id}>
      <Row>
        <Col>
          <RiderName onClick={handleClickDongName}>{dong.name}</RiderName>
        </Col>
        <RiderDeleteColumn>
          <SmallButton onClick={handleClickDongDelete}>삭제</SmallButton>
        </RiderDeleteColumn>
      </Row>
    </ListGroup.Item>
  );
});

const Dongs = memo(({ allocationGroupId, dongs = [] }) => {
  return (
    <ListGroup>
      {dongs.map((dong) => (
        <Dong allocationGroupId={allocationGroupId} dong={dong} key={dong.id} />
      ))}
    </ListGroup>
  );
});

export default memo(() => {
  const { state, ...actions } = useAllocationGroupsStore();
  const { finishLoading, startLoading } = useLoadingStore();
  const { closeModal, openModal } = useModalStore();
  const { handleSubmit, register } = useForm();

  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize, state.query.name]);

  const fetchAll = useCallback(() => {
    actions.fetchAll();
  }, [actions.fetchAll]);

  const handleClickCreate = useCallback(() => {
    openModal(
      <CreateAllocationGroupModal
        key="create-allocation-group-modal"
        onCreate={fetchAll}
      />,
    );
  }, [openModal, fetchAll]);

  const handleUploadBulkFile = useCallback(
    async ({ binary }) => {
      const workbook = XLSX.read(binary, {
        type: "binary",
      });

      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const worksheetArr = XLSX.utils.sheet_to_json(worksheet);

      startLoading();
      await actions.bulkUpload(worksheetArr);
      finishLoading();
      closeModal();
      actions.fetchAll({ page: 1 });
    },
    [closeModal, actions.bulkUpload, actions.fetchAll],
  );

  const handleClickBulkUpload = useCallback(() => {
    openModal(
      <FileUploadModal
        dropzoneProps={{
          accept: ".xls, .xlsx",
          onUpload: handleUploadBulkFile,
        }}
        key="allocation-groups-bulk-upload-modal"
        title="지역그룹 대량생성하기"
      />,
    );
  }, [handleUploadBulkFile]);

  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({
        page,
      });
    },
    [actions.setQuery],
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page + 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({
      page: state.query.page - 1,
    });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery],
  );

  const onSubmit = useCallback(
    ({ name }) => {
      actions.setQuery({ name });
    },
    [actions.setQuery],
  );

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        width: 100,
      },
      {
        Header: "이름",
        accessor: "name",
        width: 120,
      },
      {
        Header: "할당량",
        accessor: "limit",
        width: 120,
      },
      {
        Header: "동 목록",
        accessor: (row) => {
          return <Dongs allocationGroupId={row.id} dongs={row._dongs} />;
        },
      },
      {
        id: "add-dong",
        Header: "동 추가",
        accessor: (row) => {
          return <AddDongColumn id={row.id} onCreate={fetchAll} />;
        },
        width: 120,
      },
      {
        Header: "커스텀동 정보",
        ellipsis: false,
        accessor: (row) => {
          return (
            <CustomDongColumn
              customDongs={row.customDongs}
              customDongsWeekend={row.customDongsWeekend}
            />
          );
        },
        width: 300,
      },
      {
        Header: "우편번호 정보",
        ellipsis: false,
        accessor: (row) => {
          return <AgPostalCodeColumn agPostalCodes={row.agPostalCodes} />;
        },
        width: 500,
      },
      {
        id: "edit",
        Header: "수정",
        accessor: (row) => (
          <EditColumn allocationGroup={row} onUpdate={fetchAll} />
        ),
        selectable: false,
        width: 70,
      },
      {
        id: "delete",
        Header: "삭제",
        accessor: (row) => {
          return <DeleteColumn id={row.id} />;
        },
      },
    ],
    [fetchAll],
  );

  return (
    <Container fluid>
      <Row>
        <Col>
          <Title>지역 그룹 관리</Title>
        </Col>
      </Row>
      <ButtonsRow>
        <Col>
          <ButtonGroups>
            <Button onClick={handleClickCreate}>생성</Button>
          </ButtonGroups>
        </Col>
        <RightButtonsGroupColumn>
          <Button onClick={handleClickBulkUpload}>대량 생성</Button>
        </RightButtonsGroupColumn>
      </ButtonsRow>
      <SearchRow>
        <Col>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={8}>
                <Form.Group>
                  <Form.Control
                    name="name"
                    placeholder="지역그룹명 조회"
                    ref={register}
                  />
                </Form.Group>
              </Col>
              <Col md={{ span: 2 }}>
                <Button type="submit">검색하기</Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </SearchRow>
      <AllocationGroupsTable
        checkbox={false}
        responsive
        bordered
        columns={columns}
        currentPage={state.query.page}
        data={state.allocationGroups}
        goToPage={handleGoToPage}
        nextPage={handleNextPage}
        pageCount={state.pageCount}
        pageSize={state.query.pageSize}
        previousPage={handlePreviousPage}
        setPageSize={handleSetPageSize}
      />
    </Container>
  );
});
