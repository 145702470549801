import React from "react";
import _noop from "lodash/noop";
import styled from "@emotion/styled";

import Modal from "@/components/Modal";
import UserForm from "@/forms/UserForm";
import { useUsersStore, useModalStore } from "@/store/hooks";

const Title = styled.h3`
  margin: 0;
`;

export default ({ onCreate = _noop, withRiderManager } = {}) => {
  const { ...actions } = useUsersStore();
  const { closeModal } = useModalStore();

  const onSubmit = async ({
    authority,
    authorizedForAllTypes,
    locked,
    password,
    realname,
    riderId,
    type,
    username,
  }) => {
    actions
      .create({
        authority,
        authorizedForAllTypes,
        locked,
        password,
        realname,
        riderId,
        type,
        username,
      })
      .then(() => {
        onCreate();
        closeModal();
      })
      .catch((e) => {
        window.alert(`관리자 생성에 실패했습니다\n${e.message}`);
      });
  };

  return (
    <Modal size="lg">
      <Modal.Header>
        <Title>관리자 생성</Title>
      </Modal.Header>
      <Modal.Body>
        <UserForm onSubmit={onSubmit} withRiderManager={withRiderManager} />
      </Modal.Body>
    </Modal>
  );
};
