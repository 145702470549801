import React, { useMemo, useCallback, useEffect } from "react";
import _noop from "lodash/noop";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import styled from "@emotion/styled";

import Table from "@/components/Table";
import { useLoadingStore, useReallocationLogsStore } from "@/store/hooks";

const LogsTable = styled(Table)``;

const Title = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
`;

export default () => {
  const { finishLoading, startLoading } = useLoadingStore();
  const { state, ...actions } = useReallocationLogsStore();
  
  useEffect(() => {
    fetchAll();
  }, [state.query.page, state.query.pageSize]);

  const fetchAll = useCallback(async () => { 
    try {
      startLoading();

      await actions.fetchAll();
    } catch (e) {
      window.alert(e.message);
    }

    finishLoading();
  }, [actions.fetchAll]);
  
  const handleGoToPage = useCallback(
    (page) => {
      actions.setQuery({ page });
    },
    [actions.setQuery]
  );

  const handleNextPage = useCallback(() => {
    actions.setQuery({ page: state.query.page + 1 });
  }, [actions.setQuery, state.query.page]);

  const handlePreviousPage = useCallback(() => {
    actions.setQuery({ page: state.query.page - 1 });
  }, [actions.setQuery, state.query.page]);

  const handleSetPageSize = useCallback(
    (pageSize) => {
      actions.setQuery({ page: 1, pageSize });
    },
    [actions.setQuery]
  );

  const columns = useMemo(
    () => [
      {
        Header: "예약번호",
        accessor: (row) => row.delivery.bookId,
        selectable: false,
      },
      {
        id: "type",
        Header: "유형",
        accessor: (row) => (row.type === "DELIVERY" ? "배송" : "수거"),
        width: 120,
      },
      {
        id: "senderDong",
        Header: "출발동",
        accessor: (row) => row.delivery.senderDongObj.name,
        width: 120,
      },
      {
        id: "receiverDong",
        Header: "도착동",
        accessor: (row) => row.delivery.receiverDongObj.name,
        width: 120,
      },
      {
        id: "fromRider",
        Header: "요청라이더",
        accessor: (row) => row.fromRider.name,
        width: 120,
      },
      {
        id: "toRider",
        Header: "대상라이더",
        accessor: (row) => row.toRider.name,
        width: 120,
      },
      {
        id: "reallocated",
        Header: "승인/거절",
        accessor: (row) => (row.reallocated ? "승인" : "거절"),
        width: 120,
      },
      {
        id: "createdAt",
        Header: "승인/거절 일자",
        accessor: (row) => row.createdAt,
      },
    ],
    []
  );

  return (
    <Container>
      <Row>
        <Col>
          <Title>재배차 승인/거절 이력</Title>
        </Col>
      </Row>
      <Row>
        <Col>
          <LogsTable
            responsive
            bordered
            columns={columns}
            currentPage={state.query.page}
            data={state.logs}
            goToPage={handleGoToPage}
            nextPage={handleNextPage}
            pageCount={state.pageCount}
            pageSize={state.query.pageSize}
            previousPage={handlePreviousPage}
            setPageSize={handleSetPageSize}
          />
        </Col>
      </Row>
    </Container>
  );
};
