import React, { useCallback } from "react";
import _noop from "lodash/noop";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useHistory } from "react-router-dom";

import { useForm } from "react-hook-form";
import styled from "@emotion/styled";

import { Button } from "@/components/Buttons";
import { useUsersStore } from "@/store/hooks";

const ContainerInner = styled.div`
  max-width: 480px;
  margin: 0 auto;
`;

const ErrorText = styled.p`
  margin-top: 2px;
  margin-bottom: 0;
  color: red;
  font-size: 12px;
`;
const Title = styled.h1`
  font-size: 36px;
`;

const Description = styled.p`
  color: red;
  font-size: 20px;
  margin-bottom: 20px;
`;

const LabelHighlight = styled.span`
  color: red;
`;

export default () => {
  const history = useHistory();

  const { ...actions } = useUsersStore();
  const { errors, register, handleSubmit } = useForm();

  const onSubmit = useCallback(
    async ({ oldPassword, newPassword }) => {
      await actions
        .updatePassword({ oldPassword, newPassword })
        .then(() => {
          window.alert("변경되었습니다.");
          history.push("/deliveries");
        })
        .catch((e) => {
          window.alert(e.message);
        });
    },
    [actions.updatePassword],
  );

  return (
    <Container>
      <ContainerInner>
        <Title>비밀번호 변경</Title>
        <Description>비밀번호를 변경 후 접속해 주십시오.</Description>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group>
            <Form.Label>이전 비밀번호</Form.Label>
            <Form.Control
              name="oldPassword"
              placeholder="이전 비밀번호"
              ref={register({ required: true })}
              type="password"
            />
            <ErrorText>
              {errors.oldPassword && "이전 비밀번호를 입력해주세요."}
            </ErrorText>
          </Form.Group>
          <Form.Group>
            <Form.Label>
              새 비밀번호{" "}
              <LabelHighlight>
                (영문, 숫자, 특수문자 포함 8자 자리 이상)
              </LabelHighlight>
            </Form.Label>
            <Form.Control
              name="newPassword"
              placeholder="새 비밀번호"
              ref={register({
                required: "새 비밀번호를 입력해 주세요.",
                minLength: {
                  value: 8,
                  message:
                    "영문, 숫자, 특수문자 포함 8자 자리 이상 입력해 주세요.",
                },
                validate: (value) => {
                  return (
                    [/[a-z]/, /[0-9]/, /[^a-zA-Z0-9]/].every((pattern) =>
                      pattern.test(value),
                    ) ||
                    "영문, 숫자, 특수문자 포함 8자 자리 이상 입력해 주세요."
                  );
                },
              })}
              type="password"
            />
            <ErrorText>{errors.newPassword?.message}</ErrorText>
          </Form.Group>
          <Button type="submit">수정하기</Button>
        </Form>
      </ContainerInner>
    </Container>
  );
};
